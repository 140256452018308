import React from "react";

const Sales = ({ sales, handleClick }) => {
  return sales?.length > 0 ? (
    sales?.map((item, index) => {
      const { id, title, saleImage } = item;
      return (
        <div
          className="event-box-img"
          key={id}
          onClick={() => handleClick(item)}
        >
          <img
            src={
              saleImage && saleImage.length > 0
                ? saleImage[0].image
                : "https://bgz-business.s3.us-east-2.amazonaws.com/FreeBusinessImage.jpg"
            }
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
              borderRadius: "15px",
            }}
          />
        </div>
      );
    })
  ) : (
    <div className="businessDetails-Noreview">
      <img src="/images/sale-icon.svg" />
      <div className="businessDetails-Noreview-content">
        No sale available <br />
        stay tuned!
      </div>
    </div>
  );
};

export default Sales;
