import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getBusinessList } from "../../Redux/Action/BusinessAction/businesAction";
import debounce from "lodash/debounce";

import InfinitScroll from "react-infinite-scroll-component";
import GridCardWrapper from "../../Component/GridCardWrapper";
import Card from "../../Component/Card";
import { BUSINESS_LIST } from "../../Utils/constants";
import EmptyMessage from "../../Component/EmptyMessage";
import { BUSINESS_DETAILS } from "../../Routes/RoutesConstant";
import { skeletonAction } from "../../Redux/Action/loadingAction";

const BusinessList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { type } = useParams();
  // console.log(type, "ttttttttttt");
  const dispatch = useDispatch();

  const formatTypeString = (typeStr) => {
    if (!typeStr) return { first: "", second: "" };
    if (typeStr.toLowerCase().includes("newin")) {
      const words = typeStr
        .toLowerCase()
        .replace("newin", "New In")
        .replace("business", " Business")
        .split(" ")
        .filter((word) => word)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1));

      return {
        first: "New In",
        second: "Business",
      };
    }
    const words = typeStr
      .replace(/([A-Z])/g, " $1")
      .toLowerCase()
      .trim()
      .split(" ")
      .filter((word) => word)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1));

    const lastWord = words[words.length - 1];
    const firstPart = words.slice(0, -1).join(" ");
    return {
      first: firstPart,
      second: lastWord,
    };
  };

  // const businessData = useSelector(state => state.businessData);
  // const loadingRequest = useSelector(state => state.loadingReducer);
  const skeletonLoad = useSelector(
    (state) => state?.loadingReducers?.skeletonLoader
  );
  const {
    latLng: { lat, lng },
  } = useSelector((state) => state.locationReducer);
  const PageSize = 20;

  const { stateList, selectedState } = useSelector(
    (state) => state.locationReducer
  );
  const currentState = stateList.find((s) => s.state_code === selectedState);
  let [responseData, setResponseData] = useState([]);
  let [isNext, isNextFunc] = useState(false);
  let [pageCount, setCount] = useState(1);

  const [search, setSearch] = useState("");

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    debouncVersion();
  }, [pageCount]);

  useEffect(() => {
    if (pageCount === 1) {
      debouncVersion();
    } else {
      setCount(1);
    }
  }, [lat, lng, currentState]);

  console.log(lat);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 400);
    };
  };

  useEffect(() => {
    debouncVersion(search, 1);
  }, [search]);

  // const businessPage = (id) => {
  //   navigate(BUSINESS_DETAILS, { state: { id } });
  // };
  const businessPage = (id, b_name) => {
    navigate(
      `/businessDetails/${id}?name=${b_name?.trim()?.replaceAll(" ", "_")}`,
      { state: { id } }
    );
  };

  const fetchData = useCallback(
    async (search = "", page = pageCount) => {
      const user_uuid = localStorage.getItem("user_uuid");
      let body = {
        user_uuid: user_uuid,
        fetchtype: "seeall",
        page,
        limit: PageSize,
        state: currentState?.state || "",
        state_code: currentState?.state_code || "",
        lat,
        lng,
        text: search,
      };
      dispatch(skeletonAction(true, BUSINESS_LIST));
      let response = await dispatch(getBusinessList(body, type));
      if (response?.payload?.BusinessList) {
        if (page === 1) {
          setResponseData([...response.payload.BusinessList]);
        } else {
          setResponseData([...responseData, ...response.payload.BusinessList]);
        }
      }
      dispatch(skeletonAction(false, BUSINESS_LIST));
      const totalPageCount = Math.ceil(
        response?.payload?.TotalRecords / PageSize
      );
      isNextFunc(pageCount !== totalPageCount);
    },
    [lat, lng, currentState, PageSize]
  );

  const fetchMoreData = () => {
    setCount(pageCount + 1);
  };

  const debouncVersion = useCallback(debounce(fetchData), [
    lat,
    lng,
    currentState,
    pageCount,
  ]);
  return (
    <>
      <div>
        {/* <div className="container">
          <div className="row">
            <div className="col-md-12" style={{ marginTop: "48px" }}>
              <h2>
                <span style={{ color: "#ffffff" }}>My</span>{" "}
                <span style={{ color: "#3CA059", textTransform: "capitalize" }}>
                  {type}
                </span>
              </h2>
            </div>
          </div>
        </div> */}
        <div>
          <div className="container">
            <div className="row">
              <div className="col-md-12" style={{ marginTop: "48px" }}>
                <h2>
                  {/* <span style={{ color: "#ffffff" }}>My </span> */}
                  <span style={{ color: "#ffffff" }}>
                    {formatTypeString(type).first}{" "}
                  </span>
                  <span style={{ color: "#3CA059" }}>
                    {formatTypeString(type).second}
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        {/* </section> */}
        <section className="nib-boxes">
          <div className="container">
            <div className="row">
              {/* <div className="col-md-12">
                <form onSubmit={(e) => e.preventDefault()}>
                  <input
                    type="text"
                    name="search"
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </form>
              </div> */}

              {!skeletonLoad[BUSINESS_LIST] && responseData.length === 0 ? (
                <EmptyMessage
                  message={`No Business found for the search ${search}`}
                />
              ) : (
                <InfinitScroll
                  dataLength={responseData.length}
                  next={fetchMoreData}
                  hasMore={isNext}
                >
                  <GridCardWrapper
                    blockWidth={335}
                    blockHeight={400}
                    isLoading={skeletonLoad[BUSINESS_LIST]}
                    data={responseData}
                  >
                    {(props) => (
                      <Card
                        gridView
                        showReview
                        showAddress
                        showPhone
                        handleViewDetail={businessPage}
                        {...props}
                        width={320}
                        height={380}
                      />
                    )}
                  </GridCardWrapper>
                </InfinitScroll>
              )}
            </div>
          </div>
          <div className="for-border" />
        </section>
      </div>
    </>
  );
};

export default BusinessList;
