import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { ANDROID_APP_LINK, IOS_APP_LINK } from "../../Utils/constants";
import EyesOnIcon from "../../assets/images/show.png";
import EyesOffIcon from "../../assets/images/hide.png";
import SuccessIcon from "../../assets/images/success-check.png";

import "./login.css";
import { changePasswordApi } from "../../API/ApiService";
import { loadingAction } from "../../Redux/Action/loadingAction";
import { useDispatch } from "react-redux";

function CreateNewPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [values, setValues] = useState({
    newpassword: "",
    cpassword: "",
  });

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({
      ...values,
      [name]: value,
    });
  };

  // ======= submit Login request ==============
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(loadingAction(true));

    if (values?.newpassword !== values?.cpassword) {
      setErrorMsg("Password and Confirm Password do not match!");
      return;
    } else {
      // navigate(`/signinotp/${values?.email}`)
      let body = JSON.stringify({
        new_password: values?.newpassword,
      });
      try {
        const response = await changePasswordApi(body);
        const loginDataSuccess = response;

        if (loginDataSuccess) {
          dispatch(loadingAction(false));

          setChangeStatus(true);
        }
      } catch (err) {
        dispatch(loadingAction(false));

        swal({
          title: "Error",
          text: err?.response.data.message,
          buttons: false,
          icon: "error",
          timer: 3000,
        });
      }
    }
  };

  const togglePasswordVisibility = (val) => {
    if (val === "newpassword") {
      setShowPassword(!showPassword);
    } else {
      setShowCPassword(!showCPassword);
    }
  };

  const [checkedVal, setCheckedVal] = useState("Business");
  const checkHandler = (event) => {
    setCheckedVal(event.target.value);
  };

  return (
    <>
      <div className='container login2 '>
        <div className='row'>
          <div className='col-md-6 trust_sec'>
            <h1>
              Your Trusted
              <br />
              Local Connection
              <br />{" "}
              <label
                style={{
                  color: "#349c52",
                  position: "relative",
                }}
              >
                Starts Here
                <img
                  src='images/green-suf.png'
                  className='key_feature_designg2'
                  alt=''
                  style={{ left: "3px" }}
                />
              </label>
            </h1>
            <div id='download_app'>
              <div className='container login_download_app mt-1'>
                <h6>
                  <img
                    src='images/suffixes3.svg'
                    className='suffixes3'
                    alt=''
                  />
                  Please Download and Install Brigiz App
                  <br />
                  to Explore More features{" "}
                  <img
                    src='images/green-suf2.png'
                    className='green-suf2'
                    style={{
                      width: "168px",
                      height: "32px",
                      marginLeft: "-158px",
                    }}
                    alt=''
                  />
                </h6>
              </div>
              <div className=' exp-download-btns mt-4'>
                <button
                  onClick={() => window.open(ANDROID_APP_LINK, "_")}
                  style={{ background: "#292828" }}
                >
                  <div className='g_pay_container '>
                    <div className=' btn-img1'>
                      <img src='images/play-button.svg' alt='' />
                    </div>
                    <div className=' btn-txt1'>
                      <span>GET IT ON</span>
                      <p className='text-white m-0'>Google Play</p>
                    </div>
                  </div>
                </button>
                <button
                  onClick={() => window.open(IOS_APP_LINK, "_")}
                  style={{ width: "180px", background: "#292828" }}
                >
                  <div className='g_pay_container'>
                    <div className=' btn-img1'>
                      <img src='images/apple.svg' />
                    </div>
                    <div className='col-md-9 btn-txt1'>
                      <span>Download on the</span>
                      <p className='text-white m-0'>App Store</p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
          {!changeStatus ? (
            <div className='col-md-6 form_screen' id='create_new_password'>
              <div className='login_card bg-white p-5 mt-1'>
                <div className='login_back_btn'>
                  {" "}
                  <button
                    href='#'
                    style={{ verticalAlign: "text-bottom" }}
                    onClick={() => navigate("/")}
                  >
                    <img src='images/back.png' alt='' /> Back
                  </button>
                  {/* <span className='ms-2'>Back</span> */}
                </div>
                <form onSubmit={handleSubmit} className='login_form2'>
                  <h4 className='login_form_label_2'>Create New Password</h4>

                  <label className='login_form_email2 my-2'>New Password</label>
                  <div className='password-input-container'>
                    <input
                      className='login_form_input2'
                      type={showPassword ? "text" : "password"}
                      name='newpassword'
                      onChange={handleInputChange}
                    />
                    <button
                      type='button'
                      className='password-toggle-btn bg-transparent'
                      onClick={() => togglePasswordVisibility("newpassword")}
                    >
                      {showPassword ? (
                        <img src={EyesOnIcon} className='eyes-icon' alt='' />
                      ) : (
                        <img src={EyesOffIcon} className='eyes-icon' alt='' />
                      )}
                    </button>
                  </div>
                  <label className='login_form_email2 my-2'>
                    Confirm Password
                  </label>
                  <div className='password-input-container'>
                    <input
                      className='login_form_input2'
                      type={showCPassword ? "text" : "password"}
                      name='cpassword'
                      onChange={handleInputChange}
                    />
                    <button
                      type='button'
                      className='password-toggle-btn bg-transparent'
                      onClick={() => togglePasswordVisibility("cpassword")}
                    >
                      {showCPassword ? (
                        <img src={EyesOnIcon} className='eyes-icon' alt='' />
                      ) : (
                        <img src={EyesOffIcon} className='eyes-icon' alt='' />
                      )}
                    </button>
                  </div>
                  {errorMsg && (
                    <span className='password_warning_text mt-1 mb-3 text-danger'>
                      {errorMsg}
                      <br />
                    </span>
                  )}
                  <button
                    className='login_form_btn mt-4'
                    disabled={!checkedVal}
                  >
                    Next
                  </button>
                </form>
              </div>
            </div>
          ) : (
            <div className='col-md-6 form_screen' id='create_new_password'>
              <div className='login_card bg-white p-5 mt-1'>
                <div className='Success text-center'>
                  <img src={SuccessIcon} alt='' />
                  <h4>Password Changes Successfully!</h4>
                </div>

                <button
                  className='login_form_btn mt-4'
                  onClick={() => navigate("/login")}
                >
                  Back To Sign In
                </button>
              </div>
            </div>
          )}
        </div>
        <div id='mobile_view'>
          <div className='container login_download_app mt-1'>
            <h6>
              <img src='images/suffixes3.svg' className='suffixes3' alt='' />
              Please Download and Install Brigiz App
              <br />
              to Explore More features{" "}
              <img
                src='images/green-suf2.png'
                className='green-suf2'
                style={{
                  width: "168px",
                  height: "32px",
                  marginLeft: "-158px",
                }}
                alt=''
              />
            </h6>
          </div>
          <div className=' exp-download-btns mt-4'>
            <button
              onClick={() => window.open(ANDROID_APP_LINK, "_")}
              style={{ background: "#292828" }}
            >
              <div className='g_pay_container '>
                <div className=' btn-img1'>
                  <img src='images/play-button.svg' alt='' />
                </div>
                <div className=' btn-txt1'>
                  <span>GET IT ON</span>
                  <p className='text-white m-0'>Google Play</p>
                </div>
              </div>
            </button>
            <button
              onClick={() => window.open(IOS_APP_LINK, "_")}
              style={{ width: "180px", background: "#292828" }}
            >
              <div className='g_pay_container'>
                <div className=' btn-img1'>
                  <img src='images/apple.svg' />
                </div>
                <div className='col-md-9 btn-txt1'>
                  <span>Download on the</span>
                  <p className='text-white m-0'>App Store</p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateNewPassword;
