import { combineReducers } from "redux";
import businessData from "./businessReducers";
import loadingReducers from "./loadingReducers";
import LoginData from "./loginReducers";
import locationReducer from "./locationReducer";
import profileReducer from "./profileReducer";

const rootReduser = combineReducers({
  LoginData,
  businessData,
  loadingReducers,
  locationReducer,
  profileReducer
});

export default rootReduser;
