import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../Contact/Contact.css";
import "./EditProfile.css";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { getProfile } from "../../Redux/Action/ProfileAction/profileAction";
import { loadingAction } from "../../Redux/Action/loadingAction";
import {
  updateProfileImageApi,
  updateUserProfile,
  deleteUserApi,
} from "../../API/ApiService";
import defaultProfileImage from "../../assets/images/profile-image.jpg";
import { toast } from "react-toastify";

const EditProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [dob, setDob] = useState("");
  const [showModalchangePassword, setShowModalChangePassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  useEffect(() => {
    dispatch(getProfile()).then((response) => {
      console.log("Profile Data:", response.result.data.user);
      const userData = response.result.data.user;
      formik.setValues({
        business_name: userData?.userProfile?.name || "",
        email: userData?.email_id || "",
        zipcode: userData?.userProfile?.zipcode || "",
      });
     setPhone(userData?.phone || "");
     setProfileImage(
        userData?.userProfile?.profile_image || defaultProfileImage
      );
      setCountryCode(userData?.country_code || "+1");
      setDob(userData?.userProfile?.date_of_birth || "01/01/1990");
    });
  }, []);


  const handleImageError = (e) => {
    e.target.src = defaultProfileImage;
  };


  // For Image upload
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    dispatch(loadingAction(true));

    try {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");
        const payload = {
          image: base64String,
          format: file.name.split(".").pop(),
        };

        try {
          const response = await updateProfileImageApi(payload);
          if (response?.data?.success) {
            setProfileImage(response.data.profile_image);
          }
        } catch (error) {
          console.error("Error uploading image:", error);
        } finally {
          dispatch(loadingAction(false));
        }
      };

      const tempImageUrl = URL.createObjectURL(file);
      setProfileImage(tempImageUrl);

      reader.readAsDataURL(file);
    } catch (error) {
      console.error("Error processing image:", error);
      dispatch(loadingAction(false));
    }
  };

  // For Delete Account
  const handleDelete = async () => {
    try {
      dispatch(loadingAction(true));
      const response = await deleteUserApi();
      if (response) {
        toast.success(response?.data?.message);
        localStorage.clear();
        navigate("/login");
      } else {
        toast.error(response?.data?.message || "Failed to delete account");
      }
    } catch (error) {
      console.error("Error deleting account:", error);
      toast.error(error.response?.data?.message || "Failed to delete account");
    } finally {
      dispatch(loadingAction(false));
      setShowModal(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      business_name: "",
      email: "",
      zipcode: "",
    },
    validationSchema: Yup.object({
      business_name: Yup.string().trim().required("Name Required"),
      email: Yup.string()
        .trim()
        .email("Invalid email")
        .required("Email Required"),
      zipcode: Yup.string().trim().required("Zip code Required"),
    }),
    onSubmit: async (values) => {
      try {
        console.log("Form submitted with values:", values);
        dispatch(loadingAction(true));

        const payload = {
          name: values.business_name,
          dob: dob,
          zipcode: values.zipcode,
          country_code: countryCode,
          phone: phone,
          email: values.email,
        };
        const response = await updateUserProfile(payload);
        if (response) {
          toast.success(response.data.message);
          navigate('/');
          dispatch(getProfile());
        } else {
          toast.error(response.data.message || "Failed to update profile");
        }
      } catch (error) {
        console.error("Error updating profile:", error);
        toast.error(
          error.response?.data?.message || "Failed to update profile"
        );
      } finally {
        dispatch(loadingAction(false));
      }
    },
  });
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showModal]);

  return (
    <>
      <section className="heading">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <img
                src={"../images/back_btn.png"}
                alt="Edit"
                height={25}
                width={76}
                onClick={() => navigate("/")}
                style={{ cursor: "pointer" }}
              />
            </div>

            <div
              className="relative h-96 w-96 m-8"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <form id="form" encType="multipart/form-data">
                <button
                  type="button"
                  className="flex-center relative"
                  style={{
                    borderRadius: "50%",
                    padding: "0",
                    margin: "30px 0",
                    background: "transparent",
                  }}
                >
                  <img
                    src={profileImage}
                    alt="Avatar"
                    height={187}
                    width={187}
                    onError={handleImageError}
                    style={{
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      bottom: "5px",
                      right: "5px",
                      borderRadius: "50%",
                      padding: "5px",
                      width: "65px",
                      height: "65px",
                    }}
                  >
                    <label htmlFor="file" style={{ cursor: "pointer" }}>
                      <img
                        src={"../images/Edit_profile.png"}
                        alt="Edit"
                        height={65}
                        width={65}
                      />
                    </label>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      borderRadius: "50%",
                      bottom: "23px",
                      right: "20px",
                    }}
                  >
                    <label htmlFor="file" style={{ cursor: "pointer" }}>
                      <img
                        src={"../images/edit_pencil.png"}
                        alt="Edit"
                        height={20}
                        width={20}
                      />
                    </label>
                  </div>
                </button>
                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  hidden
                  onChange={handleImageUpload}
                />
              </form>

               {/* Change Password Button Hide for sometime */}
              {/* <button
                type="button"
                className="btn btn-passwd"
                style={{
                  width: "189px",
                  marginLeft: "20px",
                  height: "52px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "2px solid  #F0F0F0",
                  borderRadius: "8px",
                  padding: "0 10px",
                  color: "#F0F0F0",
                }}
                onClick={() => setShowModalChangePassword(true)}
              >
                <img
                  src={"../images/lock_password.png"}
                  alt="Lock"
                  height={20}
                  width={20}
                  style={{ marginRight: "5px" }}
                />
                Change Password
              </button> */}
            </div>
          </div>
        </div>
      </section>

      {/* {showModalchangePassword && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "#292828",
              color: "#fff",
              padding: "20px",
              borderRadius: "20px",
              width: "538px",
              height: "513px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h5 style={{ marginBottom: "20px" }}>Change Password</h5>
            <div style={{ width: "100%", marginBottom: "15px" }}>
              <label>Current Password</label>

              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter current password"
                  style={{
                    flex: 1,
                    marginRight: "5px",
                    paddingRight: "40px",
                    height: "50px",
                    borderRadius: "15px",
                    backgroundColor: "#121215",
                    color: "#4A4A4B",
                    paddingLeft: "15px",
                    marginTop: "10px",
                  }}
                />
                <img
                  src={"../images/eyefill.png"}
                  alt="Toggle Visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: "absolute",
                    right: "10px",
                    cursor: "pointer",
                    height: "20px",
                    width: "20px",
                  }}
                />
              </div>
            </div>
            <div style={{ width: "100%", marginBottom: "15px" }}>
              <label>New Password</label>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type={showNewPassword ? "text" : "password"}
                  placeholder="Enter new password"
                  style={{
                    flex: 1,
                    marginRight: "5px",
                    paddingRight: "40px",
                    height: "50px",
                    borderRadius: "15px",
                    backgroundColor: "#121215",
                    color: "#4A4A4B",
                    paddingLeft: "15px",
                    marginTop: "10px",
                  }}
                />
                <img
                  src={"../images/eyefill.png"}
                  alt="Toggle Visibility"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  style={{
                    position: "absolute",
                    right: "10px",
                    cursor: "pointer",
                    height: "20px",
                    width: "20px",
                  }}
                />
              </div>
            </div>
            <div style={{ width: "100%", marginBottom: "20px" }}>
              <label>Confirm Password</label>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Enter confirm password"
                  style={{
                    flex: 1,
                    marginRight: "5px",
                    paddingRight: "40px",
                    height: "50px",
                    borderRadius: "15px",
                    backgroundColor: "#121215",
                    color: "#4A4A4B",
                    paddingLeft: "15px",
                    marginTop: "10px",
                  }}
                />
                <img
                  src={"../images/eyefill.png"}
                  alt="Toggle Visibility"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  style={{
                    position: "absolute",
                    right: "10px",
                    cursor: "pointer",
                    height: "20px",
                    width: "20px",
                  }}
                />
              </div>
            </div>
            <button
              onClick={() => console.log("Password Changed")}
              style={{
                backgroundColor: "#28a745",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                padding: "10px 20px",
                cursor: "pointer",
                marginTop: "80px",
                width: "474px",
                borderRadius: "7px",
                marginTop: "35px",
                height: "60px",
              }}
            >
              Change Password
            </button>
          </div>
        </div>
      )} */}

      <section className="contact-form pt-1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={formik.handleSubmit}>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <label className="input_label"> Name*</label>
                    <input
                      type="text"
                      name="business_name"
                      placeholder="Enter Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.business_name}
                    />
                    {formik.touched.business_name &&
                    formik.errors.business_name ? (
                      <div
                        className="invalid-feedback"
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.business_name}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="input_label">Email*</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter Email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div
                        className="invalid-feedback"
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <label className="input_label">Phone Number*</label>
                    <input
                      type="text"
                      name="phone"
                      placeholder="Enter Phone Number"
                      onChange={(e) => setPhone(e.target.value)}
                      onBlur={formik.handleBlur}
                      value={phone}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <div
                        className="invalid-feedback"
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.phone}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="input_label">Zip Code*</label>
                    <input
                      type="text"
                      name="zipcode"
                      placeholder="Enter Zip Code"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.zipcode}
                    />
                    {formik.touched.zipcode && formik.errors.zipcode ? (
                      <div
                        className="invalid-feedback"
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.zipcode}
                      </div>
                    ) : null}
                  </div>
                </div>
                <button
                  type="submit"
                  className="submit_button btn btn-success w-100"
                  style={{ marginTop: "30px" }}
                  // onClick={() => navigate("/")}
                >
                  Next
                </button>

                <div className="divider"></div>
                <div
                  style={{ marginTop: "5px", height: "52px", width: "169px" }}
                >
                  <button
                    type="button"
                    className="delete-btn"
                    onClick={() => setShowModal(true)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      whiteSpace: "nowrap",
                      borderRadius: "8px",
                      width: "170px",
                    }}
                  >
                    <img
                      src={"../images/delete_btn.png"}
                      alt="Delete"
                      height={20}
                      width={20}
                      style={{ marginRight: "5px" }}
                    />
                    Delete Account
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>


    {/* Confirmation Modal for delete accout */}
      {showModal && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 80,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "#292828",
              color: "#fff",
              padding: "20px",
              borderRadius: "20px",
              width: "468px",
              height: "173px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h5
              style={{
                whiteSpace: "nowrap",
                marginTop: "-1px",
                marginBottom: "25px",
              }}
            >
              Are you sure you want to delete the account?
            </h5>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
                width: "100%",
              }}
            >
              <button
                onClick={() => setShowModal(false)}
                className="btn btn-secondary"
                style={{
                  border: "2px solid white",
                  color: "white",
                  backgroundColor: "transparent",
                  padding: "5px 10px",
                  borderRadius: "8px",

                  marginLeft: "180px",

                  height: "50px",
                }}
              >
                Cancel
              </button>

              <button
                onClick={handleDelete}
                className="btn-del-act"
                disabled={formik.isSubmitting}
                style={{
                  border: "2px solid #d03131",
                  color: "#d03131",
                  backgroundColor: "transparent",
                  padding: "5px 10px",
                  borderRadius: "5px",

                  marginRight: "8px",
                }}
              >
                Delete Account
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditProfile;
