import React from "react";
import moment from "moment";

const Reviews = ({ isbusiness, visible }) => {
  return isbusiness?.userReview?.length > 0 ? (
    isbusiness?.userReview.slice(0, visible).map((item, index) => {
      return (
        <div className="" key={index}>
          <div className="">
            <div className="business-review-profile-wrap">
              <div className="review-img">
                <img
                  src={
                    item?.userProfile[0]?.profile_image &&
                      item?.userProfile[0]?.profile_image !== "N/A"
                      ? item?.userProfile[0]?.profile_image
                      : "../images/user.svg"
                  }
                  className="img-fluid"
                />
              </div>
              <div style={{ paddingLeft: "8px" }}>
                <div className="business-review-name">
                  {item?.userProfile[0]?.name}
                </div>
                <div className="business-review-time">
                  {moment(item?.created_at).format("ll")}
                </div>
              </div>
            </div>
            <div className="">
              <div className="review-text">
                <div className="businessDetails-reviewStar">
                  {isbusiness?.reviewAverage != undefined &&
                    Array.from(Array(parseInt(item?.rating)), (e, i) => {
                      return (
                        <li key={i} className="reviewStar">
                          <i className="fa-solid fa-star" />
                        </li>
                      );
                    })}
                  {Array.from(
                    { length: 5 - parseInt(item?.rating || 0) },
                    (e, j) => {
                      return (
                        <li key={j + 5} style={{ listStyle: "none" }}>
                          <i
                            style={{ marginRight: "3px" }}
                            className="fa-regular fa-star"
                          />
                        </li>
                      );
                    }
                  )}
                </div>

                <div className="businessDetails-review-comment">
                  {item.comment}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    })
  ) : (
    <div className="businessDetails-Noreview">
      <img src="/images/emtystateReview.svg" />
      <div className="businessDetails-Noreview-content">
        No review found. <br />
        Be the first person to add a review.
      </div>
    </div>
  );
};

export default Reviews;
