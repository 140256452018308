import React from "react";
import Modal from "../../../../Component/modal/Modal";

const EventsPopup = ({ isOpenEventModal, setIsOpenEventModal }) => {
  return (
    <Modal
      isOpen={isOpenEventModal.open}
      onClose={() => setIsOpenEventModal({ data: {}, open: false })}
    >
      <div className="sale-popContent-container">
        <div className="modal-event-box-img">
          <img
            src={
              isOpenEventModal?.data?.eventImage &&
              isOpenEventModal?.data?.eventImage.length > 0
                ? isOpenEventModal?.data?.eventImage[0].image
                : "https://bgz-business.s3.us-east-2.amazonaws.com/FreeBusinessImage.jpg"
            }
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
              borderRadius: "15px",
            }}
          />
        </div>
        <h5 className="saleTitle">{isOpenEventModal?.data?.title}</h5>
        <div style={{ color: "#3CA059", fontSize: "18px", fontWeight: "400" }}>
          Black Friday Beauty Deals – Glam Up & Save Big!
        </div>
        {/* <ol style={{ listStyle: "outside", paddingLeft: "20px" }}>
      {selectedSale?.description &&
        selectedSale?.description.map((val) => (
          <li
            style={{
              color: "#FCFCFC",
              fontWeight: "400",
              fontSize: "16px",
            }}
            key={val.id}
          >
            {val.value}
          </li>
        ))}
    </ol> */}
        <div
          className="event-modal-details-address"
          style={{ opacity: "100%" }}
        >
          <img src="/images/calender.svg" width={16} />
          <span style={{ padding: "0px 8px " }}>
            {isOpenEventModal?.data?.event_time}
          </span>
          <span>{isOpenEventModal?.data?.event_date}</span>
        </div>
        <div className="event-modal-details-description">
          Get ready for our Black Friday Beauty Sale! Enjoy exclusive discounts
          on haircuts, styling, skincare, and luxury spa treatments. Pamper
          yourself with premium services at unbeatable prices. Hurry—these deals
          won’t last long! Book your appointment now and treat yourself to a
          glamorous makeover. Glow & Grace Salon – Because You Deserve the Best!
        </div>
        {/* {selectedSale?.term_conditions && ( */}
        <h6
          style={{
            color: "#FFFFFF",
            fontSize: "18px",
            fontWeight: "600",
            paddingTop: "16px",
          }}
        >
          Terms & Conditions{" "}
        </h6>
        {/* )} */}
        <ol style={{ listStyle: "outside", paddingLeft: "20px" }}>
          {/* {selectedSale?.term_conditions.length > 0 &&
        selectedSale?.term_conditions.map((val) => ( */}
          <li
            style={{
              color: "#9f9f9f",
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            Offer valid only during the Black Friday sale period.
            {/* {val.value} */}
          </li>
          {/* ))} */}
        </ol>
      </div>
    </Modal>
  );
};

export default EventsPopup;
