import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EmptyMessage from "../../Component/EmptyMessage";
import MyInterestCard from "../../Component/MyInterestCard";
import "./EditInterest.css";
import { useDispatch, useSelector } from "react-redux";
import { skeletonAction } from "../../Redux/Action/loadingAction";
import InfinitScroll from "react-infinite-scroll-component";
import {
  getEditInterests,
  updateCheckboxInterest,
} from "../../Redux/Action/BusinessAction/businesAction";

import debounce from "lodash/debounce";
import GridCardWrapper from "../../Component/GridCardWrapper";
const EditInterest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [search, setSearch] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [isNext, isNextFunc] = useState(false);
  const [pageCount, setCount] = useState(1);
  const [initialSelectionsLoaded, setInitialSelectionsLoaded] = useState(false);

  const PageSize = 20;

  const skeletonLoad = useSelector(
    (state) => state?.loadingReducers?.skeletonLoader
  );
  const user_uuid = localStorage.getItem("user_uuid");

  // Function to sort interests with selected ones first
  const sortInterestsBySelection = (interests, selectedIds) => {
    return [...interests].sort((a, b) => {
      if (a.status === 1 && b.status !== 1) return -1;
      if (a.status !== 1 && b.status === 1) return 1;
      return 0;
    });
  };

  const fetchData = useCallback(
    async (search = "", page = pageCount) => {
      const payload = {
        type: "Custom",
        user_uuid: user_uuid,
        page,
        limit: PageSize,
        text: search,
      };

      dispatch(skeletonAction(true, "EDIT_INTEREST"));
      try {
        const response = await dispatch(getEditInterests(payload));
        if (response?.data?.status === 200) {
          let newData;
          if (page === 1) {
            newData = sortInterestsBySelection(
              [...response.data.result.interestList],
              []
            );

            // Set selected interests based on status
            if (!initialSelectionsLoaded && search === "") {
              const newSelectedInterests = newData
                .filter((item) => item.status === 1)
                .map((item) => item.id);
              setSelectedInterests(newSelectedInterests);
              setInitialSelectionsLoaded(true);
            }
          } else {
            newData = [...responseData, ...response.data.result.interestList];
          }
          setResponseData(newData);

          const totalPageCount = Math.ceil(
            response.data.result.TotalRecords / PageSize
          );
          isNextFunc(page !== totalPageCount);
        }
      } catch (error) {
        console.error("Error fetching interests:", error);
      } finally {
        dispatch(skeletonAction(false, "EDIT_INTEREST"));
      }
    },
    [pageCount, user_uuid, dispatch, initialSelectionsLoaded, responseData]
  );

  const fetchMoreData = () => {
    setCount(pageCount + 1);
  };

  const debouncVersion = useCallback(debounce(fetchData), [fetchData]);
  //  Filter interests based on search by category name
  const filteredInterests = responseData.filter((interest) =>
    interest.category_name.toLowerCase().includes(search.toLowerCase())
  );
  useEffect(() => {
    debouncVersion();
  }, [pageCount]);

  useEffect(() => {
    if (pageCount === 1) {
      debouncVersion();
    } else {
      setCount(1);
    }
  }, []);

  useEffect(() => {
    debouncVersion(search, 1);
  }, [search]);

  useEffect(() => {
    if (filteredInterests.length > 0) {
      const allSelected = filteredInterests.every((i) =>
        selectedInterests.includes(i.id)
      );
      setSelectAll(allSelected);
    } else {
      setSelectAll(false);
    }
  }, [filteredInterests, selectedInterests]);

  const handleSelectAll = () => {
    const visibleIds = filteredInterests.map((i) => i.id);
    const allVisibleSelected = visibleIds.every((id) =>
      selectedInterests.includes(id)
    );

    setSelectedInterests((prev) => {
      return allVisibleSelected
        ? prev.filter((id) => !visibleIds.includes(id))
        : [...new Set([...prev, ...visibleIds])];
    });
  };

  const handleSaveChanges = async () => {
    dispatch(skeletonAction(true, "EDIT_INTEREST"));
    try {
      const allIds = responseData.map((i) => i.id);
      const toUnselect = allIds.filter((id) => !selectedInterests.includes(id));
      const payload = { id: toUnselect };
      const response = await dispatch(updateCheckboxInterest(payload));
  
      if (response?.payload?.status === 200) {
        // Navigate back with a flag to trigger refresh
        navigate("/my-interest", { 
          state: { fromEdit: true, timestamp: Date.now() } 
        });
      }
    } catch (error) {
      console.error("Error updating interests:", error);
    } finally {
      dispatch(skeletonAction(false, "EDIT_INTEREST"));
    }
  };

  const handleInterestSelect = (interestId) => {
    setSelectedInterests((prev) =>
      prev.includes(interestId)
        ? prev.filter((id) => id !== interestId)
        : [...prev, interestId]
    );
  };

  return (
    <div className="edit-interest-container">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <img
              src={"../images/back_btn.png"}
              alt="Back"
              height={25}
              width={76}
              onClick={() => navigate("/my-interest")}
              className="back-button"
            />
          </div>
        </div>
      </div>

      <section className="nib-boxes">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="search-section">
                <div className="search-container">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <input
                      type="text"
                      name="search"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                      className="search-input"
                      style={{ width: "502px" }}
                    />
                  </form>
                  <button className="search-btn">Search</button>
                </div>

                <div className="controls-container">
                  <label className="select-all-label">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                      className="select-all-checkbox"
                    />
                    {selectAll ? "Unselect All" : "Select All"}
                  </label>

                  <button
                    className="save-changes-btn"
                    onClick={handleSaveChanges}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>

            {!skeletonLoad["EDIT_INTEREST"] &&
            filteredInterests.length === 0 ? (
              <EmptyMessage
                message={`No Business found for the search ${search}`}
              />
            ) : (
              <InfinitScroll
                dataLength={responseData.length}
                next={fetchMoreData}
                hasMore={isNext}
              >
                {skeletonLoad["EDIT_INTEREST"] ? (
                  <GridCardWrapper
                    blockWidth={335}
                    blockHeight={400}
                    isLoading={true}
                    data={[1, 2, 3, 4, 5, 6]}
                  >
                    {() => <div style={{ width: "100%", height: "100%" }} />}
                  </GridCardWrapper>
                ) : (
                  <div className="interests-grid">
                    {filteredInterests.map((interest) => (
                      <div key={interest.id}>
                        <MyInterestCard
                          data={{
                            id: interest.id,
                            title: interest.category_name,
                            image: interest.category_image,
                          }}
                          width="100%"
                          height={287}
                          isEditMode={true}
                          isSelected={selectedInterests.includes(interest.id)}
                          onSelect={handleInterestSelect}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </InfinitScroll>
            )}
          </div>
        </div>
        <div className="for-border" />
      </section>
    </div>
  );
};

export default EditInterest;
