import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getFavorites } from "../../Redux/Action/BusinessAction/businesAction";
import InfinitScroll from "react-infinite-scroll-component";
import GridCardWrapper from "../../Component/GridCardWrapper";
import Card from "../../Component/Card";
import { FAVORITES_LIST } from "../../Utils/constants";
import { skeletonAction } from "../../Redux/Action/loadingAction";
import FavoriteList from "./FavoriteList";

const Favorite = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const skeletonLoad = useSelector(
    (state) => state?.loadingReducers?.skeletonLoader
  );
  const PageSize = 10;

  const [responseData, setResponseData] = useState([]);
  const [isNext, setIsNext] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchFavorites(search);
  }, [pageCount]);

  const businessPage = (id, b_name) => {
    navigate(
      `/businessDetails/${id}?name=${b_name?.trim()?.replaceAll(" ", "_")}`,
      { state: { id } }
    );
  };

  const fetchFavorites = async (search = "", page = pageCount) => {
    try {
      const body = {
        text: search,
        page,
        limit: PageSize,
      };

      dispatch(skeletonAction(true, FAVORITES_LIST));
      const response = await dispatch(getFavorites(body));

      if (response?.type === "GET_FAVORITES_SUCCESS") {
        const favoritesList = response?.payload?.result?.businessList || [];
        const totalRecords = response?.payload?.result?.TotalRecords || 0;

        if (page === 1) {
          setResponseData(favoritesList);
        } else {
          setResponseData((prev) => [...prev, ...favoritesList]);
        }

        const totalPageCount = Math.ceil(totalRecords / PageSize);
        setIsNext(pageCount !== totalPageCount);
      }
    } catch (error) {
      console.error("Error fetching favorites:", error);
    } finally {
      dispatch(skeletonAction(false, FAVORITES_LIST));
    }
  };

  const fetchMoreData = () => {
    setPageCount((prev) => prev + 1);
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 400);
    };
  };

  const debouncVersion = useCallback(debounce(fetchFavorites), []);

  useEffect(() => {
    setPageCount(1);
    debouncVersion(search, 1);
  }, [search]);

  const handleFavoriteUpdate = (businessId) => {
    setResponseData((prev) => prev.filter((item) => item.id !== businessId));
  };

  return (
    <>
      <div>
        <div className="container">
          <div className="row">
            {responseData.length > 0 && (
              <div className="col-md-12">
                <img
                  src={"../images/back_btn.png"}
                  alt="Edit"
                  height={25}
                  width={76}
                  onClick={() => navigate("/")}
                  style={{ cursor: "pointer", marginTop: "35px" }}
                />
              </div>
            )}
            <div className="col-md-12" style={{ marginTop: "48px" }}>
              <h2>
                <span style={{ color: "#ffffff" }}>Favorites</span>
              </h2>
            </div>
          </div>
        </div>

        <section className="nib-boxes">
          <div className="container">
            <div className="row">
              {!skeletonLoad[FAVORITES_LIST] && responseData.length === 0 ? (
                <div className="favorite-empty-container">
                  <FavoriteList />
                </div>
              ) : (
                <InfinitScroll
                  dataLength={responseData.length}
                  next={fetchMoreData}
                  hasMore={isNext}
                  loader={<h4>Loading...</h4>}
                >
                  <GridCardWrapper
                    blockWidth={300}
                    blockHeight={400}
                    isLoading={skeletonLoad[FAVORITES_LIST]}
                    data={responseData}
                  >
                    {(props) => (
                      <Card
                        gridView
                        showReview
                        showAddress
                        showPhone
                        handleViewDetail={businessPage}
                        onFavoriteUpdate={handleFavoriteUpdate}
                        {...props}
                        width={300}
                        height={415}
                      />
                    )}
                  </GridCardWrapper>
                </InfinitScroll>
              )}
            </div>
          </div>
          <div className="for-border" />
        </section>
      </div>
    </>
  );
};

export default Favorite;
