import React from "react";
import "./FavoriteList.css";

function FavoriteList() {
  return (
    // <div className="favorite-container">
    //   <h1 className="favorite-heading">Favourites</h1>
      <div className="favorite-content">
        <div className="favorite-wrapper">
          <div className="favorite-icon">
            <img src="./images/favorite.png" alt="Heart Icon" />
          </div>
          <p className="favorite-text">
            No Favorites found.
            <br />
            Start adding your favorites now!
          </p>
        </div>
      {/* </div> */}
    </div>
  );
}

export default FavoriteList;
