import React from "react";
import { isArray, isEmpty } from "lodash";

const Events = ({ event, isLoggedIn, setIsLoginModal, handleSelectEvent }) => {
  const BlurOverlay = ({ visible, onLoginClick, children }) => {
    return (
      <div className="overlay-container">
        {children}

        {!visible && (
          <div className="blur-overlay" onClick={() => setIsLoginModal(true)}>
            <div className="lock-icon">
              <i
                className="fas fa-lock"
                style={{ color: "#349b52", fontSize: "2rem" }}
              />
            </div>
          </div>
        )}
      </div>
    );
  };
  return !isEmpty(event.events) ? (
    event?.events
      ?.filter((ev) => ev.status == 1)
      ?.map((item, index) => {
        const {
          id,
          title,
          address,
          event_date,
          event_time,
          eventImage,
          event_url,
        } = item;
        return (
          <div key={id}>
            <BlurOverlay visible={isLoggedIn || isEmpty(event.events)}>
              <div
                className="event-card-wrap"
                onClick={() => handleSelectEvent(item)}
              >
                <img
                  src={
                    eventImage && eventImage.length > 0
                      ? eventImage[0].image
                      : "https://bgz-business.s3.us-east-2.amazonaws.com/FreeBusinessImage.jpg"
                  }
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "178px",
                    borderTopLeftRadius: "12px",
                    borderTopRightRadius: "12px",
                  }}
                />
                <div className="event-card-details-wrap">
                  <h2 className="event-card-details-title">{title}</h2>
                  {address && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        padding: "8px 0px",
                      }}
                    >
                      <img src="/images/location.svg" width={16} />
                      <div className="event-card-details-address">
                        {" "}
                        {address}
                      </div>
                    </div>
                  )}

                  {event_url && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        padding: "8px 0px",
                      }}
                    >
                      <img src="/images/link-icon.svg" width={16} />
                      <div className="event-card-details-address">
                        {event_url}
                      </div>
                    </div>
                  )}

                  <div className="event-card-details-address">
                    <img src="/images/tim-date.svg" width={16} />
                    <span style={{ padding: "0px 8px " }}>{event_time}</span>
                    <span>{event_date}</span>
                  </div>
                </div>
              </div>
            </BlurOverlay>
          </div>
        );
      })
  ) : (
    <div className="businessDetails-Noreview">
      <img src="/images/calender-icon.svg" />
      <div className="businessDetails-Noreview-content">
        No events found. <br />
        stay tuned
      </div>
    </div>
  );
};

export default Events;
