import React from "react";

const Slider = ({ isbusiness }) => {
  if (isbusiness?.free_image) {
    return (
      <div className="barber-slider">
        <div id="demo" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#demo"
              data-bs-slide-to={0}
              className="active"
            />
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={isbusiness?.free_image}
                alt="Los Angeles"
                className="d-block"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="barber-slider">
        <div id="demo" className="carousel slide" data-bs-ride="carousel">
          {/* Indicators/dots */}
          <div className="carousel-indicators">
            {isbusiness?.businessImage?.length > 0 &&
              isbusiness?.businessImage.map((item, index) => {
                return (
                  <button
                    type="button"
                    data-bs-target="#demo"
                    data-bs-slide-to={index}
                    className={index == 0 ? "active" : ""}
                    key={item?.id}
                  />
                );
              })}
          </div>
          {/* The slideshow/carousel */}
          <div className="carousel-inner">
            {isbusiness?.businessImage?.length > 0 &&
              isbusiness?.businessImage.map((item, index) => {
                return (
                  <div
                    className={`carousel-item ${index == 0 ? `active` : ``}`}
                    key={item?.id}
                  >
                    <img
                      src={item.image}
                      alt="Los Angeles"
                      className="d-block"
                      style={{ width: "100%" }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
};

export default Slider;
