import axios from "axios";
import store from "../Redux/Store/store";

const baseURL = process.env.REACT_APP_API_KEY

// const data = store.getState();

// const accessToken = data.LoginData?.accessToken;
// const refreshToken = data.LoginData?.refreshToken;
// let token = accessToken;
// if (isSendRefreshToken) {
//   token = refreshToken;
// }

// const accessToken = localStorage.getItem("accessToken");

export const axiosObj = axios.create({
  baseURL: baseURL,
  timeout: 30000,
  headers: {
    'Accept': "application/json",
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});

axiosObj.interceptors.request.use(function (config) {
  // console.log("Request:", JSON.stringify(config, null, 2));
  const token = localStorage.getItem("accessToken");

  config.headers.Authorization = "Bearer " + token;
  // if (config.url === "/ad/generatePaymentIntent") {
    config.headers["x-access-token"] = token;
  // }
  return config;
});
