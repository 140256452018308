
// import { UPDATE_INTEREST_SUCCESS } from "../../../test";
import {
  GET_BUSINESS_FEATURE_SUCCESS,
  GET_BUSINESS_FEATURE_ERROR,
  GET_POPULAR_CAT_SUCCESS,
  GET_POPULAR_CAT_ERROR,
  GET_LOCAL_HIGHTLIGHT_SUCCESS,
  GET_LOCAL_HIGHTLIGHT_ERROR,
  BUSINESS_LIST_SUCCESS,
  BUSINESS_LIST_ERROR,
  ADD_FAVORITE_SUCCESS,
  ADD_FAVORITE_ERROR,
  REMOVE_FAVORITE_ERROR,
  REMOVE_FAVORITE_SUCCESS,
  GET_SELECTED_INTEREST_ERROR,
  GET_SELECTED_INTEREST_SUCCESS,
  UPDATE_EDIT_INTERESTS,
  UPDATE_CHECKBOX_SUCCESS,
  UPDATE_CHECKBOX_ERROR
} from "../Action/actionTypes";
const initialState = {
  featureBusinessListSuccess: {},
  featureBusinessListError: {},
  papularCategorySuccess: {},
  papularCategoryError: {},
  localHightlightSuccess: {},
  localHightlightErorr: {},
  businessListSuccess: {},
  businessListError: {},
  businessSearchSuccess: {},
  localHighlightPageNo: 1,
  favoriteSuccess: {},
  favoriteError: {},

  selectedInterests: {
    data: [],
    totalRecords: 0,
    currentPage: 1,
  },
  updateInterestError: null
};
const BusinessData = (state = initialState, action) => {
  switch (action.type) {
    case GET_BUSINESS_FEATURE_SUCCESS:
      return {
        ...state, // not value lose
        featureBusinessListSuccess: action?.payload,
        featureBusinessListError: {},
      };
    case GET_BUSINESS_FEATURE_ERROR:
      return {
        ...state, // not value lose
        featureBusinessListError: action?.payload,
        featureBusinessListSuccess: {},
      };
    case GET_POPULAR_CAT_SUCCESS:
      return {
        ...state, // not value lose
        papularCategorySuccess: action?.payload,
        papularCategoryError: {},
      };
    case GET_POPULAR_CAT_ERROR:
      return {
        ...state, // not value lose
        papularCategoryError: action?.payload,
        papularCategorySuccess: {},
      };

    case GET_LOCAL_HIGHTLIGHT_SUCCESS:
      return {
        ...state, // not value lose
        localHightlightSuccess: action?.payload,
        papularCategoryError: {},
      };
    case GET_LOCAL_HIGHTLIGHT_ERROR:
      return {
        ...state, // not value lose
        localHightlightError: action?.payload,
        localHightlightSuccess: {},
      };

    case BUSINESS_LIST_SUCCESS:
      return {
        ...state, // not value lose
        businessListSuccess: action?.payload,
        businessListError: {},
      };
    case BUSINESS_LIST_ERROR:
      return {
        ...state, // not value lose
        businessListError: action?.payload,
        businessListSuccess: {},
      };
    case "SET_LOCAL_HIGHLIGHT_PAGE_COUNT":
      return {
        ...state, // not value lose
        localHighlightPageNo: action?.payload,
      };
    case "SEARCH_BUSINESS_SUCCESS":
      return {
        ...state,
        businessSearchSuccess: action,
      };

    case ADD_FAVORITE_SUCCESS:
      return {
        ...state,
        favoriteSuccess: action?.payload,
        favoriteError: {},
      };
    case ADD_FAVORITE_ERROR:
      return {
        ...state,
        favoriteError: action?.payload,
        favoriteSuccess: {},
      };

    case REMOVE_FAVORITE_SUCCESS:
      return {
        ...state,
        favoriteSuccess: action?.payload,
        favoriteError: {},
      };
    case REMOVE_FAVORITE_ERROR:
      return {
        ...state,
        favoriteError: action?.payload,
        favoriteSuccess: {},
      };
    case GET_SELECTED_INTEREST_SUCCESS:
      return {
        ...state,
        selectedInterests: {
          data: action.payload.interestList,
          totalRecords: action.payload.TotalRecords,
          currentPage: action.payload.Page,
        },
      };
    case GET_SELECTED_INTEREST_ERROR:
      return {
        ...state,
        selectedInterests: initialState.selectedInterests,
      };

    case "GET_EDIT_INTERESTS_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_EDIT_INTERESTS_SUCCESS":
      return {
        ...state,
        loading: false,
        editInterests: action.payload,
        error: null,
      };
    case "GET_EDIT_INTERESTS_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_EDIT_INTERESTS:
      return {
        ...state,
        editInterests: action.payload,
      };

      case UPDATE_CHECKBOX_SUCCESS:
        return {
          ...state,
          checkboxSuccess: action?.payload,
          checkboxError: {},
        };
      
      case UPDATE_CHECKBOX_ERROR:
        return {
          ...state,
          checkboxError: action?.payload,
          checkboxSuccess: {},
        };

    default:
      return state;
  }
};

export default BusinessData;
