import { GET_PROFILE_SUCCESS, GET_PROFILE_ERROR } from "../Action/actionTypes";

const initialState = {
  profileData: {},
  profileError: {}
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        profileData: action.payload,
        profileError: {}
      };
    case GET_PROFILE_ERROR:
      return {
        ...state,
        profileError: action.payload,
        profileData: {}
      };
    default:
      return state;
  }
};

export default profileReducer;