export const STRIPE_KEY =
  "pk_test_51K3BgPALne365GO9bpuPcBHj71iMOtlaXxmJnIDr8MUzORa6ttGRZJbVSPlJYsWs5q9aau35irZxQW2BFhKpTTgn00601KwtYp";

export const STRIPE_LIVE_KEY =
  "pk_live_51K3BgPALne365GO9uUpB9rVgtKQWQssxsqtsOvg78GJRzE0TreWgLxuoO2LeIFWfiMh9NSc1rFOGchNVRjGWRBov00egGTl86x";

export const IOS_APP_LINK = "https://apps.apple.com/us/app/brigiz/id1600877394";

export const ANDROID_APP_LINK =
  "https://play.google.com/store/apps/details?id=com.brigiz.app";

export const LATITUDE = 27.6648;
export const LONGITUDE = 81.5158;

export const LOCATION_ERROR_MSG =
  "There are currently no registered business in your area☹️ \n\n Explore other Regions.";

export const GOOGLE_API_KEY = 'AIzaSyAv1NXeIvFpcgy3zwAEC9h_vgTflroR6vw';

export const DAILY_DEAL = "DAILY_DEAL";
export const FEATURE_BUSINESS = "FEATURE_BUSINESS";
export const LOCAL_HIGHLIGHT = "LOCAL_HIGHLIGHT";
export const NEW_BUSINESS = "NEW_BUSINESS";
export const HOME_PAGE_DATA_LIST = "HOME_PAGE_DATA_LIST";
export const BUSINESS_TYPE_LIST = 'BUSINESS_TYPE_LIST'
export const BUSINESS_LIST = "BUSINESS_LIST";
export const BUSINESS_SEARCH = "BUSINESS_SEARCH";
export const BUSINESS_ADS = "BUSINESS_ADS";
export const NEW_INTEREST = "NEW_INTEREST"
export const FAVORITES_LIST = 'FAVORITES_LIST';
export const UPDATE_INTEREST_SUCCESS = "UPDATE_INTEREST_SUCCESS";
export const UPDATE_INTEREST_ERROR = "UPDATE_INTEREST_ERROR";


export const SIGN_UP_TYPE = {
  REGULAR: 'Regular',
  GOOGLE: "Google",
  FACEBOOK: "Facebook",
  APPLE: "Apple",
  PHONE: "Phone"
}
