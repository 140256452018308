import { useEffect } from "react";
// import { withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import {
  ADD_BUSINESS_DETAILS,
  ALLREVIEWS,
  CREATE_NEW_PASSWORD,
  FORGOT_PASSWORD,
  LOGIN,
  REGISTER,
  SIGNIN,
  SIGNINOTP,
} from "./RoutesConstant";
import { useSelector } from "react-redux";

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();
  const { setShow } = useSelector((state) => state.locationReducer);
  const routes = [SIGNIN, SIGNINOTP, LOGIN, REGISTER, ALLREVIEWS];
  // const showState = locationData.setShow;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);
  return (
    <>
      {( !pathname.includes(LOGIN) &&
          !pathname.includes(REGISTER) && 
          !pathname.includes(SIGNIN) &&
          !pathname.includes(SIGNINOTP) &&
          !pathname.includes(FORGOT_PASSWORD) &&
          !pathname.includes(CREATE_NEW_PASSWORD) ) ? (
        <Header showState={setShow} loginShow={true} />
      ) : (
        <Header showState={false} loginShow={false} />
      )}
      <div className={!pathname.includes(LOGIN) && "body_wrapper"}>
        {children || null}
        {pathname !== ALLREVIEWS &&
          !pathname.includes(LOGIN) &&
          !pathname.includes(REGISTER) && 
          !pathname.includes(SIGNIN) &&
          !pathname.includes(SIGNINOTP) &&
          !pathname.includes(FORGOT_PASSWORD) &&
          !pathname.includes(CREATE_NEW_PASSWORD) &&
          !pathname.includes(ADD_BUSINESS_DETAILS) &&


          <Footer />}
      </div>
    </>
  );
};

export default ScrollToTop;
