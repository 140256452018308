import React, { useEffect, useState } from "react";

import "./MyInterestModule.css";
import MyInterestCard from "../../Component/MyInterestCard";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EmptyMessage from "../../Component/EmptyMessage";
import InfinitScroll from "react-infinite-scroll-component";
import { getSelectedInterests } from "../../Redux/Action/BusinessAction/businesAction";

import { NEW_INTEREST } from "../../Utils/constants";

const MyInterestModule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
    const location = useLocation();
  const [pageCount, setPageCount] = useState(1);
  const pageSize = 10;
  const user_uuid = localStorage.getItem("user_uuid");
 const [loadedInterests, setLoadedInterests] = useState([]);
   const [totalRecords, setTotalRecords] = useState(0);
   const [hasMore, setHasMore] = useState(true); // Initialize as true to load first page
     const [initialLoad, setInitialLoad] = useState(true);
       const [refreshKey, setRefreshKey] = useState(0); 
        const [isFetchingMore, setIsFetchingMore] = useState(false); 
  const { selectedInterests } = useSelector((state) => ({
    selectedInterests: state.businessData.selectedInterests || {
      data: [],
      totalRecords: 0,
    },
  }));
  const skeletonLoad = useSelector(
    (state) => state?.loadingReducers?.skeletonLoader
  );

  const fetchInterests = () => {
    const payload = {
      type: "Custom",
      user_uuid: user_uuid,
      page: pageCount,
      limit: pageSize,
    };
    dispatch(getSelectedInterests(payload));
  };
 useEffect(() => {
    if (location.state?.fromEdit) {
      setPageCount(1);
      setLoadedInterests([]);
      setTotalRecords(0);
      setHasMore(true);
      fetchInterests();
    
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state?.fromEdit, refreshKey]);

     useEffect(() => {
    
       setPageCount(1);
       setLoadedInterests([]);
       setTotalRecords(0);
       setHasMore(true);
       setInitialLoad(true);
       fetchInterests();
     }, []);

   useEffect(() => {
     if (pageCount > 1) {
       setIsFetchingMore(true); 
       fetchInterests();
     }
   }, [pageCount]);


  useEffect(() => {
    if (selectedInterests.data.length > 0) {
      if (pageCount === 1) {
        setLoadedInterests(selectedInterests.data);
      } else {
        const newItems = selectedInterests.data.filter(
          newItem => !loadedInterests.some(existingItem => existingItem.id === newItem.id)
        );
        setLoadedInterests(prev => [...prev, ...newItems]);
      }
      
      setTotalRecords(selectedInterests.totalRecords);
      setHasMore(loadedInterests.length + selectedInterests.data.length < selectedInterests.totalRecords);
      setIsFetchingMore(false);
    }
  }, [selectedInterests.data]);

  const loadMore = () => {
    if (!skeletonLoad[NEW_INTEREST] && hasMore && !isFetchingMore) {
      setPageCount(prev => prev + 1);
    }
  };



  const handleEditInterests = () => {
    navigate("/edit-interest", { state: { fromMyInterests: true } });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <img
              src={"../images/back_btn.png"}
              alt="Back"
              height={25}
              width={76}
              onClick={() => navigate("/")}
              style={{ cursor: "pointer", marginTop: "35px" }}
            />
          </div>
          <div
            className="col-md-12"
            style={{ marginTop: "30px", marginBottom: "10px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2>
                <span style={{ color: "#ffffff" }}>My Interest</span>
              </h2>
              <button
                onClick={handleEditInterests}
                style={{
                  background: "none",
                  border: "1px solid #2C6A3F",
                  color: "#00ff9f",
                  cursor: "pointer",
                  fontSize: "16px",
                  padding: "8px 16px",
                  fontWeight: "400",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={"../images/edit_icon_interest.png"}
                  alt=""
                  height={20}
                  width={20}
                  style={{ marginRight: "5px" }}
                />
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>

   
    <section className="nib-boxes" style={{ marginTop: "0", padding: "0px" }}>
           <div className="container">
             {!skeletonLoad[NEW_INTEREST] && loadedInterests.length === 0 ? (
               <EmptyMessage message="No interests selected yet" />
             ) : (
               <div id="scrollableDiv" style={{ height: "calc(100vh - 200px)", overflow: "auto" }}>
                 <InfinitScroll
                   dataLength={loadedInterests.length}
                   next={loadMore}
                   hasMore={hasMore}
                   style={{ overflow: "auto", overflowX: "hidden" }}
                   loader={
                     <div style={{ 
                       display: "flex",
                       justifyContent: "center",
                       padding: "20px",
                       marginTop: "10px"
                     }}>
                       {isFetchingMore && (
                         <div className="loading-spinner">
                           <div className="spinner"></div>
                           <p>Loading...</p>
                         </div>
                       )}
                     </div>
                   }
                  
                   scrollableTarget="scrollableDiv"
                 >
                   <div
                     className="row"
                     style={{
                       display: "grid",
                       gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
                       gap: "24px",
                       padding: "10px 0",
                     }}
                   >
                     {skeletonLoad[NEW_INTEREST] && pageCount === 1
                       ? Array(4).fill(0).map((_, index) => (
                           <div key={index} style={{ width: "100%", height: "287px" }}>
                             <div style={{
                               width: "100%",
                               height: "100%",
                               backgroundColor: "#333",
                               borderRadius: "8px",
                             }} />
                           </div>
                         ))
                       : loadedInterests.map((interest) => (
                           <MyInterestCard
                             key={interest.id}
                             data={{
                               id: interest.id,
                               title: interest.category_name,
                               image: interest.category_image,
                             }}
                             width="100%"
                             height={287}
                             isEditMode={false}
                           />
                         ))}
                   </div>
                 </InfinitScroll>
             * </div> 
             )}
           </div>
         </section> 
    </div>
  );
};

export default MyInterestModule;
