import React from "react";

const EspPromotionInfo = () => {
  return (
    <>
      <section className="privacy-txt">
        <div className="container">
          <div className="row">
            <div className="col-md-12 wow fadeIn">
              <h3 style={{ textTransform: "uppercase" }}>
                Términos y condiciones promocionales
              </h3>

              <hr />
              <p>¡Gana una subvención empresarial de $5,000!</p>
              <p>
                ¡Crea una cuenta profesional en Brigiz hoy mismo para tener la
                oportunidad de ganar una subvención empresarial de $5,000.00!
              </p>
              <p>Se aplican condiciones.</p>
              <p>
                Como miembro valioso de la comunidad empresarial, queríamos
                compartir contigo una noticia emocionante. Brigiz, la plataforma
                dinámica que conecta negocios y clientes, se complace en
                anunciar nuestro exclusivo sorteo de una subvención empresarial
                de $5,000.
              </p>
              <p>
                Entendemos los desafíos que enfrentan los negocios en el
                competitivo panorama actual y queremos apoyar tu crecimiento y
                éxito. Es por eso que ofrecemos esta increíble oportunidad para
                que un negocio profesional afortunado gane una subvención de
                $5,000 para impulsar sus ambiciones y llevar su negocio a
                nuevas alturas.
              </p>
              <p>Participar en el sorteo es sencillo:</p>
              <ol>
                <li style={{ color: "#ffffff80", fontSize: "15px" }}>
                  Asegúrate de que tu negocio esté registrado como Profesional
                  en Brigiz. Si aún no lo has hecho, puedes crear fácilmente tu
                  perfil de negocio descargando la aplicación en tu dispositivo
                  Android o Apple.
                </li>
                <li style={{ color: "#ffffff80", fontSize: "15px" }}>
                  Completa tu perfil de negocio con información precisa y
                  actualizada. El negocio debe ofrecer un servicio o producto
                  que los clientes puedan comprar.
                </li>
                <li style={{ color: "#ffffff80", fontSize: "15px" }}>
                  El negocio debe ser guardado como favorito por un mínimo de 10
                  clientes.
                </li>
              </ol>
              <p>
                ¡Eso es todo! Una vez que cumplas con estos requisitos, entrarás
                automáticamente en el sorteo para tener la oportunidad de ganar
                la subvención empresarial de $5,000.
              </p>
              <p>
                La subvención se puede usar para financiar varios aspectos de tu
                negocio, ya sea para expandir tus esfuerzos de marketing,
                invertir en nuevos equipos o lanzar un proyecto emocionante.
                ¡Depende totalmente de ti! Imagina las posibilidades que este
                impulso financiero podría traer a tu negocio.
              </p>
              <p>
                Ten en cuenta que la fecha límite para participar es [Fecha
                límite]. El ganador será seleccionado al azar de todas las
                entradas elegibles, y el anuncio se hará en nuestro sitio web
                oficial y en nuestras plataformas de redes sociales el [Fecha de
                anuncio].
              </p>
              <p>
                Te animamos a aprovechar esta fantástica oportunidad y a formar
                parte de la creciente comunidad de negocios que se benefician de
                Brigiz. ¡No pierdas la oportunidad de ganar la subvención
                empresarial de $5,000!
              </p>
              <p>
                Si tienes alguna pregunta o necesitas asistencia con tu perfil
                de negocio o el sorteo, nuestro equipo de soporte dedicado está
                aquí para ayudarte. Simplemente contáctanos al 404-400-5399.
              </p>
              <p>
                Te deseamos la mejor de las suertes en el sorteo. ¡Esperamos ver
                tu negocio prosperar!
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EspPromotionInfo;
