import React, { useEffect, useState } from "react";

import Cookies from "js-cookie";
import swal from "sweetalert";
import EyesOnIcon from "../../../../assets/images/show.png";
import EyesOffIcon from "../../../../assets/images/hide.png";
import { login } from "../../../../Redux/Action/LoginAction/loginAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Modal from "../../../../Component/modal/Modal";
import { setItemLocalStorage } from "../../../../Utils/localStroge";
import { Link } from "react-router-dom";

const LoginPopup = ({ isLoginModal, setIsLoginModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [checkedVal, setCheckedVal] = useState("Customer");

  const [values, setValues] = useState({
    email: Cookies.get("email"),
    password: Cookies.get("password"),
  });

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({
      ...values,
      [name]: value,
    });
  };
  // ======= submit Login request ==============
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let body = JSON.stringify({
      email: values.email,
      password: values.password,
      platform: "android",
      os_version: "v1.0",
      app_version: "v.4.5",
      user_timezone: "GMT+5:30",
    });
    // console.log("body--", body);
    try {
      const response = await dispatch(login(body));
      const loginDataSuccess = response?.payload;
      if (loginDataSuccess) {
        if (loginDataSuccess?.data.status == 200) {
          if (
            loginDataSuccess?.data?.user?.role_id === 2 &&
            checkedVal === "Business"
          ) {
            if (loginDataSuccess?.data?.user?.is_deleted == 0) {
              if (
                loginDataSuccess?.data?.user?.user_state === "3" ||
                loginDataSuccess?.data?.user?.user_state === "4"
              ) {
                setItemLocalStorage(
                  "accessToken",
                  loginDataSuccess.data.access_token
                );
                setItemLocalStorage(
                  "refreshToken",
                  loginDataSuccess.data.refresh_token
                );
                setItemLocalStorage("id", loginDataSuccess.data.user.id);
                if (
                  loginDataSuccess?.data != "" &&
                  loginDataSuccess?.data.status === 200
                ) {
                  setIsLoginModal(false);
                } else {
                  navigate("/login2");
                }
              } else if (loginDataSuccess?.data?.user?.user_state === "1") {
                setItemLocalStorage(
                  "accessToken",
                  loginDataSuccess.data.access_token
                );
                setItemLocalStorage(
                  "refreshToken",
                  loginDataSuccess.data.refresh_token
                );
                setItemLocalStorage("id", loginDataSuccess.data.user.id);
                navigate("/adbusinessdetails");
              }
            } else {
              swal({
                title: "Inactive User!",
                text: "User is either Inactive or Deleted",
                buttons: false,
                icon: "error",
                timer: 3000,
              });
            }
          } else if (
            loginDataSuccess?.data?.user?.role_id === 2 &&
            checkedVal === "Customer"
          ) {
            swal({
              title: "error!",
              text: "Not a Customer account. Check and try again",
              buttons: false,
              icon: "error",
              timer: 3000,
            });
          } else if (
            loginDataSuccess?.data?.user?.role_id === 3 &&
            checkedVal === "Business"
          ) {
            swal({
              title: "error!",
              text: "Not a Business account. Check and try again",
              buttons: false,
              icon: "error",
              timer: 3000,
            });
          } else if (
            loginDataSuccess?.data?.user?.role_id === 3 &&
            checkedVal === "Customer"
          ) {
            setItemLocalStorage(
              "accessToken",
              loginDataSuccess.data.access_token
            );
            setItemLocalStorage(
              "refreshToken",
              loginDataSuccess.data.refresh_token
            );
            setItemLocalStorage("id", loginDataSuccess.data.user.id);
            if (
              loginDataSuccess?.data != "" &&
              loginDataSuccess?.data.status === 200
            ) {
              setIsLoginModal(false);
            } else {
              navigate("/login2");
            }
          }
        } else {
          swal({
            title: "error!",
            text: "Please complete your business details from the mobile app and try again",
            buttons: false,
            icon: "error",
            timer: 3000,
          });
        }
      } else {
        swal({
          title: "error!",
          text: loginDataSuccess?.data?.message,
          buttons: false,
          icon: "error",
          timer: 3000,
        });
      }
    } catch (err) {
      console.log(err);
      swal({
        title: "Invalid Credentials",
        text: err.response.data.message,
        buttons: false,
        icon: "error",
        timer: 3000,
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const checkHandler = (event) => {
    setCheckedVal(event.target.value);
  };
  return (
    <Modal
      isOpen={isLoginModal}
      onClose={() => setIsLoginModal(false)}
      style={{ width: '40%' }}
      className="login-modal-bg"
    >
      <div className="login_card bg-white p-5 mt-1">
        <form onSubmit={handleSubmit} className="login_form2">
          <h4 className="login_form_label_2">Login Your Account</h4>
          <div className="d-flex mt-4 row">
            <div className="form-check col-md-6">
              <input
                type="radio"
                className="form-check-input"
                id="radio1"
                name="optradio"
                value="Customer"
                onChange={checkHandler}
                checked={checkedVal === "Customer"}

              //   checked={checkedVal}
              />
              <label className="form-check-label2" for="radio1">
                For Customer
              </label>
            </div>
            <div className="form-check col-md-6">
              <input
                type="radio"
                className="form-check-input"
                id="radio2"
                name="optradio"
                value="Business"
                onChange={checkHandler}
                checked={checkedVal === "Business"}
              />

              <label className="form-check-label2" for="radio2">
                For Business
              </label>
            </div>
          </div>
          <label className="login_form_email2 mt-4 mb-2">Email Address</label>
          <input
            className="login_form_input2"
            type="text"
            name="email"
            onChange={handleInputChange}
          />

          <label className="login_form_email2 my-2">Password</label>
          <div className="password-input-container">
            <input
              className="login_form_input2"
              type={showPassword ? "text" : "password"}
              name="password"
              onChange={handleInputChange}
            />
            <button
              type="button"
              className="password-toggle-btn bg-transparent"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <img src={EyesOnIcon} className="eyes-icon" alt="" />
              ) : (
                <img src={EyesOffIcon} className="eyes-icon" alt="" />
              )}
            </button>
          </div>
          <div className="forgot_password text-end">
            <Link to="/forgotpassword">Forgot Password</Link>
          </div>
          <button className="login_form_btn mt-4" disabled={!checkedVal}>
            Sign In
          </button>
          <div className="signup_text text-center mt-3">
            Don’t have an account? <Link to="/register">Sign Up</Link>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default LoginPopup;
