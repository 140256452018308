import React from "react";

const Offers = ({ offers, handleOffer }) => {
  return offers?.length > 0 ? (
    offers?.map((item, index) => {
      const { id, title, offerImage } = item;
      return (
        <div
          className="event-box-img"
          key={id}
          onClick={() => handleOffer(item)}
        >
          <img
            src={
              offerImage && offerImage.length > 0
                ? offerImage[0].image
                : "https://bgz-business.s3.us-east-2.amazonaws.com/FreeBusinessImage.jpg"
            }
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
              borderRadius: "15px",
            }}
          />
        </div>
      );
    })
  ) : (
    <div className="businessDetails-Noreview">
      <img src="/images/offer.svg" />
      <div className="businessDetails-Noreview-content">
        No offer available. <br />
        stay tuned!
      </div>
    </div>
  );
};

export default Offers;
