import React, { useState, useEffect } from "react";
import { Link, useNavigate, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { setItemLocalStorage } from "../../Utils/localStroge";
import { ANDROID_APP_LINK, IOS_APP_LINK } from "../../Utils/constants";

import "./login.css";
import "react-international-phone/style.css";
import OtpInput from "react-otp-input";
import { forgotPasswordApi } from "../../API/ApiService";
import { loadingAction } from "../../Redux/Action/loadingAction";

function ForgotPassword({ pageName }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    email: Cookies.get("email"),
  });
  //   const [signupStatus, setSignUpStatus] = useState(1);

  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({
      ...values,
      [name]: value,
    });
  };

  // ======= submit Login request ==============
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(loadingAction(true));

    if (
      values?.email &&
      values?.email.length &&
      values?.email.match(isValidEmail)
    ) {
      // navigate(`/signinotp/${values?.email}`)
      let body = JSON.stringify({
        email: values?.email,
      });
      try {
        const response = await forgotPasswordApi(body);
        const loginDataSuccess = response;

        if (loginDataSuccess) {
          dispatch(loadingAction(false));

          setItemLocalStorage(
            "accessToken",
            loginDataSuccess.data.access_token
          );
          // setItemLocalStorage(
          //   "refreshToken",
          //   loginDataSuccess.data.refresh_token
          // );
         //
          swal({
            title: "Email Verified!",
            text: " OTP send to your Email.",
            buttons: false,
            icon: "success",
            timer: 3000,
          }).then(() =>
            navigate("/signinotp", { state: { forget_password: 1 } })
          );
        }
      } catch (err) {
        dispatch(loadingAction(false));

        swal({
          title: "Error",
          text: err?.response.data.message,
          buttons: false,
          icon: "error",
          timer: 3000,
        });
      }
    } else {
      dispatch(loadingAction(false));

      //show a error message or something like that
      swal({
        title: "Not valid Email!",
        text: "Not valid Email Id, Please use correct format.",
        buttons: false,
        icon: "error",
        timer: 3000,
      });
      return;
    }
  };

  return (
    <>
      <div className='container login2 '>
        <div className='row'>
          <div className='col-md-6 trust_sec'>
            <h1>
              Your Trusted
              <br />
              Local Connection
              <br />{" "}
              <label
                style={{
                  color: "#349c52",
                  position: "relative",
                }}
              >
                Starts Here
                <img
                  src='images/green-suf.png'
                  className='key_feature_designg2'
                  alt=''
                  style={{ left: "3px" }}
                />
              </label>
            </h1>
            <div id='download_app'>
              <div className='container login_download_app mt-1'>
                <h6>
                  <img
                    src='images/suffixes3.svg'
                    className='suffixes3'
                    alt=''
                  />
                  Please Download and Install Brigiz App
                  <br />
                  to Explore More features{" "}
                  <img
                    src='images/green-suf2.png'
                    className='green-suf2'
                    style={{
                      width: "141px",
                      height: "32px",
                      marginLeft: "-136px",
                    }}
                    alt=''
                  />
                </h6>
              </div>
              <div className=' exp-download-btns mt-4'>
                <button
                  onClick={() => window.open(ANDROID_APP_LINK, "_")}
                  style={{ background: "#292828" }}
                >
                  <div className='g_pay_container '>
                    <div className=' btn-img1'>
                      <img src='images/play-button.svg' alt='' />
                    </div>
                    <div className=' btn-txt1'>
                      <span>GET IT ON</span>
                      <p className='text-white m-0'>Google Play</p>
                    </div>
                  </div>
                </button>
                <button
                  onClick={() => window.open(IOS_APP_LINK, "_")}
                  style={{ width: "180px", background: "#292828" }}
                >
                  <div className='g_pay_container'>
                    <div className=' btn-img1'>
                      <img src='images/apple.svg' />
                    </div>
                    <div className='col-md-9 btn-txt1'>
                      <span>Download on the</span>
                      <p className='text-white m-0'>App Store</p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div className='col-md-6 form_screen' id='forgot_password'>
            <div className='login_card bg-white p-5 mt-1 mb-5'>
              <div className='login_back_btn'>
                {" "}
                <button
                  href='#'
                  style={{ verticalAlign: "text-bottom" }}
                  onClick={() => navigate(-1)}
                >
                  <img src='images/back.png' alt='' /> Back
                </button>
              </div>
              <form onSubmit={handleSubmit} className='login_form2'>
                <h4 className='login_form_label_2'>Forgot Password?</h4>
                <label className='login_form_email2 mt-4 mb-2'>
                  Enter your email address
                </label>
                <input
                  className='login_form_input2'
                  type='email'
                  name='email'
                  onChange={handleInputChange}
                />
                <button className='login_form_btn mt-4'>Next</button>
              </form>
            </div>
          </div>
        </div>
        <div id='mobile_view'>
          <div className='container login_download_app mt-1'>
            <h6>
              <img src='images/suffixes3.svg' className='suffixes3' alt='' />
              Please Download and Install Brigiz App
              <br />
              to Explore More features{" "}
              <img
                src='images/green-suf2.png'
                className='green-suf2'
                style={{
                  width: "168px",
                  height: "32px",
                  marginLeft: "-158px",
                }}
                alt=''
              />
            </h6>
          </div>
          <div className=' exp-download-btns mt-4'>
            <button
              onClick={() => window.open(ANDROID_APP_LINK, "_")}
              style={{ background: "#292828" }}
            >
              <div className='g_pay_container '>
                <div className=' btn-img1'>
                  <img src='images/play-button.svg' alt='' />
                </div>
                <div className=' btn-txt1'>
                  <span>GET IT ON</span>
                  <p className='text-white m-0'>Google Play</p>
                </div>
              </div>
            </button>
            <button
              onClick={() => window.open(IOS_APP_LINK, "_")}
              style={{ width: "180px", background: "#292828" }}
            >
              <div className='g_pay_container'>
                <div className=' btn-img1'>
                  <img src='images/apple.svg' />
                </div>
                <div className='col-md-9 btn-txt1'>
                  <span>Download on the</span>
                  <p className='text-white m-0'>App Store</p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
