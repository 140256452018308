export const HOME = "/";
export const LOGIN = "/login";
export const LOGIN2 = "/login2";
export const REGISTER = "/register";
export const SIGNIN = "/signin";
export const SIGNINOTP = "/signinotp";
export const FORGOT_PASSWORD = "/forgotpassword";
export const CREATE_NEW_PASSWORD = "/createnewpassword";
export const ADD_BUSINESS_DETAILS = "/adbusinessdetails";
export const MY_INTEREST = "/myinterest";
export const DAILY_DEAL = "/dailydeals";
export const BUSINESS_DETAILS = "/businessDetails/:id";
export const BUSINESS_LIST = "/businessList/:type";
export const CONTACT = "/contactUs";
export const EXPLORE_APP = "/exploreApp";
export const AD_PURCHASE = "/adPurchase";
export const PLANONE = "/planOne";
export const ALLREVIEWS = "/allReviews";
export const POLICY = "/privacyPolicy";
export const TERM = "/terms";
export const PROMOTIONINFO = "/promotionInfo";
export const EVENTS = "/events";
export const SUCCESSFUL_PAYMENT = "/successfullPayment";
export const FAILED_PAYMENT = "/failedPayment";

export const BUSINESS_SEARCH_RESULT = "/businessSearchResult";
export const BUSINESS_LIST_BY_ID = "/id=:id/business";

export const ESP_POLICY = "/esp-privacyPolicy";
export const ESP_TERM = "/esp-terms";
export const ESP_PROMOTIONINFO = "/esp-promotionInfo";

export const FAVORITE = "/favorite";

export const EDIT_PROFILE_PAGE = "/edit-profile";
export const MY_INTEREST_PAGE = "/my-interest";
export const EDIT_INTEREST = "/edit-interest";
