import React from "react";

const MyInterestCard = ({
  width = 300,
  height = 287,
  marginLeft = 0,
  marginBottom = 20,
  data,
  handleViewDetail,
  isEditMode = false,
  isSelected = false,
  onSelect,
}) => {
  const handleCheckboxClick = (e) => {
    e.stopPropagation();
    onSelect && onSelect(data.id);
  };

  return (
    <div
      className="nib-box"
      style={{
        width: `${width}px`,
        minWidth: `${width}px`,
        height: `${height}px`,
        marginLeft,
        marginBottom,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflow: "hidden",
        boxSizing: "border-box",
        // padding: "10px",
        padding: "0",
        borderRadius: "8px",
        cursor: "pointer",
        transition: "transform 0.3s ease-in-out",
        backgroundColor: "#1a1a1a",
        border: "none",
      }}
      onClick={() => handleViewDetail && handleViewDetail(data?.id, data.title)}
    >
      {/* Only show checkbox if in edit mode */}
      {isEditMode && (
        <div
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            zIndex: 2,
          }}
        >
          <input
            type="checkbox"
            checked={isSelected}
            onChange={handleCheckboxClick}
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              accentColor: "#28a745",
              border: "2px solid #ffffff",
              borderRadius: "3px",
            }}
          />
        </div>
      )}

      <div style={{ flex: 1, border: "none" }}>
        <img
          src={data?.image || "https://via.placeholder.com/300x200"}
          style={{
            width: "100%",
            objectFit: "cover",
            height: "100%",
            borderRadius: "8px",
            display: "block",
          }}
          alt={data?.title}
        />

        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              "linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 30%, rgba(0,0,0,0.1) 100%)",
            borderRadius: "8px",
          }}
        />

        <div
          style={{
            position: "absolute",
            bottom: "20px",
            left: "24px",
            right: "20px",
          }}
        >
          <h4
            style={{
              color: "white",
              margin: 0,
              fontSize: "1.25rem",
              fontWeight: 600,
              textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
            }}
          >
            {data?.title}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default MyInterestCard;
