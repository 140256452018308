import React from "react";

const EspTerm = () => {
  return (
    <>
      <section className="privacy-txt">
        <div className="container">
          <div className="row">
            <div className="col-md-12 wow fadeIn">
              <h3 style={{ textTransform: "uppercase" }}>
                ACUERDO DE LICENCIA DE USUARIO FINAL DE LA APLICACIÓN MÓVIL
                BRIGIZ
              </h3>
              <hr />
              <p>
                Esta Licencia de Usuario Final ("Licencia") es entre usted y
                Brigiz, Inc ("Brigiz") y regula el uso de la aplicación Brigiz
                (la "App"). La App de Brigiz le permite utilizar ciertos
                aspectos del servicio de Brigiz, disponible en{" "}
                <a href="www.brigiz.com">www.brigiz.com </a> y funciones
                específicas solo disponibles a través de la App (el "Servicio").
              </p>
              <ol>
                <li
                  style={{
                    color: "#ffffff80",
                    fontSize: "15px",
                    marginBottom: "1rem",
                  }}
                >
                  Partes. Esta Licencia es entre usted y Brigiz únicamente, y no
                  con el distribuidor de la aplicación o el sistema operativo de
                  su dispositivo móvil (por ejemplo, Apple, Android, Amazon,
                  Nokia, Samsung o Microsoft). Brigiz, no estas empresas, es el
                  único responsable de la App y su contenido. Dependiendo del
                  sistema operativo de su dispositivo móvil o del distribuidor
                  de la App y sus términos de servicio aplicables en la tienda
                  de aplicaciones, su operador de dispositivo móvil o
                  distribuidor de la App puede tener el derecho de hacer cumplir
                  esta Licencia contra usted como beneficiario de terceros.
                </li>
                <li
                  style={{
                    color: "#ffffff80",
                    fontSize: "15px",
                    marginBottom: "1rem",
                  }}
                >
                  Términos de Servicio. Su uso del Servicio a través de la App
                  está sujeto a los Términos de Servicio de Brigiz, que forman
                  parte de esta Licencia.
                </li>
                <li
                  style={{
                    color: "#ffffff80",
                    fontSize: "15px",
                    marginBottom: "1rem",
                  }}
                >
                  Anuncios, Consejos y Otros Contenidos. Brigiz es una
                  plataforma que permite a una empresa anunciar sus productos y
                  servicios en el sitio web de Brigiz (“Anuncios”) y a través de
                  aplicaciones móviles propiedad y operadas por Brigiz. Tales
                  Anuncios se proporcionan sin ninguna garantía o
                  representación, y usted asume toda la responsabilidad y el
                  riesgo por la forma en que decide usar dichos Anuncios. Usted
                  entiende y acepta que los Anuncios pueden hacer afirmaciones
                  que no serán verificadas por Brigiz, que Brigiz simplemente
                  proporciona el medio para que un negocio local liste sus
                  productos y servicios, y que tales Anuncios no son un
                  sustituto para que usted realice su propia diligencia debida
                  con respecto a un negocio local y sus productos y servicios.
                </li>
                <li
                  style={{
                    color: "#ffffff80",
                    fontSize: "15px",
                    marginBottom: "1rem",
                  }}
                >
                  Privacidad. Brigiz puede recopilar y usar su información como
                  se establece en su Política de Privacidad, que es parte de
                  esta Licencia. Brigiz hace las siguientes divulgaciones
                  específicas de privacidad para la App: Brigiz puede recopilar
                  y usar información relacionada con sus dispositivos, como el
                  ID de dispositivo único ("UDID"), el tipo de dispositivo, el
                  sistema operativo y la información del operador. Brigiz puede
                  recopilar y almacenar información sobre sus actividades en la
                  app y el estado en línea/fuera de línea y vincular esa
                  información a su UDID para mejorar las características y
                  funcionalidades de la App. Brigiz puede compartir dicha
                  información con proveedores que alimentan ciertas
                  características de la App, como reconocimiento de voz o
                  notificaciones push, y con proveedores de análisis que nos
                  ayudan a entender mejor la demografía de los usuarios de
                  nuestra App. Brigiz no permitirá que los anunciantes y redes
                  publicitarias que colocan anuncios en la App recopilen,
                  almacenen y usen su información sobre el uso de la App, su
                  área geográfica general y su interacción con los anuncios, y
                  que vinculen estos datos con su UDID para su propio uso,
                  incluyendo la orientación de anuncios hacia usted. Con su
                  consentimiento, Brigiz puede recopilar y almacenar su
                  información exacta de ubicación geográfica para activar una
                  “característica específica” en su App, que permite a los
                  usuarios de la App ver negocios locales relevantes cerca de su
                  ubicación actual. No compartiremos esta información con
                  anunciantes y redes publicitarias para dirigir anuncios hacia
                  usted basados en su ubicación geográfica. Si interactuó con
                  uno de nuestros anuncios móviles promocionando la App, es
                  posible que hayamos utilizado una etiqueta especial para
                  reconocerle cuando instale la App. Hacemos esto para medir el
                  rendimiento de nuestras descargas y la eficiencia del uso de
                  la App.
                </li>
                <li
                  style={{
                    color: "#ffffff80",
                    fontSize: "15px",
                    marginBottom: "1rem",
                  }}
                >
                  Licencia Limitada. Brigiz le otorga una licencia limitada, no
                  exclusiva, no transferible y revocable para usar la App para
                  sus propósitos personales y no comerciales. Solo puede usar la
                  App en un dispositivo que posea o controle y según lo permita
                  los términos de servicio de la tienda de aplicaciones donde
                  haya descargado la app.
                </li>
                <li
                  style={{
                    color: "#ffffff80",
                    fontSize: "15px",
                    marginBottom: "1rem",
                  }}
                >
                  Garantía. La App se proporciona de forma gratuita "tal cual".
                  Como tal, Brigiz renuncia a todas las garantías sobre la App
                  de Brigiz en la máxima medida permitida por la ley. En la
                  medida en que exista alguna garantía bajo la ley que no pueda
                  ser renunciada, Brigiz, no el distribuidor de la app ni el
                  sistema operativo de su dispositivo móvil, será el único
                  responsable de dicha garantía.
                </li>
                <li
                  style={{
                    color: "#ffffff80",
                    fontSize: "15px",
                    marginBottom: "1rem",
                  }}
                >
                  Mantenimiento y Soporte. Brigiz proporciona soporte limitado
                  para la App y la App se proporciona "tal cual". En la medida
                  en que se requiera cualquier mantenimiento o soporte por la
                  ley aplicable, Brigiz, no el distribuidor de la app ni el
                  sistema operativo de su dispositivo móvil, estará obligado a
                  proporcionar dicho mantenimiento o soporte.
                </li>
                <li
                  style={{
                    color: "#ffffff80",
                    fontSize: "15px",
                    marginBottom: "1rem",
                  }}
                >
                  Reclamos de Propiedad Intelectual de Terceros. Brigiz no
                  estará obligado a indemnizar o defenderle respecto a cualquier
                  reclamo de terceros que surja de o se relacione con la App de
                  Brigiz. En la medida en que Brigiz esté obligado a
                  proporcionar indemnización por la ley aplicable, Brigiz, no el
                  distribuidor de la app ni el sistema operativo de su
                  dispositivo móvil, será el único responsable de la
                  investigación, defensa, resolución y resolución de cualquier
                  reclamo de que la App de Brigiz o su uso infringe cualquier
                  derecho de propiedad intelectual de terceros.
                </li>
                <li
                  style={{
                    color: "#ffffff80",
                    fontSize: "15px",
                    marginBottom: "1rem",
                  }}
                >
                  Reclamos de Producto. Brigiz, no el distribuidor de la app ni
                  el sistema operativo de su dispositivo móvil, es responsable
                  de abordar cualquier reclamo por parte de usted relacionado
                  con la App de Brigiz o su uso, incluyendo, pero no limitado a:
                  (i) cualquier reclamo de responsabilidad del producto; (ii)
                  cualquier reclamo de que la App de Brigiz no cumple con
                  cualquier requisito legal o regulatorio aplicable; y (iii)
                  cualquier reclamo que surja bajo protección del consumidor o
                  legislación similar. Nada en esta Licencia se considerará una
                  admisión de que usted pueda tener tales reclamos.
                </li>
                <li
                  style={{
                    color: "#ffffff80",
                    fontSize: "15px",
                    marginBottom: "1rem",
                  }}
                >
                  Cumplimiento Legal de EE.UU. Usted declara y garantiza que (i)
                  no se encuentra en un país que esté sujeto a un embargo del
                  Gobierno de EE.UU., o que haya sido designado por el Gobierno
                  de EE.UU. como un país que "apoya el terrorismo", y (ii) no
                  figura en ninguna lista del Gobierno de EE.UU. de partes
                  prohibidas o restringidas.
                </li>
                <li
                  style={{
                    color: "#ffffff80",
                    fontSize: "15px",
                    marginBottom: "1rem",
                  }}
                >
                  Información de Contacto. Si tiene alguna pregunta, queja o
                  reclamo relacionado con la App de Brigiz, por favor
                  contáctenos en support@Brigiz.com.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EspTerm;
