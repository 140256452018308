import React, { useState, useEffect } from "react";
import { usePosition } from "./usePosition";

import { Loader } from "./Pages/Loader/Loader";
import RoutesComponent from "./Routes/Routes";
import { useDispatch, useSelector } from "react-redux";
import { setLatLng, setLocation, setShow } from "./Redux/Action/LocationAction";
import { LATITUDE, LONGITUDE } from "./Utils/constants";
import { checkForSameLocation } from "./Utils/helperFunction";
import SkeletonLoader from "./Component/skeleton-loader/Skeleton";

function App() {
  const { latitude, longitude, error } = usePosition();
  const dispatch = useDispatch();

  const { latLng } = useSelector((state) => state.locationReducer);

  useEffect(() => {
    const localLat = latLng.lat;
    const localLong = latLng.lng;

    // console.log(latitude, longitude,checkForSameLocation(latitude, longitude, localLat, localLong), "GEOLocation");
    // console.log(error, "Location Error");
    if (latitude) {
      if (!checkForSameLocation(latitude, longitude, localLat, localLong)) {
        dispatch(setLocation(""));
        dispatch(setShow(false));
        dispatch(
          setLatLng({
            lat: latitude,
            lng: longitude,
          })
        );
        localStorage.setItem("latitude", latitude);
        localStorage.setItem("longitude", longitude);
      }
    }
    if (error) {
      if (LATITUDE !== localLat && LONGITUDE !== localLong) {
        dispatch(setLocation("FL"));
        dispatch(setShow(true));
        dispatch(
          setLatLng({
            lat: LATITUDE,
            lng: LONGITUDE,
          })
        );
        localStorage.setItem("latitude", LATITUDE);
        localStorage.setItem("longitude", LONGITUDE);
      }
    }
  }, [latitude, longitude, error, latLng]);

  return (
    <div className="App" id="app">
      {/* <code>
      latitude: {latitude}<br/>
      longitude: {longitude}<br/>
      error: {error}
      </code> */}

      <RoutesComponent />
      <Loader />
    </div>
  );
}

export default App;
