import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getReviews } from "../../API/ApiService";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import { loadingAction } from "../../Redux/Action/loadingAction";

const pageSize = 10;
const AllReviews = () => {
  const [reviews, setData] = useState();
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  const totalPageCount = Math.ceil((reviews?.count || 0) / pageSize);

  useEffect(() => {
    getData();
  }, [page]);

  const getData = () => {
    const obj = {
      page: page,
      limit: pageSize,
    };
    dispatch(loadingAction(true));
    getReviews(obj)
      .then((res) => {
        if (page === 1) {
          setData(res?.data?.result);
        } else {
          setData({
            ...res?.data?.result,
            rows: [...reviews.rows, ...res?.data?.result?.rows],
          });
        }
        dispatch(loadingAction(false));
      })
      .catch((err) => {
        dispatch(loadingAction(false));
      });
  };

  return (
    <div style={{ display: "flex", flexFlow: "column" }}>
      <div style={{ display: "flex", flexFlow: "column", height: "100%" }}>
        <section className="all-reviews" style={{ height: "100%" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3>All reviews</h3>
                <hr />
              </div>
              {reviews?.rows?.map((r, i) => (
                <div className="col-md-6" key={i}>
                  <div className="ar-review-one">
                    <div className="exp-client-img">
                      <img
                        src={r.profile_image}
                        // class="img-fluid"
                        width={70}
                        height={70}
                      />
                    </div>
                    <div className="exp-client-name">
                      <h5>{r.name}</h5>
                      <ul>
                        {Array.from(Array(parseInt(r?.rating || 0)), (e, i) => {
                          return (
                            <li key={i}>
                              <i className="fa-solid fa-star" />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <p>{r.review}</p>
                  </div>
                </div>
              ))}
              {totalPageCount !== page && (
                <div className="col-md-12">
                  <button className="ar-see-more" onClick={() => setPage(page + 1)}>
                    See More
                  </button>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AllReviews;
