import React from "react";
import { isArray, isEmpty } from "lodash";

const BusinessOpenHours = ({ isBusinessHours }) => {
  const isDataCorrect = isArray(JSON.parse(isBusinessHours?.day_hours));
  const day_hours_data = JSON.parse(isBusinessHours?.day_hours);
  const custom_data = JSON.parse(isBusinessHours?.custom_hours);
  if (custom_data) {
    var dt = new Date();

    var firstDay = dt.getDate() - dt.getDay();
    var lastDay = dt.getDate() + 6 - dt.getDay();

    custom_data.map((itm, idx) => {
      const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      var day = new Date(itm.day);
      var date = day.getDate();
      if (date >= firstDay && date <= lastDay) {
        var week = weekday[day.getDay()];
        custom_data[idx].day = week;
        var dayidx = weekday.indexOf(week);
        day_hours_data[dayidx - 1] = custom_data[idx];
      }
    });
  }

  return (
    isDataCorrect &&
    day_hours_data.map((item, index) => {
      const { day, hours } = item;

      return (
        item?.toggle != false && (
          <div className="open-hours-block" key={index}>
            <div className="open-hours-days">{day}</div>
            <div>
              {hours.map((data, key) => {
                return (
                  <div key={key} className="open-hours-hours">
                    <span> {data?.open_hour} </span> to{" "}
                    <span> {data?.close_hour}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )
      );
    })
  );
};

export default BusinessOpenHours;
