import axios from "axios";
import { axiosObj } from "./BaseApi";

export const loginApi = (data) => {
  return axiosObj.post("/users/login", data);
};

export const registerApi = (data) => {
  return axiosObj.post("/users/register", data);
};

export const googleRegisterUpdate  = (data) => {
  return axiosObj.post("/users/google_register_update", data);
}

export const facebookRegisterUpdate  = (data) => {
  return axiosObj.post("/users/facebook_register_update", data);
}
export const appleRegisterUpdate  = (data) => {
  return axiosObj.post("/users/apple_register_update", data);
}
export const phoneRegisterUpdate  = (data) => {
  return axiosObj.post("/users/update_phone_profile", data);
}

export const verifyOtpApi = (data) => {
  return axiosObj.post("/users/otp_verify", data);
};

export const resendOtpApi = () => {
  return axiosObj.post("/users/resend_otp");
};

export const forgotPasswordApi = (data) => {
  return axiosObj.post("/users/forgot_password", data);
};

export const loginByPhoneApi = (data) => {
  return axiosObj.post("/users/login_by_phone", data);
};

export const changePasswordApi = (data) => {
  return axiosObj.post("/users/change_password", data);
};

export const featuredBusinessApi = (data) => {
  return axiosObj.post("/business/homepage", data);
};

export const getPopularCatApi = (data) => {
  return axiosObj.post("/business/get_interest", data);
};

export const getBusenessDatailApi = (data) => {
  return axiosObj.post("/business/get_business_detail_by_id", data);
};

export const localHighlightApi = (data) => {
  return axiosObj.post("/business/getlocalhighlightbusinesses", data);
};

export const getNewBuisnes = (data) => {
  return axiosObj.post("/business/getnewinbusiness", data);
};

export const getDailyDeals = (data) => {
  return axiosObj.post("/business/getdailydeals", data);
};

export const getFeatureBusiness = (data) => {
  return axiosObj.post("/business/getfeaturedbusinesses", data);
};

export const contactusApi = (data) => {
  return axiosObj.post("/contactus/add_web_contact_us", data);
};

export const businessEventApi = (id) => {
  return axiosObj.get("/events/view_event/" + id);
};

export const getAllCMSData = () => {
  return axiosObj.get("/cms/all_cms_data");
};

export const getBusenessData = () => {
  return axiosObj.get("/business/get_business_detail");
};

export const subscribeApi = (data) => {
  return axiosObj.post("/contactus/add_subscribed_user/", data);
};

export const getAllPlanApi = (data) => {
  return axiosObj.post("/ad/get_all_ad_plans");
};

export const getAllBusinessApi = (data, type) => {
  if (type === "new") {
    return axiosObj.post("/business/getnewinbusiness", data);
  } else if (type === "featured") {
    return axiosObj.post("/business/getfeaturedbusinesses", data);
  } else if (type === "Daily-Deals") {
    return axiosObj.post("/business/getdailydeals", data);
  } else if (type === "localHighlights") {
    return axiosObj.post("/business/getlocalhighlightbusinesses", data);
  } else {
    return axiosObj.post("/business/getnewinbusiness", data);
  }
};

export const getBusinessTypeApi = () => {
  return axiosObj.get("/business/get_business_types_web");
};

export const searchBusinessApi = (data) => {
  return axiosObj.post("/business/search_business_details", data);
};

export const webSearchBusinessApi = (data) => {
  return axiosObj.post("/business/web_search_business_details", data);
};

export const generatePaymentIntent = (data) => {
  return axiosObj.post("/ad/generatePaymentIntentWeb", data);
};

export const getReviews = (data) => {
  return axiosObj.post("/reviews/get_app_reviews", data);
};

export const getStates = (data) => {
  return axiosObj.get("/ad/state_plan_price");
};

export const getAnnouncements = (id) => {
  return axiosObj.get(`/announcements/view_announcement/${id}`);
};

export const getSales = (id) => {
  return axiosObj.get(`/sales/view_sale/${id}`);
};
export const getOffer = (business_id) => {
  return axiosObj.get(`/offers/view_offer_web/${business_id}/active`);
};
export const getSchedule = (id) => {
  return axiosObj.post("/ad/get_schedules", id);
};
export const getAds = (data) => {
  return axiosObj.post("/ad/get_ad", data);
};
export const getInterest = (data) => {
  return axiosObj.post("/business/get_my_interest", data);
};
export const googleRegister = (data) => {
  return axiosObj.post("/users/google_register", data);
};
export const fbRegister = (data) => {
  return axiosObj.post("/users/facebook_register", data);
};
export const addBusinessImage = (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_KEY}/business/add_business_image`,
    data,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("accessToken"),
        // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTEzMywidXNlcl91dWlkIjoiZGZlZDhlYmEtZTU4Ny00YTdiLWJjZWItMGNjOGRlZjY1OWI1IiwiaWF0IjoxNzM5ODY4NjgzLCJleHAiOjE3NDE1OTY2ODN9.5PGXq0BvlqBXb5xjUtw3yqu-d1P0VOYD0QRXV-1sTl4",
      },
    }
  );
};

export const getBusinessCategories = () => {
  return axiosObj.get("/business/get_business_categories");
};

export const getBusinessTypes = () => {
  return axiosObj.get("/business/get_business_types");
};

export const addBusinessDetails = (data) => {
  return axiosObj.post("/business/add_business_details", data);
};

export const getProfileApi = () => {
  return axiosObj.post("/users/getprofile");
};
export const updateProfileImageApi = (data) => {
  return axiosObj.post("/users/update_profile_image", data);
};
export const updateUserProfile = (data) => {
  return axiosObj.post("/users/update_user_profile", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const deleteUserApi = () => {
  return axiosObj.get("/users/delete_user");
};
export const Selectfavorite = (data) => {
  return axiosObj.post("/business/add_favourite_business",data);
};
export const Removefavorite = (data) => {
  return axiosObj.post("/business/delete_favourite_business",data);
};
export const getAddFavoriteList = (data) => {
  return axiosObj.post("/business/get_favourite_business",data);
};
export const getAllSelectedInterest = (data) => {
  return axiosObj.post("/business/get_interest", data);
};
export const getAllEditInterest = (data) => {
  return axiosObj.post("/business/get_my_interest", data);
};
export const updateCheckboxEditInterest = (data) => {
  return axiosObj.post("/business/is_not_interested", data);
};
