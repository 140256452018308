import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getItemLocalStorage } from "../Utils/localStroge";
import {
  HOME,
  BUSINESS_DETAILS,
  CONTACT,
  EXPLORE_APP,
  AD_PURCHASE,
  LOGIN,
  LOGIN2,
  SIGNIN,
  SIGNINOTP,
  REGISTER,
  FORGOT_PASSWORD,
  CREATE_NEW_PASSWORD,
  ADD_BUSINESS_DETAILS,
  BUSINESS_LIST,
  POLICY,
  TERM,
  PROMOTIONINFO,
  PLANONE,
  ALLREVIEWS,
  BUSINESS_SEARCH_RESULT,
  SUCCESSFUL_PAYMENT,
  FAILED_PAYMENT,
  BUSINESS_LIST_BY_ID,
  EVENTS,
  ESP_POLICY,
  ESP_PROMOTIONINFO,
  ESP_TERM,
  MY_INTEREST,
  DAILY_DEAL,
  EDIT_PROFILE_PAGE,
  FAVORITE,
  MY_INTEREST_PAGE,
  EDIT_INTEREST
} from "./RoutesConstant";
import Home from "../Pages/Home/Home";
import BusinessDetails from "../Pages/Business/BusinessDetails";
import SearchBusinessList from "../Pages/Business/SearchBusinessList";
import Contact from "../Pages/Contact/Contact";
import ExploreApp from "../Pages/ExploreApp/ExploreApp";
import AdPurchase from "../Pages/AdPurchase/AdPurchase";
import Login from "../Pages/Login";
import Login2 from "../Pages/Login/Login";

import BusinessList from "../Pages/Business/BusinessList";
// import Policy from "../Pages/Policy/Policy";
import Term from "../Pages/Term/Term";
import PromotionInfo from "../Pages/PromotionInfo/PromotionInfo";
import PlanOne from "../Pages/PlanOne/PlanOne";
import AllReviews from "../Pages/AllReviews/AllReviews";
// import { PrivateRoute } from "./PrivateRoutes";
import { Suspense } from "react";
import ScrollToTop from "./ScrollToTop";
import { getBusenessData } from "../API/ApiService";
import { Loader } from "../Pages/Loader/Loader";
// import Events from "../Pages/events/Events";
import EspPolicy from "../Pages/esp/EspPolicy";
import EspPromotionInfo from "../Pages/esp/EspPromotionInfo";
import EspTerm from "../Pages/esp/Espterm";
import Register from "../Pages/Login/Register";
import SignIn from "../Pages/Login/SignIn";
import SignInOTP from "../Pages/Login/SignInOTP";
import ForgotPassword from "../Pages/Login/ForgotPassword";
import CreateNewPassword from "../Pages/Login/CreateNewPassword";
import NotFoundPage from "../Component/NotFoundPage";
import AdBusinessDetails from "../Pages/Business/AdBusinessDetails";
import MyInterest from "../Pages/Interest/MyInterest";
import DailyDeals from "../Pages/DailyDeals/DailyDeals";
import EditProfile from "../Pages/ProfileDropdown/EditProfile";
import Favorite from "../Pages/FavoriteDropdown/Favorite";
import FavoriteList from "../Pages/FavoriteDropdown/FavoriteList";import MyInterestModule from "../Pages/MyInterestDropdown/MyInterestModule";
import EditInterest from "../Pages/MyInterestDropdown/EditInterest";


const Policy = React.lazy(() => import("../Pages/Policy/Policy"));
const SuccessPayment = React.lazy(() =>
  import("../Pages/AdPurchase/SuccessfullPayment")
);
const FailedPayment = React.lazy(() =>
  import("../Pages/AdPurchase/FailedPayment")
);
const RoutesComponet = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    getItemLocalStorage("accessToken") ? true : false
  );
  const userLogin = useSelector((state) => {
    return state.LoginData;
  });
  useEffect(() => {
    const existingAuth = getItemLocalStorage("accessToken");
    if (existingAuth) {
      setIsAuthenticated(true);
      getBusenessData().then((res) => {
        localStorage.setItem("id", res?.data?.result?.id);
      });
    } else {
      setIsAuthenticated(false);
    }
  }, [userLogin]);

  const ProtectedRoute = ({ isAuthenticated, children }) => {
    if (!isAuthenticated) {
      return <Navigate to="/login" replace />;
    }

    return children;
  };

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route
              exact
              path={PLANONE}
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <PlanOne />
                </ProtectedRoute>
              }
            />

            {/* Onboarding */}
            <Route path={LOGIN} element={<Login2 />} />
            <Route path={LOGIN2} element={<Login />} />
            <Route path={REGISTER} element={<Register />} />
            <Route path={SIGNIN} element={<SignIn />} />
            <Route exact path={SIGNINOTP} element={<SignInOTP />} />
            <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={CREATE_NEW_PASSWORD} element={<CreateNewPassword />} />
            <Route
              exact
              path={ADD_BUSINESS_DETAILS}
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <AdBusinessDetails />
                </ProtectedRoute>
              }
            />
            <Route exact path={EDIT_PROFILE_PAGE} element={<EditProfile />} />
            <Route exact path={FAVORITE} element={<Favorite />} />
            <Route exact path={FAVORITE} element={<FavoriteList />} />

            {/*  */}

            <Route exact path={BUSINESS_LIST} element={<BusinessList />} />
            <Route exact path={MY_INTEREST} element={<MyInterest />} />
            <Route exact path={DAILY_DEAL} element={<DailyDeals />} />
            <Route exact path={MY_INTEREST_PAGE} element={<MyInterestModule/>} />
            <Route exact path={EDIT_INTEREST} element={<EditInterest/>} />

            <Route
              exact
              path={BUSINESS_DETAILS}
              element={<BusinessDetails />}
            />
            <Route
              exact
              path={BUSINESS_SEARCH_RESULT}
              element={<SearchBusinessList />}
            />
            <Route
              exact
              path={BUSINESS_LIST_BY_ID}
              element={<SearchBusinessList />}
            />

            <Route exact path={CONTACT} element={<Contact />} />
            <Route exact path={EXPLORE_APP} element={<ExploreApp />} />
            <Route exact path={AD_PURCHASE} element={<AdPurchase />} />
            {/* <Route exact path={PLANONE} element={<PlanOne />} /> */}
            <Route exact path={POLICY} element={<Policy />} />
            <Route exact path={TERM} element={<Term />} />
            {/* <Route exact path={PROMOTIONINFO} element={<PromotionInfo />} /> */}
            {/* <Route exact path={EVENTS} element={<Events />} /> */}
            <Route exact path={ALLREVIEWS} element={<AllReviews />} />
            <Route
              exact
              path={SUCCESSFUL_PAYMENT}
              element={<SuccessPayment />}
            />
            <Route exact path={FAILED_PAYMENT} element={<FailedPayment />} />
            <Route exact path={ESP_POLICY} element={<EspPolicy />} />
            <Route
              exact
              path={ESP_PROMOTIONINFO}
              element={<EspPromotionInfo />}
            />
            <Route exact path={ESP_TERM} element={<EspTerm />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </BrowserRouter>
  );
};
export default RoutesComponet;
