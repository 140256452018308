import moment from "moment";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import {
  AD_PURCHASE,
  CONTACT,
  EXPLORE_APP,
  HOME,
  POLICY,
  TERM,
  PROMOTIONINFO,
  EVENTS,
} from "../Routes/RoutesConstant";
import { ANDROID_APP_LINK, IOS_APP_LINK } from "../Utils/constants";
// import MobileLeftImg from "../assets/images/mobile2.png";
// import MobileRightImg from "../assets/images/mobile.png";
import "./footer.css";

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <>
      {location.pathname === HOME || EVENTS ? (
        <section className='download-app relative'>
          <div className='container '>
            <div className='row da-back'>
              <div className='col-md-6'>
                <div className='da-text'>
                  <h4>
                    <img src='../images/suffixes3.svg' className='suffixes3' alt="" />
                    Download Now &amp; Let’s find more{" "}
                    <img
                      src='../images/green-suf2.png'
                      className='green-suf2'
                      alt=""
                    />
                    features to explore.
                  </h4>
                  <p>
                    Brigiz is dedicated to connecting customers to local
                    businesses and services. Let's find a space for your
                    business or get you connected with a service near you!
                  </p>
                  <span>
                    Download the App Today
                    <img
                      src='../images/exp-da.png'
                      className='exp-da arrow_moving_horizontal'
                      alt=""
                    />
                  </span>
                  <div className='exp-download-btns2'>
                    <button onClick={() => window.open(ANDROID_APP_LINK, "_")}>
                      <div className='g_pay_container '>
                        <div className=' btn-img1'>
                          <img src='../images/play-button.svg' alt=""/>
                        </div>
                        <div className=' btn-txt1'>
                          <span>GET IT ON</span>
                          <p>Google Play</p>
                        </div>
                      </div>
                    </button>
                    <button
                      onClick={() => window.open(IOS_APP_LINK, "_")}
                      style={{ width: "180px" }}
                    >
                      <div className='g_pay_container'>
                        <div className=' btn-img1'>
                          <img src='../images/apple.svg' alt="" />
                        </div>
                        <div className='col-md-9 btn-txt1'>
                          <span>Download on the</span>
                          <p>App Store</p>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='da-image'>
                  <div className='footer_animatio_img me-4'>
                    <ScrollAnimation animateIn='fadeInLeft'>
                      <img
                        src='../images/mobile2.png'
                        className='img-fluid  slide_right  wow fadeInLeft'
                        alt=""
                      />
                    </ScrollAnimation>

                    <div className='footer_animation_slide_wrap ms-5'>
                      <ScrollAnimation animateIn='fadeInRight'>
                        <img
                          src='../images/mobile.png'
                          className='img-fluid slide_left  wow fadeInRight'
                          alt=""
                        />
                      </ScrollAnimation>
                    </div>
                  </div>

                  <img src='../images/img62.png' className='img63' alt="" />
                  <img src='../images/img62.png' className='img64' alt="" />
                </div>
              </div>
            </div>
          </div>
          {location.pathname === EVENTS && (
            <div className='green-label-footer'>
              <div className='banner '>
                <div className='text'>
                  <span className='label-text'>
                    $5,000.00 Business Giveaway.
                  </span>
                  <span className='label-text'>
                    $5,000.00 Business Giveaway.
                  </span>
                  <span className='label-text'>
                    $5,000.00 Business Giveaway.
                  </span>
                  <span className='label-text'>
                    $5,000.00 Business Giveaway.
                  </span>
                  <span className='label-text'>
                    $5,000.00 Business Giveaway.
                  </span>
                  <span className='label-text'>
                    $5,000.00 Business Giveaway.
                  </span>
                  <span className='label-text'>
                    $5,000.00 Business Giveaway.
                  </span>
                  <span className='label-text'>
                    $5,000.00 Business Giveaway.
                  </span>
                  <span className='label-text'>
                    $5,000.00 Business Giveaway.
                  </span>
                  <span className='label-text'>
                    $5,000.00 Business Giveaway.
                  </span>
                  <span className='label-text'>
                    $5,000.00 Business Giveaway.
                  </span>
                  <span className='label-text'>
                    $5,000.00 Business Giveaway.
                  </span>
                </div>
              </div>
            </div>
          )}
        </section>
      ) : (
        ""
      )}
      <footer>
        <div className='footer1-content'>
          <img
            src='../images/img-logo.png'
            className='f-logo'
            alt='brigiz_logo'
          />
          <ul className='social-icons'>
            <li>
              <a
                href='https://www.facebook.com/brigizinc'
                target='_blank'
                rel='noreferrer'
                title='Facebook'
              >
                <img src='../images/fb.svg' alt='fb_icon' />
              </a>
            </li>
            <li>
              <a
                href='https://x.com/Brigiz_official'
                target='_blank'
                rel='noreferrer'
                title='Twitter/X'
              >
                <img
                  src='../images/twitter_X.svg'
                  style={{
                    borderRadius: "50%",
                    padding: "3px",
                    background: "#fff",
                  }}
                  alt='twiiter_icon'
                />
              </a>
            </li>
            {/* <li>
              <a href="#">
                <img src="images/linkedin.svg" />
              </a>
            </li> */}
            <li>
              <a
                href='https://www.instagram.com/brigiz_inc'
                target='_blank'
                rel='noreferrer'
                title='Instagram'
              >
                <img src='../images/insta.svg' alt='insta_icon' />
              </a>
            </li>
            <li
              style={{
                background: "#fff",
                borderRadius: "50%",
                marginRight: 0,
              }}
            >
              <a
                href='https://www.youtube.com/channel/UCkvLkJDOXwksvLMd1DYC8fg'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src='../images/youtube_logo.png'
                  width='32'
                  height='32'
                  alt='youtube_icon'
                  title='Youtube'
                />
              </a>
            </li>
          </ul>
          <ul className='nav-icons'>
            <li>
              <Link to={HOME}>Home</Link>
            </li>
            <li>
              <Link to={AD_PURCHASE}>Ad Purchase</Link>
            </li>
            <li>
              <Link to={EXPLORE_APP}>Explore Brigiz</Link>
            </li>
            <li>
              <Link to={CONTACT}>Contact Us</Link>
            </li>
          </ul>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='copyright'>
                <p style={{ fontWeight: "500" }}>
                  Copyright @{moment().format("YYYY")} Brigiz. All Rights
                  Reserved by Brigiz
                </p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='privacy-t'>
                <ul>
                  <li>
                    <Link to={POLICY} style={{ fontWeight: "500" }}>
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={TERM} style={{ fontWeight: "500" }}>
                      Terms of Use
                    </Link>
                  </li>
                  <li>
                    {/* <Link to={PROMOTIONINFO} style={{ fontWeight: "500" }}>
                      Promotional T&C
                    </Link> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
