import React, { useEffect, useState } from "react";
import { formatPhone } from "../Utils/localStroge";
import { useDispatch } from "react-redux";
import {
  addFavorite,
  removeFavorite,
} from "../Redux/Action/BusinessAction/businesAction";
const WHITE_HEART = "../images/white-heart.png";
const RED_HEART = "../images/favorite_Image.png";

const Card = ({
  width = 350,
  height = 415,
  marginLeft = 0,
  marginBottom = 20,
  data,
  handleViewDetail,
  showReview = false,
  showAddress = false,
  gridView = false,
  showPhone = false,
  onFavoriteUpdate,
}) => {
  const [isFavorite, setIsFavorite] = useState(data?.isFavourite || false);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsFavorite(data?.isFavourite || false);
  }, [data?.isFavourite]);

  const handleFavoriteClick = async (e) => {
    e.stopPropagation();

    try {
      const response = await dispatch(
        isFavorite
          ? removeFavorite({ business_id: data.id })
          : addFavorite({ business_id: data.id })
      );

      if (
        response.type === "ADD_FAVORITE_SUCCESS" ||
        response.type === "REMOVE_FAVORITE_SUCCESS" ||
        (response.type === "ADD_FAVORITE_ERROR" &&
          response.payload?.status === 409)
      ) {
        setIsFavorite(!isFavorite);
        onFavoriteUpdate(data.id);
      }
    } catch (error) {
      console.error("Error handling favorite:", error);
    }
  };

  const {
    address1,
    city,
    state,
    country,
    zipcode,
    is_address_hide,
    phone,
    country_code,
    lat,
    lng,
    busi_type_id,
  } = data;

  const starCount = [1, 2, 3, 4, 5];

  return (
    <div
      className="nib-box"
      style={{
        width: `${width}px`,
        minWidth: `${width}px`,
        height: `${height}px`,
        marginLeft,
        marginBottom,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflow: "hidden",
        boxSizing: "border-box",
        padding: "10px",

        borderRadius: "8px",
      }}
    >
      <div style={{ flex: 1 }}>
        <img
          src={
            data?.businessImage?.[0]?.image ||
            "https://bgz-business.s3.us-east-2.amazonaws.com/FreeBusinessImage.jpg"
          }
          style={{
            width: "100%",
            objectFit: "cover",
            height: "155px",
            borderRadius: "8px",
          }}
          alt=""
        />

        <button
          onClick={handleFavoriteClick}
          style={{
            position: "absolute",
            top: "30px",
            right: "20px",
            background: "transparent",
            border: "none",
            cursor: "pointer",
            padding: 0,
            zIndex: 2,
          }}
        >
          <img
            src={isFavorite ? RED_HEART : WHITE_HEART}
            alt={isFavorite ? "Favorited" : "Not favorited"}
            // alt=""
            style={{
              width: "37px",
              height: "36.19px",
            }}
          />
        </button>

        {busi_type_id === 6 && (
          <label
            style={{
              position: "absolute",
              transform: "translate(-50%, -50%)",
              // top: "44.3%",
              top: "40%",
              left: "50%",
              backgroundColor: "#00000094",
              color: "white",
              width: `calc(${width}px - 20px)`,
              textAlign: "center",
              fontSize: "1.1rem",
            }}
          >
            Gig worker
          </label>
        )}
        {data?.userDetail?.subscription_id === 1 && (
          <div
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              width: "0",
              height: "0",
              borderTop: "70px solid #000000ab",
              borderLeft: "70px solid transparent",
            }}
          >
            <img
              src="../images/greenlock.png"
              style={{ position: "absolute", top: "-65px", right: "10px" }}
              width={26}
              alt=""
            />
          </div>
        )}

        <h4 style={{ textAlign: "left", margin: "10px 0" }}>
          {data?.business_name}
        </h4>
        {showReview && (
          <ul style={{ padding: 0, margin: "10px 0" }}>
            {Array.from(
              { length: parseInt(data?.reviewAverage || 0) },
              (e, i) => {
                return (
                  <li
                    key={i}
                    style={{ display: "inline-block", marginRight: "5px" }}
                  >
                    <i className={`fa-solid fa-star`} />
                  </li>
                );
              }
            )}
          </ul>
        )}
        {showAddress && is_address_hide === 1 && (
          <div
            className="d-flex align-items-baseline"
            style={{
              margin: "10px 0",
              height: "60px",
              lineHeight: "20px",
              overflow: "hidden",
            }}
          >
            <i
              className="fa-solid fa-location-dot"
              style={{ color: "#349c52", marginRight: "5px", fontSize: "15px" }}
            />
            <p
              style={{
                color: "#ffffffc9",
                fontSize: "14px",
                fontWeight: 500,
                textAlign: "initial",
                margin: 0,
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
                  "_"
                );
              }}
            >
              {address1}, {city}, {state}, {country}, {zipcode}
            </p>
          </div>
        )}

        {showPhone && phone && (
          <div
            className="d-flex align-items-baseline"
            style={{ margin: "10px 0" }}
          >
            <a
              href={`tel:${country_code}${phone}`}
              style={{ textDecoration: "none", color: "#dadada" }}
            >
              <img
                src="../images/call-calling.svg"
                width="18px"
                height="18px"
                alt=""
              />{" "}
              {formatPhone(`${country_code}${phone}`)}
            </a>
          </div>
        )}
      </div>
      {data?.userDetail?.subscription_id === 1 ? (
        <button
          className="view-detail-btn"
          style={{ width: "100%", padding: "10px", marginTop: "10px" }}
          onClick={() => {
            window.open(`tel:${country_code}${phone}`, "_self");
          }}
        >
          Call Now
        </button>
      ) : (
        <button
          className="view-detail-btn"
          style={{ width: "100%", padding: "10px", marginTop: "10px" }}
          onClick={() => {
            handleViewDetail && handleViewDetail(data?.id, data.business_name);
          }}
        >
          View Details
        </button>
      )}
    </div>
  );
};

export default Card;
