import React from 'react';
import ReactDOM from 'react-dom';
import '../src/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import store from './Redux/Store/store'
import { PersistGate } from 'redux-persist/integration/react'
import {persistor} from './Redux/Store/store'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleOAuthProvider } from "@react-oauth/google"

toast.configure({
  autoClose: 3000,
  draggable: false,
  position: 'top-right',
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: true,
  rtl: false,
  pauseOnVisibilityChange: true,
  pauseOnHover: true
});

ReactDOM.render(
  <GoogleOAuthProvider clientId='974682362227-3kr601fac16ahc52d5spl9pfpvrd13to.apps.googleusercontent.com'>
  <React.StrictMode>
    <Provider store={store}>
       <PersistGate loading={null} persistor={persistor}>
        <App />
       </PersistGate>
    </Provider>
   
  </React.StrictMode>,
  </GoogleOAuthProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
