import React, { useState, useEffect } from "react";
import { Link, useNavigate, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import swal from "sweetalert";
import { login } from "../../Redux/Action/LoginAction/loginAction";
import { useDispatch } from "react-redux";
import { setItemLocalStorage } from "../../Utils/localStroge";
import { ANDROID_APP_LINK, IOS_APP_LINK } from "../../Utils/constants";
import EyesOnIcon from "../../assets/images/show.png";
import EyesOffIcon from "../../assets/images/hide.png";
import EmailIcon from "../../assets/images/email.png";
import FbIcon from "../../assets/images/facebook.png";
import GoogleIcon from "../../assets/images/google.png";

import "./login.css";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import {
  fbRegister,
  googleRegister,
  loginByPhoneApi,
} from "../../API/ApiService";
import { loadingAction } from "../../Redux/Action/loadingAction";
// import FacebookLogin from "react-facebook-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";

import {
  LOGIN_SUCCESS,
  LOGOUT_DETAILS_SUCCESS,
} from "../../Redux/Action/actionTypes";
import axios from "axios";

function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  //   const [signupStatus, setSignUpStatus] = useState(1);
  const [checkedVal, setCheckedVal] = useState("Customer");

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handlePhone = (phone, country, inputvalue) => {
    setPhone(phone);
    setCountryCode("+" + country?.dialCode);
    let mystring = phone.replace("+", "");
    mystring = mystring.replace(country?.dialCode, "");
    setPhoneNumber(mystring);
  };

  // ======= submit Login request ==============
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let body = JSON.stringify({
      email: values.email,
      password: values.password,
      platform: "android",
      os_version: "v1.0",
      app_version: "v.4.5",
      user_timezone: "GMT+5:30",
    });
    // console.log("body--", body);
    try {
      const response = await dispatch(login(body));
      const loginDataSuccess = response?.payload;

      if (loginDataSuccess) {
        if (loginDataSuccess?.data.status == 200) {
          if (loginDataSuccess?.data?.user?.is_deleted == 0) {
            const result = loginDataSuccess?.data?.user
            if (
              loginDataSuccess?.data?.user?.role_id === 2 &&
              checkedVal === "Business"
            ) {
              setItemLocalStorage(
                "accessToken",
                loginDataSuccess.data.access_token
              );
              setItemLocalStorage(
                "refreshToken",
                loginDataSuccess.data.refresh_token
              );
              setItemLocalStorage(
                "user_uuid",
                result?.user_uuid
              );
              setItemLocalStorage("id", result?.userProfile?.id);
              setItemLocalStorage(
                "user",
                JSON.stringify(result)
              );
              if (result?.user_state === "0") {
                navigate("/register");
              } else if (result?.user_state === "1" || result?.user_state === "2") {
                navigate("/adbusinessdetails");
              } else {
                navigate("/");
              }

            } else if (
              result?.role_id === 2 &&
              checkedVal === "Customer"
            ) {
              swal({
                title: "error!",
                text: "Not a Customer account. Check and try again",
                buttons: false,
                icon: "error",
                timer: 3000,
              });
            } else if (
              result?.role_id === 3 &&
              checkedVal === "Business"
            ) {
              swal({
                title: "error!",
                text: "Not a Business account. Check and try again",
                buttons: false,
                icon: "error",
                timer: 3000,
              });
            } else if (
              result?.role_id === 3 &&
              checkedVal === "Customer"
            ) {
              setItemLocalStorage(
                "accessToken",
                loginDataSuccess.data.access_token
              );
              setItemLocalStorage(
                "refreshToken",
                loginDataSuccess.data.refresh_token
              );
              setItemLocalStorage(
                "user_uuid",
                loginDataSuccess.data.result?.user_uuid
              );
              setItemLocalStorage("id", result?.userProfile?.id);
              if (result.user_state === "0") {
                navigate("/register");
              } else {
                navigate("/");
              }
            }
          } else {
            swal({
              title: "Inactive User!",
              text: "User is either Inactive or Deleted",
              buttons: false,
              icon: "error",
              timer: 3000,
            });
          }
        } else {
          swal({
            title: "error!",
            text: "Please complete your business details from the mobile app and try again",
            buttons: false,
            icon: "error",
            timer: 3000,
          });
        }
      } else {
        swal({
          title: "error!",
          text: loginDataSuccess?.data?.message,
          buttons: false,
          icon: "error",
          timer: 3000,
        });
      }
    } catch (err) {
      console.log(err);
      swal({
        title: "Invalid Credentials",
        text: err.response.data.message,
        buttons: false,
        icon: "error",
        timer: 3000,
      });
    }
  };

  // =============== Send OTP to Phone for customer=====================
  const handleSendPasscode = async () => {
    dispatch(loadingAction(true));

    let body = JSON.stringify({
      role_id: 3,
      country_code: countryCode,
      phone: phoneNumber,
      platform: "android",
      os_version: "v1.0",
      app_version: "v.4.5",
      user_timezone: "GMT+5:30",
    });
    try {
      const response = await loginByPhoneApi(body);
      const loginDataSuccess = response;
      if (loginDataSuccess) {
        dispatch(loadingAction(false));

        if (loginDataSuccess?.data?.user?.is_deleted == 0) {
          if (loginDataSuccess?.data?.user?.role_id == "3") {
            setItemLocalStorage(
              "accessToken",
              loginDataSuccess.data.access_token
            );
            setItemLocalStorage(
              "refreshToken",
              loginDataSuccess.data.refresh_token
            );
            setItemLocalStorage(
              "user",
              JSON.stringify(loginDataSuccess.data.user)
            );
            swal({
              title: "OTP Sent successfully!",
              text: " verify OTP send to your Phone",
              buttons: false,
              icon: "success",
              timer: 3000,
            }).then(() =>
              navigate("/signinotp", {
                state: { forget_password: 0, customer_login: true },
              })
            );
          } else if (loginDataSuccess?.data?.user?.role_id == "2") {
            dispatch(loadingAction(false));

            swal({
              title: "Error!",
              text: "Not a Customer account. Check and try again",
              buttons: false,
              icon: "error",
              timer: 3000,
            });
          }
        }
      } else {
        dispatch(loadingAction(false));

        swal({
          title: "Error!",
          text: loginDataSuccess?.data?.message,
          buttons: false,
          icon: "error",
          timer: 3000,
        });
      }
    } catch (err) {
      dispatch(loadingAction(false));

      console.log(err);
      swal({
        title: "Error!",
        text: "Please enter your phone number",
        buttons: false,
        icon: "error",
        timer: 3000,
      });
    }
  };

  const togglePasswordVisibility = (val) => {
    setShowPassword(!showPassword);
  };

  const checkHandler = (event) => {
    setCheckedVal(event.target.value);
  };

  const responseFacebook = async (response) => {
    if (response?.status !== "unknown" || response != "undefined") {
      dispatch(loadingAction(true));
      let body = {
        role_id: 3,
        name: response?.name,
        facebook_id: response?.id,
        platform: "android",
        os_version: "v1.0",
        app_version: "v.4.5",
        user_timezone: "GMT+5:30",
      };
      try {
        const responseData = await fbRegister(body);
        const loginDataSuccess = responseData;
        if (loginDataSuccess) {
          dispatch(loadingAction(false));

          if (loginDataSuccess?.data?.result?.is_deleted == 0) {
            setItemLocalStorage(
              "accessToken",
              loginDataSuccess.data.access_token
            );
            setItemLocalStorage(
              "refreshToken",
              loginDataSuccess.data.refresh_token
            );
            setItemLocalStorage(
              "user",
              JSON.stringify(loginDataSuccess.data.result)
            );
            swal({
              title: "Facebook login successfull!",
              text: " Facebook login successfull",
              buttons: false,
              icon: "success",
              timer: 3000,
            }).then(() => {
              dispatch(loadingAction(false));
              dispatch({ type: LOGOUT_DETAILS_SUCCESS, payload: {} });
              dispatch({ type: LOGIN_SUCCESS, payload: loginDataSuccess });
              navigate("/");
            });
          }
        } else {
          dispatch(loadingAction(false));

          swal({
            title: "Error!",
            text: loginDataSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 3000,
          });
        }
      } catch (err) {
        dispatch(loadingAction(false));

        console.log(err);
        swal({
          title: "Error!",
          text: err.response.data.message,
          buttons: false,
          icon: "error",
          timer: 3000,
        });
      }
    } else {
      swal({
        title: "Error!",
        text: "Login Failed",
        buttons: false,
        icon: "error",
        timer: 3000,
      });
    }
  };

  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);

  const responseGoogle = useGoogleLogin({
    onSuccess: (CodeResponse) => {
      setUser(CodeResponse);
    },
    // flow: "auth-code",
  });

  useEffect(() => {
    if (user?.access_token) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v2/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user?.access_token]);

  const GoogleRegister = async () => {
    dispatch(loadingAction(true));
    let body = {
      role_id: 3,
      name: profile.name,
      email: profile.email,
      platform: "android",
      os_version: "11",
      app_version: "1.2.0",
      user_timezone: "GMT+5:30",
      google_token: user?.access_token,
    };
    try {
      const responseData = await googleRegister(body);
      const loginDataSuccess = responseData;
      if (loginDataSuccess) {
        dispatch(loadingAction(false));

        if (loginDataSuccess?.data?.result?.is_deleted == 0) {
          setItemLocalStorage(
            "accessToken",
            loginDataSuccess.data.access_token
          );
          setItemLocalStorage(
            "refreshToken",
            loginDataSuccess.data.refresh_token
          );
          setItemLocalStorage(
            "user_uuid",
            loginDataSuccess.data.result.user_uuid
          );
          setItemLocalStorage("id", loginDataSuccess.data.result?.userProfile?.id);
          setItemLocalStorage(
            "user",
            JSON.stringify(loginDataSuccess.data.result)
          );
          swal({
            title: "Google login successfull!",
            text: " Google login successfull",
            buttons: false,
            icon: "success",
            timer: 3000,
          }).then(() => {
            const { result } = loginDataSuccess.data
            dispatch(loadingAction(false));
            dispatch({ type: LOGOUT_DETAILS_SUCCESS, payload: {} });
            dispatch({ type: LOGIN_SUCCESS, payload: loginDataSuccess });
            if (result.user_state === "0") {
              navigate("/register");
            } else if ((result.user_state === "1" || result.user_state === "2") && result.role_id === 2) {
              navigate("/adbusinessdetails");
            } else {
              navigate("/");
            }
          });
        } else {
          swal({
            title: "Inactive User!",
            text: "User is either Inactive or Deleted",
            buttons: false,
            icon: "error",
            timer: 3000,
          });
        }
      } else {
        dispatch(loadingAction(false));

        swal({
          title: "Error!",
          text: loginDataSuccess?.data?.message,
          buttons: false,
          icon: "error",
          timer: 3000,
        });
      }
    } catch (err) {
      dispatch(loadingAction(false));

      console.log(err);
      swal({
        title: "Error!",
        text: err.response.data.message,
        buttons: false,
        icon: "error",
        timer: 3000,
      });
    }
  };

  useEffect(() => {
    if (profile && user?.access_token) {
      GoogleRegister();
    }
  }, [profile]);

  return (
    <>
      <div className="container login2 ">
        <div className="row">
          <div className="col-md-6 trust_sec">
            <h1>
              Your Trusted
              <br />
              Local Connection
              <br />{" "}
              <label
                style={{
                  color: "#349c52",
                  position: "relative",
                }}
              >
                Starts Here
                <img
                  src="images/green-suf.png"
                  className="key_feature_designg2"
                  alt=""
                  style={{ left: "3px" }}
                />
              </label>
            </h1>
            <div id="download_app">
              <div className="container login_download_app mt-1">
                <h6>
                  <img
                    src="images/suffixes3.svg"
                    className="suffixes3"
                    alt=""
                  />
                  Please Download and Install Brigiz App
                  <br />
                  to Explore More features{" "}
                  <img
                    src="images/green-suf2.png"
                    className="green-suf2"
                    style={{
                      width: "141px",
                      height: "32px",
                      marginLeft: "-136px",
                    }}
                    alt=""
                  />
                </h6>
              </div>
              <div className=" exp-download-btns mt-4">
                <button
                  onClick={() => window.open(ANDROID_APP_LINK, "_")}
                  style={{ background: "#292828" }}
                >
                  <div className="g_pay_container ">
                    <div className=" btn-img1">
                      <img src="images/play-button.svg" alt="" />
                    </div>
                    <div className=" btn-txt1">
                      <span>GET IT ON</span>
                      <p className="text-white m-0">Google Play</p>
                    </div>
                  </div>
                </button>
                <button
                  onClick={() => window.open(IOS_APP_LINK, "_")}
                  style={{ width: "180px", background: "#292828" }}
                >
                  <div className="g_pay_container">
                    <div className=" btn-img1">
                      <img src="images/apple.svg" />
                    </div>
                    <div className="col-md-9 btn-txt1">
                      <span>Download on the</span>
                      <p className="text-white m-0">App Store</p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6 form_screen" id="signup_screen_2">
            <div className="login_card bg-white p-5 mt-1 mb-5">
              <div className="login_back_btn">
                {" "}
                <h4 className="login_form_label_2">Welcome Back!</h4>
                <button
                  href="#"
                  style={{ verticalAlign: "text-bottom" }}
                  onClick={() => navigate("/")}
                >
                  <img src="images/back.png" alt="" /> Back
                </button>
              </div>
              <form onSubmit={handleSubmit} className="login_form2">
                <div className="d-flex mt-4 row">
                  <div className="form-check col-md-6">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="radio1"
                      name="optradio"
                      value="Customer"
                      onChange={checkHandler}
                      checked={checkedVal === "Customer"}
                    />
                    <label className="form-check-label2" htmlFor="radio1">
                      For Customer
                    </label>
                  </div>
                  <div className="form-check col-md-6">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="radio2"
                      name="optradio"
                      value="Business"
                      onChange={checkHandler}
                      checked={checkedVal === "Business"}
                    />

                    <label className="form-check-label2" htmlFor="radio2">
                      For Business
                    </label>
                  </div>
                </div>
                {checkedVal === "Customer" ? (
                  <>
                    <label className="login_form_email2 mt-4 mb-2">
                      Mobile Number
                    </label>
                    <PhoneInput
                      defaultCountry="us"
                      value={phone}
                      onChange={(phone, { country, inputValue }) => {
                        handlePhone(phone, country, inputValue);
                      }}
                      name="phone"
                    />
                    <div className="my-3 text-center">
                      <span
                        className="send_passcode"
                        role="button"
                        onClick={handleSendPasscode}
                      >
                        Send Passcode
                      </span>
                    </div>
                    <div className="or_div my-5">
                      <h6>
                        <span>OR</span>
                      </h6>
                    </div>
                    <div className="login_signup_div">
                      <button onClick={() => navigate("/login")}>
                        <img src={EmailIcon} alt="" className="me-3" />
                        Sign In/Sign Up
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <label className="login_form_email2 mt-4 mb-2">
                      Email Address
                    </label>
                    <input
                      className="login_form_input2"
                      type="text"
                      name="email"
                      onChange={handleInputChange}
                    />

                    <label className="login_form_email2 my-2">Password</label>
                    <div className="password-input-container">
                      <input
                        className="login_form_input2"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        onChange={handleInputChange}
                      />
                      <button
                        type="button"
                        className="password-toggle-btn bg-transparent"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <img src={EyesOnIcon} className="eyes-icon" alt="" />
                        ) : (
                          <img src={EyesOffIcon} className="eyes-icon" alt="" />
                        )}
                      </button>
                    </div>
                    <div className="forgot_password text-end">
                      <Link to="/forgotpassword">Forgot Password</Link>
                    </div>
                    <button
                      className="login_form_btn mt-4"
                      disabled={!checkedVal}
                    >
                      Sign In
                    </button>
                    <div className="signup_text text-center mt-3">
                      Don’t have an account? <Link to="/register">Sign Up</Link>
                    </div>
                  </>
                )}
                <div className='or_div my-5'>
                  <h6>
                    <span>OR</span>
                  </h6>
                </div>
                {/* <div className='social_login_div my-4'>
                  <FacebookLogin
                    appId='731226891753558'
                    autoLoad={false}
                    fields="name,email,picture"
                    scope="public_profile,email"
                    callback={responseFacebook}
                    render={(renderProps) => (
                      <button type='button' onClick={renderProps.onClick}>
                        <img src={FbIcon} alt='' className='me-3' />
                        Continue with Facebook
                      </button>
                    )}
                    icon={FbIcon}
                  />
                </div> */}
                <div className='social_login_div'>
                  <button onClick={responseGoogle} type='button'>
                    <img src={GoogleIcon} alt='' className='me-3' />
                    Continue with Google
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div id="mobile_view">
          <div className="container login_download_app mt-1">
            <h6>
              <img src="images/suffixes3.svg" className="suffixes3" alt="" />
              Please Download and Install Brigiz App
              <br />
              to Explore More features{" "}
              <img
                src="images/green-suf2.png"
                className="green-suf2"
                style={{
                  width: "168px",
                  height: "32px",
                  marginLeft: "-158px",
                }}
                alt=""
              />
            </h6>
          </div>
          <div className=" exp-download-btns mt-4">
            <button
              onClick={() => window.open(ANDROID_APP_LINK, "_")}
              style={{ background: "#292828" }}
            >
              <div className="g_pay_container ">
                <div className=" btn-img1">
                  <img src="images/play-button.svg" alt="" />
                </div>
                <div className=" btn-txt1">
                  <span>GET IT ON</span>
                  <p className="text-white m-0">Google Play</p>
                </div>
              </div>
            </button>
            <button
              onClick={() => window.open(IOS_APP_LINK, "_")}
              style={{ width: "180px", background: "#292828" }}
            >
              <div className="g_pay_container">
                <div className=" btn-img1">
                  <img src="images/apple.svg" />
                </div>
                <div className="col-md-9 btn-txt1">
                  <span>Download on the</span>
                  <p className="text-white m-0">App Store</p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;
