import React from "react";
import Modal from "../../../../Component/modal/Modal";

const SalesPopup = ({ isOpenSaleModal, handleClose, selectedSale }) => {
  return (
    <Modal isOpen={isOpenSaleModal} onClose={handleClose}>
      <div className="sale-popContent-container">
        <div className="modal-event-box-img">
          <img
            src={
              selectedSale?.saleImage && selectedSale?.saleImage.length > 0
                ? selectedSale?.saleImage[0].image
                : "https://bgz-business.s3.us-east-2.amazonaws.com/FreeBusinessImage.jpg"
            }
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
              borderRadius: "15px",
            }}
          />
        </div>
        <h5 className="saleTitle">{selectedSale?.title}</h5>
        {selectedSale?.description && (
          <h6 style={{ color: "#FCFCFC", fontSize: "16px", fontWeight: "600" }}>
            Sale Details
          </h6>
        )}
        <ol style={{ listStyle: "outside", paddingLeft: "20px" }}>
          {selectedSale?.description &&
            selectedSale?.description.map((val) => (
              <li
                style={{
                  color: "#9f9f9f",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
                key={val.id}
              >
                {val.value}
              </li>
            ))}
        </ol>
        {selectedSale?.term_conditions && (
          <h6 style={{ color: "#FFFFFF", fontSize: "18px", fontWeight: "600" }}>
            Terms & Conditions{" "}
          </h6>
        )}
        <ol style={{ listStyle: "outside", paddingLeft: "20px" }}>
          {selectedSale?.term_conditions.length > 0 &&
            selectedSale?.term_conditions.map((val, index) => (
              <li
                style={{
                  color: "#9f9f9f",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
                key={index}
              >
                {val.value}
              </li>
            ))}
        </ol>
      </div>
    </Modal>
  );
};

export default SalesPopup;
