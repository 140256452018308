import React from "react";

const CardInterest2 = ({
  width,
  height,
  marginLeft,
  marginBottom = 40,
  data,
  borderRadius,
  padding = 0,
}) => {
  //   var result = text.slice(0, count) + (text.length > count ? "..." : "");
  return (
    <div
      className='nib-box interest-box '
      style={{
        width,
        height,
        marginLeft,
        marginBottom,
        borderRadius,
        padding,
        position:'relative'
      }}
    >
      <div style={{ position: "relative", width: "100%" }} className="overlay z-0">
        <img
          src={
            data?.category_image ||
            "https://bgz-business.s3.us-east-2.amazonaws.com/FreeBusinessImage.jpg"
          }
          style={{
            width: 295,
            objectFit: "fill",
            height: 267,
            borderRadius: "20px",
          }}
          alt=''
        />
      </div>
      <div className='text-left mt-2 interest_title_card2'>
          <h4 className='text-white'>{data?.category_name || ""}</h4>
        </div>
    </div>
  );
};

export default CardInterest2;
