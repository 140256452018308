import React, { useState, useEffect } from "react";
import {
  Link,
  useNavigate,
  Redirect,
  useLocation,
  useParams,
} from "react-router-dom";
import Cookies from "js-cookie";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import {
  setItemLocalStorage,
  getItemLocalStorage,
} from "../../Utils/localStroge";
import { ANDROID_APP_LINK, IOS_APP_LINK } from "../../Utils/constants";

import "./login.css";
import "react-international-phone/style.css";
import OtpInput from "react-otp-input";
import { resendOtpApi, verifyOtpApi } from "../../API/ApiService";
import { loadingAction } from "../../Redux/Action/loadingAction";
import {
  LOGIN_SUCCESS,
  LOGOUT_DETAILS_SUCCESS,
} from "../../Redux/Action/actionTypes";
import FacebookLogin from "react-facebook-login";

function SignInOTP() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [responseData, setResponseData] = useState(location.state);
  let { forget_password, customer_login } = responseData;
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(30);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [registerFrom, setRegisterFrom] = useState(
    JSON.parse(getItemLocalStorage("user"))
  );
  const [values, setValues] = useState({
    email: Cookies.get("email"),
    password: Cookies.get("password"),
  });
  //   const [signupStatus, setSignUpStatus] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
  const sendOTP = () => {
    setMinutes(1);
    setSeconds(0);
  };
  const resendOTP = async () => {
    if (minutes == 0 && seconds == 0) {
      dispatch(loadingAction(true));
      try {
        const response = await resendOtpApi();
        const loginDataSuccess = response;
        if (loginDataSuccess) {
          dispatch(loadingAction(false));
          swal({
            title: "OTP sent!",
            text: "OTP sent to your email",
            buttons: false,
            icon: "success",
            timer: 3000,
          });
        }
      } catch (err) {
        console.log(err, "err");
        dispatch(loadingAction(false));
        swal({
          title: "OTP not sent!",
          text: "Due to some issue OTP not sent.",
          buttons: false,
          icon: "error",
          timer: 3000,
        });
      }
      setMinutes(1);
      setSeconds(0);
    }
  };

  window.onbeforeunload = function (e) {
    window.onunload = function () {
      window.localStorage.isMySessionActive = "false";
    };
    return undefined;
  };

  // ======= submit Login request ==============
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(loadingAction(true));

    let body = JSON.stringify({
      otp: Number(otp),
      forget_password: forget_password,
    });
    try {
      const response = await verifyOtpApi(body);
      const loginDataSuccess = response;

      if (loginDataSuccess) {
        dispatch(loadingAction(false));

        if (loginDataSuccess?.data?.user?.is_deleted == 0) {
          if (loginDataSuccess?.data?.user?.user_state == "1") {
            setItemLocalStorage(
              "accessToken",
              loginDataSuccess.data.access_token
            );
            setItemLocalStorage(
              "refreshToken",
              loginDataSuccess.data.refresh_token
            );
            setItemLocalStorage("id", loginDataSuccess.data.user.id);
          }
        }
        if (forget_password === 0 && !customer_login) {
          swal({
            title: "Registration successfull!",
            text: "Registration Successfull",
            buttons: false,
            icon: "success",
            timer: 3000,
          }).then(() => {
            dispatch(loadingAction(false));
            dispatch({ type: LOGOUT_DETAILS_SUCCESS, payload: {} });
            dispatch({ type: LOGIN_SUCCESS, payload: loginDataSuccess });

            if (loginDataSuccess.data.user.user_state === "0") {
              navigate("/register");
            } else if (loginDataSuccess.data.user.role_id == 2 && (loginDataSuccess.data.user.user_state === "1" || loginDataSuccess.data.user.user_state === "2")) {
              navigate("/adbusinessdetails");
            } else {
              navigate("/");
            }
          });
        } else if (forget_password === 0 && customer_login) {
          swal({
            title: "Login successfull!",
            text: "Login Successfull",
            buttons: false,
            icon: "success",
            timer: 3000,
          }).then(() => {
            if (loginDataSuccess.data.user.role_id == 3) {
              dispatch(loadingAction(true));
              dispatch(loadingAction(false));
              dispatch({ type: LOGOUT_DETAILS_SUCCESS, payload: {} });
              dispatch({ type: LOGIN_SUCCESS, payload: loginDataSuccess });
              navigate("/");
            }
          });
        } else if (forget_password === 1) {
          // navigate("/createnewpassword");
          swal({
            title: "OTP Verified!",
            text: "OTP verified Successfull",
            buttons: false,
            icon: "success",
            timer: 3000,
          }).then(() => {
            navigate("/createnewpassword");
          });
        }
        // } else {
        //   dispatch(loadingAction(false));

        //   swal({
        //     title: "Registration Failed",
        //     text: loginDataSuccess?.data?.message,
        //     buttons: false,
        //     icon: "error",
        //     timer: 3000,
        //   });
        // }
      }
    } catch (err) {
      dispatch(loadingAction(false));
      if (customer_login) {
        swal({
          title: "Wrong OTP!",
          text: err?.response.data.message,
          buttons: false,
          icon: "error",
          timer: 3000,
        });
      } else {
        swal({
          title: "Wrong OTP!",
          text: err?.response.data.message,
          buttons: false,
          icon: "error",
          timer: 3000,
        });
      }
    }
  };

  return (
    <>
      <div className="container login2 ">
        <div className="row">
          <div className="col-md-6 trust_sec">
            <h1>
              Your Trusted
              <br />
              Local Connection
              <br />{" "}
              <label
                style={{
                  color: "#349c52",
                  position: "relative",
                }}
              >
                Starts Here
                <img
                  src="images/green-suf.png"
                  className="key_feature_designg2"
                  alt=""
                  style={{ left: "3px" }}
                />
              </label>
            </h1>
            <div id="download_app">
              <div className="container login_download_app mt-1">
                <h6>
                  <img
                    src="images/suffixes3.svg"
                    className="suffixes3"
                    alt=""
                  />
                  Please Download and Install Brigiz App
                  <br />
                  to Explore More features{" "}
                  <img
                    src="images/green-suf2.png"
                    className="green-suf2"
                    style={{
                      width: "141px",
                      height: "32px",
                      marginLeft: "-136px",
                    }}
                    alt=""
                  />
                </h6>
              </div>
              <div className=" exp-download-btns mt-4">
                <button
                  onClick={() => window.open(ANDROID_APP_LINK, "_")}
                  style={{ background: "#292828" }}
                >
                  <div className="g_pay_container ">
                    <div className=" btn-img1">
                      <img src="images/play-button.svg" alt="" />
                    </div>
                    <div className=" btn-txt1">
                      <span>GET IT ON</span>
                      <p className="text-white m-0">Google Play</p>
                    </div>
                  </div>
                </button>
                <button
                  onClick={() => window.open(IOS_APP_LINK, "_")}
                  style={{ width: "180px", background: "#292828" }}
                >
                  <div className="g_pay_container">
                    <div className=" btn-img1">
                      <img src="images/apple.svg" />
                    </div>
                    <div className="col-md-9 btn-txt1">
                      <span>Download on the</span>
                      <p className="text-white m-0">App Store</p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6 form_screen" id="signin_otp">
            <div className="login_card bg-white p-5 mt-1 mb-5">
              <div className="login_back_btn">
                {" "}
                <button
                  href="#"
                  style={{ verticalAlign: "text-bottom" }}
                  onClick={() => navigate(-1)}
                >
                  <img src="images/back.png" alt="" /> Back
                </button>
              </div>
              <form onSubmit={handleSubmit} className="login_form2">
                <h4 className="login_form_label_2">Enter Verification Code!</h4>
                <div className="otp_input_div mt-4">
                  <span>
                    Enter the OTP we sent to your{" "}
                    {!customer_login ? "Email" : "Phone"}
                  </span>
                  <div className="mt-2 otp_input">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      renderSeparator={<span className="me-4"></span>}
                      renderInput={(props) => <input {...props} style={{width:"50px"}}/>}
                      style={{ justifyContent: "between" }}
                      inputStyle={{ width: "50px" }}
                    />
                  </div>
                </div>
                <div className="verify my-4">
                  <button disabled={otp?.length < 4}>Verify</button>
                </div>
                <div className="text-center">
                  <span
                    className="resend_otp"
                    style={{ color: seconds || minutes ? "grey" : "" }}
                    role="button"
                    disabled={minutes > 0 && seconds > 0}
                    onClick={resendOTP}
                  >
                    Resend OTP
                  </span>
                  <span className="timer">
                    {" "}
                    in 0{minutes}:{seconds || "00"}
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div id="mobile_view">
          <div className="container login_download_app mt-1">
            <h6>
              <img src="images/suffixes3.svg" className="suffixes3" alt="" />
              Please Download and Install Brigiz App
              <br />
              to Explore More features{" "}
              <img
                src="images/green-suf2.png"
                className="green-suf2"
                style={{
                  width: "168px",
                  height: "32px",
                  marginLeft: "-158px",
                }}
                alt=""
              />
            </h6>
          </div>
          <div className=" exp-download-btns mt-4">
            <button
              onClick={() => window.open(ANDROID_APP_LINK, "_")}
              style={{ background: "#292828" }}
            >
              <div className="g_pay_container ">
                <div className=" btn-img1">
                  <img src="images/play-button.svg" alt="" />
                </div>
                <div className=" btn-txt1">
                  <span>GET IT ON</span>
                  <p className="text-white m-0">Google Play</p>
                </div>
              </div>
            </button>
            <button
              onClick={() => window.open(IOS_APP_LINK, "_")}
              style={{ width: "180px", background: "#292828" }}
            >
              <div className="g_pay_container">
                <div className=" btn-img1">
                  <img src="images/apple.svg" />
                </div>
                <div className="col-md-9 btn-txt1">
                  <span>Download on the</span>
                  <p className="text-white m-0">App Store</p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignInOTP;
