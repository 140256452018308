import { getStates } from "../../API/ApiService";
import {
  LOCATION_LIST_ERROR,
  LOCATION_LIST_SUCCESS,
  SELECT_LOCATION,
  SET_LAT_LNG,
} from "./actionTypes";
import { loadingAction } from "./loadingAction";

export const getStateList = () => {
  return (dispatch) => {
    // dispatch(loadingAction(true));
    return getStates()
      .then((response) => {
        if (response && response.status === 200) {
          // dispatch(loadingAction(false));
          return dispatch({
            type: LOCATION_LIST_SUCCESS,
            payload: response?.data?.result || [],
          });
        }
      })
      .catch(({ ...error }) => {
        // dispatch(loadingAction(false));
        return dispatch({ type: LOCATION_LIST_ERROR });

        //   throw error;
      });
  };
};

export const setLocation = (data) => ({
  type: SELECT_LOCATION,
  payload: data,
});

export const setLatLng = (data) => ({
  type: SET_LAT_LNG,
  payload: data,
});
export const setShow = (data) => ({
  type:'SET_SHOW',
  payload: data
})
