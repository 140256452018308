import {
  GET_BUSINESS_FEATURE_SUCCESS,
  GET_BUSINESS_FEATURE_ERROR,
  GET_POPULAR_CAT_SUCCESS,
  GET_POPULAR_CAT_ERROR,
  GET_BUSINESS_DETAILS_SUCCESS,
  GET_BUSINESS_DETAILS_ERROR,
  GET_LOCAL_HIGHTLIGHT_SUCCESS,
  GET_LOCAL_HIGHTLIGHT_ERROR,
  GET_BUSINESS_EVENT_SUCCESS,
  GET_BUSINESS_EVENT_ERROR,
  BUSINESS_LIST_SUCCESS,
  BUSINESS_LIST_ERROR,
  BUSINESS_TYPE_SUCCESS,
  BUSINESS_TYPE_ERROR,
  SEARCH_BUSINESS_SUCCESS,
  SEARCH_BUSINESS_ERROR,
  ADD_FAVORITE_SUCCESS ,
ADD_FAVORITE_ERROR ,
 REMOVE_FAVORITE_SUCCESS,
 REMOVE_FAVORITE_ERROR,
  GET_SELECTED_INTEREST_ERROR,
  GET_SELECTED_INTEREST_SUCCESS,
  UPDATE_CHECKBOX_SUCCESS,
  UPDATE_CHECKBOX_ERROR
  
} from "../actionTypes";
import {
  featuredBusinessApi,
  getPopularCatApi,
  getBusenessDatailApi,
  localHighlightApi,
  businessEventApi,
  getAllBusinessApi,
  getBusinessTypeApi,
  searchBusinessApi,
  webSearchBusinessApi,
  Selectfavorite,
  Removefavorite,
  // getFavorites,
  getAddFavoriteList,
  getAllSelectedInterest,
  getAllEditInterest,
} from "../../../API/ApiService";
import swal from "sweetalert";
import { loadingAction } from "../loadingAction";
import { skeletonAction } from "../loadingAction";
import {
  LOCAL_HIGHLIGHT,
  FEATURE_BUSINESS,
  LOCATION_ERROR_MSG,
  BUSINESS_LIST,
  DAILY_DEAL,
  BUSINESS_TYPE_LIST,
  BUSINESS_SEARCH,
  NEW_INTEREST,

} from "../../../Utils/constants";
import ReactDOM from "react-dom";
import { setLocation, setShow } from "../LocationAction";
import { updateCheckboxEditInterest } from '../../../API/ApiService';
// import { UPDATE_EDIT_INTERESTS, UPDATE_INTEREST_ERROR, UPDATE_INTEREST_SUCCESS } from "../../../../test";

export const featureBusinessList = (data) => {
  return (dispatch) => {
    dispatch(skeletonAction(true, FEATURE_BUSINESS));
    return featuredBusinessApi(data)
      .then((response) => {
        if (response && response.status === 200) {
          dispatch({ type: GET_BUSINESS_FEATURE_SUCCESS, payload: response });
          dispatch(skeletonAction(false, FEATURE_BUSINESS));
        }
      })
      .catch(({ ...error }) => {
        dispatch({ type: GET_BUSINESS_FEATURE_ERROR, payload: error.response });
        dispatch(skeletonAction(false, FEATURE_BUSINESS));
        throw error;
      });
  };
};

export const getPopularCatList = (data) => {
  return (dispatch) => {
    // dispatch(loadingAction(true));
    return getPopularCatApi(data)
      .then((response) => {
        if (response && response.status === 200) {
          dispatch({ type: GET_POPULAR_CAT_SUCCESS, payload: response });
          dispatch(loadingAction(false));
        }
      })
      .catch(({ ...error }) => {
        dispatch({ type: GET_POPULAR_CAT_ERROR, payload: error.response });
        // dispatch(loadingAction(false));
        throw error;
      });
  };
};

export const getBusinessDetail = (id) => {
  return (dispatch) => {
    dispatch(skeletonAction(true, DAILY_DEAL));
    dispatch(skeletonAction(true, BUSINESS_SEARCH));

    return getBusenessDatailApi(id)
      .then((response) => {
        if (response && response.status === 200) {
          dispatch(skeletonAction(true, DAILY_DEAL));
          dispatch(skeletonAction(false, BUSINESS_SEARCH));

          return dispatch({
            type: GET_BUSINESS_DETAILS_SUCCESS,
            payload: response.data.result,
          });
        }
      })
      .catch(({ ...error }) => {
        dispatch(skeletonAction(true, DAILY_DEAL));
        return dispatch({
          type: GET_BUSINESS_DETAILS_ERROR,
          payload: error.response,
        });

        //   throw error;
      });
  };
};

export const getLocalHightlight = (data) => {
  return (dispatch, getState) => {
    if (data.lat === "") return;
    const { businessData } = getState()
    const pageCount = data.page;

    dispatch(skeletonAction(true, LOCAL_HIGHLIGHT));
    return localHighlightApi(data)
      .then((response) => {
        if (response && response.status === 200) {
          dispatch(skeletonAction(false, LOCAL_HIGHLIGHT));
          const hide = localStorage.getItem("HIDE_INFO");
          if (response.data.result.BusinessList.length === 0) {
            if (hide === 'false') {
              let hideFlag = false;
              let wrapper = document.createElement("div");
              ReactDOM.render(
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type='checkbox'
                    style={{ width: "20px", height: "20px" }}
                    onChange={(e) => {
                      hideFlag = e.target.checked;
                    }}
                  />
                  <span>Don't show this again</span>
                </div>,
                wrapper
              );
              swal({
                title: "Be The First To Register!",
                text: LOCATION_ERROR_MSG,
                content: wrapper,
                closeOnClickOutside: false,
                closeOnEsc: false,
                icon: "",
                button: "OK",
              }).then((res) => {
                if (hideFlag) {
                  localStorage.setItem("HIDE_INFO", true);
                }
                dispatch(setShow(true));
              });
            } else {
              dispatch(setShow(true));
            }
            dispatch(setLocation("FL"));
            dispatch(skeletonAction(false, LOCAL_HIGHLIGHT));
          }
          // businessData?.localHightlightSuccess?.BusinessList?.length > 0
          let tempResult = response?.data?.result?.BusinessList || []
          if (pageCount > 1) {
            tempResult = [...(businessData?.localHightlightSuccess?.BusinessList || []), ...tempResult]
          }
          return dispatch({
            type: GET_LOCAL_HIGHTLIGHT_SUCCESS,
            payload: {
              BusinessList: tempResult,
              TotalRecords: response?.data?.result?.TotalRecords
            },
          });
        }
      })
      .catch(({ ...error }) => {
        dispatch(setLocation("FL"));
        dispatch(skeletonAction(false, LOCAL_HIGHLIGHT));
        dispatch(setShow(true));

        return dispatch({
          type: GET_LOCAL_HIGHTLIGHT_ERROR,
          payload: error.response,
        });

        //   throw error;
      });
  };
};

export const getBusinessEvent = (id) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return businessEventApi(id)
      .then((response) => {
        if (response && response.status === 200) {
          dispatch(loadingAction(false));
          return dispatch({
            type: GET_BUSINESS_EVENT_SUCCESS,
            payload: response.data,
          });
        }
      })
      .catch(({ ...error }) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: GET_BUSINESS_EVENT_ERROR,
          payload: error.response,
        });
      });
  };
};

export const getBusinessList = (data, type) => {
  return (dispatch) => {
    if (data.lat === "") return;
    dispatch(skeletonAction(true, BUSINESS_LIST));
    return getAllBusinessApi(data, type)
      .then((response) => {
        if (response && response.status === 200) {
          dispatch(skeletonAction(false, BUSINESS_LIST));
          if (type === "localHighlights") {
            if (response?.data?.result.BusinessList.length === 0) {
              dispatch(setLocation("FL"));
            }
          }
          return dispatch({
            type: BUSINESS_LIST_SUCCESS,
            payload: response?.data.result,
          });
        }
      })
      .catch(({ ...error }) => {
        dispatch(skeletonAction(false, BUSINESS_LIST));
        return dispatch({ type: BUSINESS_LIST_ERROR, payload: error.response });
      });
  };
};

export const getBusinessTypeList = (data, type) => {
  return (dispatch) => {
    dispatch(skeletonAction(true, BUSINESS_TYPE_LIST));
    return getBusinessTypeApi()
      .then((response) => {
        if (response && response.status === 200) {
          dispatch(skeletonAction(false, BUSINESS_TYPE_LIST));

          return dispatch({
            type: BUSINESS_TYPE_SUCCESS,
            payload: response?.data.result,
          });
        }
      })
      .catch(({ ...error }) => {
        dispatch(skeletonAction(false, BUSINESS_TYPE_LIST));
        console.log(error, "errr");
        return dispatch({ type: BUSINESS_TYPE_ERROR, payload: error.response });
      });
  };
};

// export const searchBusiness = (data) => {
//   return (dispatch) => {
//     // dispatch(loadingAction(true));
//     return searchBusinessApi(data)
//       .then((response) => {
//         if (response && response.status === 200) {
//           // dispatch(loadingAction(false));
//           return dispatch({
//             type: SEARCH_BUSINESS_SUCCESS,
//             payload: response.data.result, data,
//           });
//         }
//       })
//       .catch(({ ...error }) => {
//         dispatch(loadingAction(false));
//         return dispatch({
//           type: SEARCH_BUSINESS_ERROR,
//           payload: error.response,
//         });

//         //   throw error;
//       });
//   };
// };
export const searchBusiness = (data) => {
  return (dispatch) => {
    // dispatch(loadingAction(true));
    return webSearchBusinessApi(data)
      .then((response) => {
        if (response && response.status === 200) {
          // dispatch(loadingAction(false));
          return dispatch({
            type: SEARCH_BUSINESS_SUCCESS,
            payload: response.data.result,
            data,
          });
        }
      })
      .catch(({ ...error }) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: SEARCH_BUSINESS_ERROR,
          payload: error.response,
        });

        //   throw error;
      });
  };
};


export const setLocalHighlightPageCount = (data) => ({
  type: 'SET_LOCAL_HIGHLIGHT_PAGE_COUNT',
  payload: data,
});



export const getSelectedInterests = (data) => {
  return (dispatch) => {
    dispatch(skeletonAction(true, NEW_INTEREST));
    return getAllSelectedInterest(data)
      .then((response) => {
        if (response && response.status === 200) {
          dispatch(skeletonAction(false, NEW_INTEREST));
          return dispatch({
            type: GET_SELECTED_INTEREST_SUCCESS,
            payload: response.data.result
          });
        }
      })
      .catch((error) => {
        dispatch(skeletonAction(false, NEW_INTEREST));
        return dispatch({
          type: GET_SELECTED_INTEREST_ERROR,
          payload: error.response
        });
      });
  };
};


export const addFavorite = (data) => {
  return (dispatch) => {
    return Selectfavorite(data)
      .then((response) => {
        if (response && response.status === 200) {
          return dispatch({
            type: ADD_FAVORITE_SUCCESS,
            payload: response.data
          });
        }
      })
      .catch(({ ...error }) => {
        return dispatch({
          type: ADD_FAVORITE_ERROR,
          payload: error.response
        });
      });
  };
};


export const removeFavorite = (data) => {
  return (dispatch) => {
    return Removefavorite(data)
      .then((response) => {
        if (response && response.status === 200) {
          return dispatch({
            type: "REMOVE_FAVORITE_SUCCESS",
            payload: response.data
          });
        }
      })
      .catch(({ ...error }) => {
        return dispatch({
          type: "REMOVE_FAVORITE_ERROR",
          payload: error.response
        });
      });
  };
};

export const getFavorites = (data) => {
  return (dispatch) => {
    return getAddFavoriteList(data)
      .then((response) => {
        if (response && response.status === 200) {
          return dispatch({
            type: "GET_FAVORITES_SUCCESS",
            payload: response.data
          });
        }
      })
      .catch(({ ...error }) => {
        return dispatch({
          type: "GET_FAVORITES_ERROR",
          payload: error.response
        });
      });
  };
};


export const getEditInterests = (data) => {
  return (dispatch) => {
    dispatch({ type: "GET_EDIT_INTERESTS_REQUEST" });
    return getAllEditInterest(data)
      .then((response) => {
        if (response.data.status === 200) {
          dispatch({
            type: "GET_EDIT_INTERESTS_SUCCESS",
            payload: response.data.result
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: "GET_EDIT_INTERESTS_ERROR",
          payload: error.response
        });
        throw error;
      });
  };


  
};

export const updateCheckboxInterest = (data) => {
  return (dispatch) => {
    return updateCheckboxEditInterest(data)
      .then((response) => {
        if (response && response.status === 200) {
          return dispatch({
            type: UPDATE_CHECKBOX_SUCCESS,
            payload: response.data
          });
        }
      })
      .catch(({ ...error }) => {
        return dispatch({
          type: UPDATE_CHECKBOX_ERROR,
          payload: error.response
        });
      });
  };
};

