import React, { useState, useEffect } from "react";
import { Link, useNavigate, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import swal from "sweetalert";
import { login } from "../../Redux/Action/LoginAction/loginAction";
import { useDispatch } from "react-redux";
import { setItemLocalStorage } from "../../Utils/localStroge";
import { ANDROID_APP_LINK, IOS_APP_LINK } from "../../Utils/constants";
import EyesOnIcon from "../../assets/images/show.png";
import EyesOffIcon from "../../assets/images/hide.png";
import "./login.css";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [checkedVal, setCheckedVal] = useState("Customer");

  const [values, setValues] = useState({
    email: Cookies.get("email"),
    password: Cookies.get("password"),
  });

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({
      ...values,
      [name]: value,
    });
  };
  // ======= submit Login request ==============
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let body = JSON.stringify({
      email: values.email,
      password: values.password,
      platform: "android",
      os_version: "v1.0",
      app_version: "v.4.5",
      user_timezone: "GMT+5:30",
    });
    // console.log("body--", body);
    try {
      const response = await dispatch(login(body));
      console.log(response, "ccccccccccccccccccccccccccccccccccc")
      const loginDataSuccess = response?.payload;
      console.log(loginDataSuccess, "ttthhhhhhhhhhhhhhh")
      if (loginDataSuccess) {
        if (loginDataSuccess?.data.status == 200) {
          if (loginDataSuccess?.data?.user?.is_deleted == 0) {
            const result = loginDataSuccess.data.user
            if (
              result?.role_id === 2 &&
              checkedVal === "Business"
            ) {
              setItemLocalStorage(
                "accessToken",
                loginDataSuccess.data.access_token
              );
              setItemLocalStorage(
                "refreshToken",
                loginDataSuccess.data.refresh_token
              );
              setItemLocalStorage(
                "user_uuid",
                result?.user_uuid
              );
              setItemLocalStorage("id", result?.userProfile?.id);
              setItemLocalStorage(
                "user",
                JSON.stringify(result)
              );
              if (result.user_state === "0") {
                navigate("/register");
              } else if (result.user_state === "1" || result.user_state === "2") {
                navigate("/adbusinessdetails");
              } else {
                navigate("/");
              }

            } else if (
              result?.role_id === 2 &&
              checkedVal === "Customer"
            ) {
              swal({
                title: "error!",
                text: "Not a Customer account. Check and try again",
                buttons: false,
                icon: "error",
                timer: 3000,
              });
            } else if (
              result?.role_id === 3 &&
              checkedVal === "Business"
            ) {
              swal({
                title: "error!",
                text: "Not a Business account. Check and try again",
                buttons: false,
                icon: "error",
                timer: 3000,
              });
            } else if (
              result?.role_id === 3 &&
              checkedVal === "Customer"
            ) {
              setItemLocalStorage(
                "accessToken",
                loginDataSuccess.data.access_token
              );
              setItemLocalStorage(
                "refreshToken",
                loginDataSuccess.data.refresh_token
              );
              setItemLocalStorage(
                "user_uuid",
                result?.user_uuid
              );
              setItemLocalStorage("id", result?.userProfile?.id);
              if (result.user_state === "0") {
                navigate("/register");
              } else {
                navigate("/");
              }
            }
          } else {
            swal({
              title: "Inactive User!",
              text: "User is either Inactive or Deleted",
              buttons: false,
              icon: "error",
              timer: 3000,
            });
          }
        } else {
          swal({
            title: "error!",
            text: "Please complete your business details from the mobile app and try again",
            buttons: false,
            icon: "error",
            timer: 3000,
          });
        }
      } else {
        swal({
          title: "error!",
          text: loginDataSuccess?.data?.message,
          buttons: false,
          icon: "error",
          timer: 3000,
        });
      }
    } catch (err) {
      console.log(err);
      swal({
        title: "Invalid Credentials",
        text: err.response.data.message,
        buttons: false,
        icon: "error",
        timer: 3000,
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const checkHandler = (event) => {
    setCheckedVal(event.target.value);
  };

  return (
    <>
      <div className="container login2 ">
        <div className="row">
          <div className="col-md-6 trust_sec">
            <h1>
              Your Trusted
              <br />
              Local Connection
              <br />{" "}
              <label
                style={{
                  color: "#349c52",
                  position: "relative",
                }}
              >
                Starts Here
                <img
                  src="images/green-suf.png"
                  className="key_feature_designg2"
                  alt=""
                  style={{ left: "3px" }}
                />
              </label>
            </h1>
            <div id="download_app">
              <div className="container login_download_app mt-1">
                <h6>
                  <img
                    src="images/suffixes3.svg"
                    className="suffixes3"
                    alt=""
                  />
                  Please Download and Install Brigiz App
                  <br />
                  to Explore More features{" "}
                  <img
                    src="images/green-suf2.png"
                    className="green-suf2"
                    style={{
                      width: "168px",
                      height: "32px",
                      marginLeft: "-158px",
                    }}
                    alt=""
                  />
                </h6>
              </div>
              <div className=" exp-download-btns mt-4">
                <button
                  onClick={() => window.open(ANDROID_APP_LINK, "_")}
                  style={{ background: "#292828" }}
                >
                  <div className="g_pay_container ">
                    <div className=" btn-img1">
                      <img src="images/play-button.svg" alt="" />
                    </div>
                    <div className=" btn-txt1">
                      <span>GET IT ON</span>
                      <p className="text-white m-0">Google Play</p>
                    </div>
                  </div>
                </button>
                <button
                  onClick={() => window.open(IOS_APP_LINK, "_")}
                  style={{ width: "180px", background: "#292828" }}
                >
                  <div className="g_pay_container">
                    <div className=" btn-img1">
                      <img src="images/apple.svg" />
                    </div>
                    <div className="col-md-9 btn-txt1">
                      <span>Download on the</span>
                      <p className="text-white m-0">App Store</p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 form_screen">
            <div className="login_card bg-white p-5 mt-1">
              <div className="login_back_btn">
                {" "}
                <h4 className="login_form_label_2">Sign In Your Account</h4>
                <button
                  href="#"
                  style={{ verticalAlign: "text-bottom" }}
                  onClick={() => navigate(-1)}
                >
                  <img src="images/back.png" alt="" /> Back
                </button>
                {/* <span className='ms-2'>Back</span> */}
              </div>
              <form onSubmit={handleSubmit} className="login_form2">
                <div className="d-flex mt-4 row">
                  <div className="form-check col-md-6">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="radio1"
                      name="optradio"
                      value="Customer"
                      onChange={checkHandler}
                      checked={checkedVal === "Customer"}

                    //   checked={checkedVal}
                    />
                    <label className="form-check-label2" for="radio1">
                      For Customer
                    </label>
                  </div>
                  <div className="form-check col-md-6">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="radio2"
                      name="optradio"
                      value="Business"
                      onChange={checkHandler}
                      checked={checkedVal === "Business"}
                    />

                    <label className="form-check-label2" for="radio2">
                      For Business
                    </label>
                  </div>
                </div>
                <label className="login_form_email2 mt-4 mb-2">
                  Email Address
                </label>
                <input
                  className="login_form_input2"
                  type="text"
                  name="email"
                  onChange={handleInputChange}
                />

                <label className="login_form_email2 my-2">Password</label>
                <div className="password-input-container">
                  <input
                    className="login_form_input2"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    onChange={handleInputChange}
                  />
                  <button
                    type="button"
                    className="password-toggle-btn bg-transparent"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <img src={EyesOnIcon} className="eyes-icon" alt="" />
                    ) : (
                      <img src={EyesOffIcon} className="eyes-icon" alt="" />
                    )}
                  </button>
                </div>
                <div className="forgot_password text-end">
                  <Link to="/forgotpassword">Forgot Password</Link>
                </div>
                <button className="login_form_btn mt-4" disabled={!checkedVal}>
                  Sign In
                </button>
                <div className="signup_text text-center mt-3">
                  Don’t have an account? <Link to="/register">Sign Up</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div id="mobile_view">
          <div className="container login_download_app mt-1">
            <h6>
              <img src="images/suffixes3.svg" className="suffixes3" alt="" />
              Please Download and Install Brigiz App
              <br />
              to Explore More features{" "}
              <img
                src="images/green-suf2.png"
                className="green-suf2"
                style={{
                  width: "168px",
                  height: "32px",
                  marginLeft: "-158px",
                }}
                alt=""
              />
            </h6>
          </div>
          <div className=" exp-download-btns mt-4">
            <button
              onClick={() => window.open(ANDROID_APP_LINK, "_")}
              style={{ background: "#292828" }}
            >
              <div className="g_pay_container ">
                <div className=" btn-img1">
                  <img src="images/play-button.svg" alt="" />
                </div>
                <div className=" btn-txt1">
                  <span>GET IT ON</span>
                  <p className="text-white m-0">Google Play</p>
                </div>
              </div>
            </button>
            <button
              onClick={() => window.open(IOS_APP_LINK, "_")}
              style={{ width: "180px", background: "#292828" }}
            >
              <div className="g_pay_container">
                <div className=" btn-img1">
                  <img src="images/apple.svg" />
                </div>
                <div className="col-md-9 btn-txt1">
                  <span>Download on the</span>
                  <p className="text-white m-0">App Store</p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
