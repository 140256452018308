

import React, { useEffect, useState } from "react";
import SkeletonLoader from "../../Component/skeleton-loader/Skeleton";
import { BUSINESS_ADS, DAILY_DEAL } from "../../Utils/constants";
import { getAds, getDailyDeals } from "../../API/ApiService";
import { useDispatch, useSelector } from "react-redux";
import { skeletonAction } from "../../Redux/Action/loadingAction";
import EmptyMessage from "../../Component/EmptyMessage";
import DailyDealsAds from "../../Component/DailyDealsAds";

import styled from "styled-components";
import DailyDealsCard from "../../Component/DailyDealsCard";

function DailyDeals() {
  const dispatch = useDispatch();

  const {
    latLng: { lat, lng },
  } = useSelector((state) => state.locationReducer);

  const adIsLoading = useSelector(
    (state) => state?.loadingReducers?.skeletonLoader?.BUSINESS_ADS
  );
  const skeletonLoad = useSelector(
    (state) => state?.loadingReducers?.skeletonLoader
  );
  const { stateList, selectedState } = useSelector(
    (state) => state.locationReducer
  );

  const currentState = stateList.find((s) => s.state_code === selectedState);
  const [adsList, setAdsList] = useState([]);
  const [daliyDealsList, setDailyDeals] = useState([]);
  const user_uuid = localStorage.getItem("user_uuid");

  //testing
  // const MOCK_ADS = {
  //   basic: [
  //     {
  //       id: 1,
  //       title: "Special Discount on Electronics",
  //       description: "Get 50% off on all electronics",
  //       image_url: "https://picsum.photos/600/300",
  //       business_name: "Tech Store",
  //       address: "123 Tech Street, NY",
  //       phone: "123-456-7890",
  //       rating: 4.5,
  //     },
  //     {
  //       id: 2,
  //       title: "Summer Fashion Sale",
  //       description: "Up to 70% off on summer collection",
  //       image_url: "https://picsum.photos/600/301",
  //       business_name: "Fashion Hub",
  //       address: "456 Fashion Ave, NY",
  //       phone: "123-456-7891",
  //       rating: 4.2,
  //     },
  //     {
  //       id: 3,
  //       title: "Restaurant Special Offer",
  //       description: "Buy 1 Get 1 Free on all main courses",
  //       image_url: "https://picsum.photos/600/302",
  //       business_name: "Foodie's Paradise",
  //       address: "789 Food Street, NY",
  //       phone: "123-456-7892",
  //       rating: 4.8,
  //     },
  //     {
  //       id: 4,
  //       title: "Spa Day Special",
  //       description: "30% off on all spa treatments",
  //       image_url: "https://picsum.photos/600/303",
  //       business_name: "Luxury Spa",
  //       address: "321 Spa Road, NY",
  //       phone: "123-456-7893",
  //       rating: 4.6,
  //     },
  //     {
  //       id: 5,
  //       title: "Fitness Membership Deal",
  //       description: "Join now and get 3 months free",
  //       image_url: "https://picsum.photos/600/304",
  //       business_name: "Elite Gym",
  //       address: "555 Fitness Blvd, NY",
  //       phone: "123-456-7894",
  //       rating: 4.3,
  //     },
  //   ],
  // };

  // const MOCK_DEALS = {
  //   BusinessList: [
  //     {
  //       id: 1,
  //       title: "Weekend Special Package",
  //       description: "Complete weekend relaxation package",
  //       image_url: "https://picsum.photos/600/305",
  //       business_name: "Wellness Center",
  //       address: "111 Health Street, NY",
  //       phone: "123-456-7895",
  //       rating: 4.7,
  //       discount: "40% OFF",
  //     },
  //     {
  //       id: 2,
  //       title: "Family Dinner Deal",
  //       description: "Special family package for 4",
  //       image_url: "https://picsum.photos/600/306",
  //       business_name: "Family Restaurant",
  //       address: "222 Family Road, NY",
  //       phone: "123-456-7896",
  //       rating: 4.4,
  //       discount: "25% OFF",
  //     },
  //     {
  //       id: 3,
  //       title: "Beauty Package",
  //       description: "Complete beauty treatment",
  //       image_url: "https://picsum.photos/600/307",
  //       business_name: "Beauty Salon",
  //       address: "333 Beauty Ave, NY",
  //       phone: "123-456-7897",
  //       rating: 4.9,
  //       discount: "35% OFF",
  //     },
  //   ],
  // };

  // useEffect(() => {
  //   // Simulate API call delay
  //   dispatch(skeletonAction(true, BUSINESS_ADS));
  //   setTimeout(() => {
  //     setAdsList(MOCK_ADS);
  //     dispatch(skeletonAction(false, BUSINESS_ADS));
  //   }, 1000);
  // }, [dispatch]);

  // useEffect(() => {
  //   // Simulate API call delay
  //   dispatch(skeletonAction(true, DAILY_DEAL));
  //   setTimeout(() => {
  //     setDailyDeals(MOCK_DEALS);
  //     dispatch(skeletonAction(false, DAILY_DEAL));
  //   }, 1000);
  // }, [dispatch]);

  // Fetch ads data from API

  useEffect(() => {
    async function fetchAds() {
      dispatch(skeletonAction(true, BUSINESS_ADS));
      await getAds({ lat, lng })
        .then((res) => {
          setAdsList(res.data.result);
          dispatch(skeletonAction(false, BUSINESS_ADS));
        })
        .catch(() => {
          dispatch(skeletonAction(false, BUSINESS_ADS));
        });
    }

    if (lat && lng) {
      fetchAds();
    }
  }, [lat, lng]);

  // Fetch daily deals data from API
  useEffect(() => {
    const body = {
      page: 1,
      text: "",
      fetchtype: "home",
      user_uuid: user_uuid,
      limit: 10,
      state: currentState?.state || "",
      state_code: currentState?.state_code || "",
      lat,
      lng,
    };

    dispatch(skeletonAction(true, DAILY_DEAL));
    getDailyDeals(body)
      .then((res) => {
        setDailyDeals(res.data.result);
        dispatch(skeletonAction(false, DAILY_DEAL));
      })
      .catch(() => {
        dispatch(skeletonAction(false, DAILY_DEAL));
      });
  }, [currentState, lat, lng]);

  // const renderCombinedContent = () => {
  //   const ads = adsList?.basic || [];

  //   const deals = daliyDealsList?.BusinessList || [];
  //   const combinedContent = [];
  //   let dealIndex = 0;

  //   // Iterate through ads and insert 2 deals after each ad
  //   for (let adIndex = 0; adIndex < ads.length; adIndex++) {
  //     combinedContent.push(
  //       <div
  //         className="row"
  //         key={`ad_${adIndex}`}
  //         style={{ marginBottom: "30px" }}
  //       >
  //         <div className="col-12">
  //           <DailyDealsAds
  //             width="100%"
  //             height={350}
  //             data={ads[adIndex]}
  //             showReview
  //             showAddress
  //             showPhone
  //           />
  //         </div>
  //       </div>
  //     );

  //     // Add 2 deals if available
  //     if (dealIndex < deals.length) {
  //       const dealsRow = [];
  //       for (let i = 0; i < 2 && dealIndex < deals.length; i++) {
  //         dealsRow.push(
  //           <div
  //             className={`col-md-6 ${i === 0 ? "pe-md-4" : "ps-md-4"}`}
  //             key={`deal_${dealIndex}`}
  //             style={{ marginBottom: "20px" }}
  //           >
  //             <DailyDealsCard
  //               height={380}
  //               data={deals[dealIndex]}
  //               showReview
  //               showAddress
  //               showPhone
  //             />
  //           </div>
  //         );
  //         dealIndex++;
  //       }

  //       if (dealsRow.length > 0) {
  //         combinedContent.push(
  //           <div
  //             className="row"
  //             key={`deals_row_${adIndex}`}
  //             style={{ marginBottom: "30px", marginLeft: "23px" }}
  //           >
  //             {dealsRow}
  //           </div>
  //         );
  //       }
  //     }
  //   }

  //   return combinedContent;
  // };

  const renderCombinedContent = () => {
    const ads = adsList?.basic || [];
    const deals = daliyDealsList?.BusinessList || [];
    const combinedContent = [];
    let dealIndex = 0;
  
    // Function to add two deals
    const addTwoDeals = () => {
      const dealsRow = [];
      for (let i = 0; i < 2 && dealIndex < deals.length; i++) {
        dealsRow.push(
          <div 
            className={`col-md-6 ${i === 0 ? 'pe-md-4' : 'ps-md-4'}`} 
            key={`deal_${dealIndex}`}
            style={{ marginBottom: "20px" }}
          >
            <DailyDealsCard
              height={380}
              data={deals[dealIndex]}
              showReview
              showAddress
              showPhone
            />
          </div>
        );
        dealIndex++;
      }
      
      if (dealsRow.length > 0) {
        combinedContent.push(
          <div className="row" key={`deals_row_${dealIndex}`} style={{ marginBottom: "20px", marginLeft: "23px" }}>
            {dealsRow}
          </div>
        );
      }
    };
  
    // First, add ads with two deals after each
    for (let adIndex = 0; adIndex < ads.length; adIndex++) {
      // Add the ad
      combinedContent.push(
        <div className="row" key={`ad_${adIndex}`} style={{ marginBottom: "20px" }}>
          <div className="col-12">
            <DailyDealsAds
              width="100%"
              height={350}
              data={ads[adIndex]}
              showReview
              showAddress
              showPhone
            />
          </div>
        </div>
      );
  
      // Add two deals after the ad
      if (dealIndex < deals.length) {
        addTwoDeals();
      }
    }
  
    // Add remaining deals in pairs of two
    while (dealIndex < deals.length) {
      addTwoDeals();
    }
  
    return combinedContent;
  };



  return (
    <>
      <div>
        <section className="interestDiv">
          <div className="container px-0">
            <div className="row">
              <div className="col-md-12">
                <h2>
                  Daily <span style={{ color: "#3CA059" }}>Deals</span>
                </h2>
              </div>
            </div>

            {adIsLoading || skeletonLoad[DAILY_DEAL] ? (
              <LoadingBlocks>
                {Array.from({ length: 3 }, (_, index) => (
                  <div
                    key={`skeleton_${index}`}
                    className="slider-skeleton-block"
                  >
                    <SkeletonLoader height={360} />
                  </div>
                ))}
              </LoadingBlocks>
            ) : !adsList?.basic?.length &&
              !daliyDealsList?.BusinessList?.length ? (
              <EmptyMessage message="No content available." />
            ) : (
              <div className="container">{renderCombinedContent()}</div>
            )}
          </div>
        </section>
      </div>
    </>
  );
}

export default DailyDeals;

const LoadingBlocks = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;

  .slider-skeleton-block {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
  }
`;
