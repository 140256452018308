import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate, Redirect, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import swal from "sweetalert";
import { login } from "../../Redux/Action/LoginAction/loginAction";
import { useDispatch, useSelector } from "react-redux";
import { setItemLocalStorage } from "../../Utils/localStroge";
import { ANDROID_APP_LINK, IOS_APP_LINK, SIGN_UP_TYPE } from "../../Utils/constants";
import EyesOnIcon from "../../assets/images/show.png";
import EyesOffIcon from "../../assets/images/hide.png";
import Select from "react-select";
import countryList from "react-select-country-list";

import "./login.css";
import { PhoneInput, usePhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { facebookRegisterUpdate, googleRegisterUpdate, phoneRegisterUpdate, registerApi } from "../../API/ApiService";
import { loadingAction } from "../../Redux/Action/loadingAction";

function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState();
  const [phoneNumber, setPhoneNumber] = useState();

  const socialLoginOptions = [SIGN_UP_TYPE.GOOGLE, SIGN_UP_TYPE.APPLE, SIGN_UP_TYPE.FACEBOOK]

  const { loginDataSuccess } = useSelector((state) => state.LoginData);
  const isLoggedIn = loginDataSuccess?.access_token && loginDataSuccess?.result;
  const loginData = loginDataSuccess?.result;
  const signUpType = loginDataSuccess?.result?.signup_type
  const isSocialLogin = isLoggedIn && socialLoginOptions.includes(signUpType)


  const [values, setValues] = useState({
    role_id: 2,
    name: "",
    email: "",
    password: "",
    country_code: "",
    phone: "",
    dob: "01/01/1990",
    zipcode: "",
  });
  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [checkedVal, setCheckedVal] = useState("2");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (loginData && loginData.role_id) {
      setValues({
        role_id: loginData.role_id,
        name: loginData?.userProfile?.name || "",
        email: loginData?.email_id || "",
        password: "",
        country_code: "",
        phone: loginData?.phone || "",
        dob: "01/01/1990",
        zipcode: "",
      })

      if (loginData?.country_code?.length > 1) {
        setCountryCode(loginData.country_code.replace('+', ""))
      }
    }
  }, [loginData])


  const changeHandler = (value) => {
    setValue(value);
  };

  const handlePhone = (phone, country, inputvalue) => {
    setPhone(phone);
    setCountryCode("+" + country?.dialCode);
    let mystring = phone.replace("+", "");
    mystring = mystring.replace(country?.dialCode, "");
    setPhoneNumber(mystring);
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({
      ...values,
      [name]: value,
    });
  };

  // ======= submit Login request ==============
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(loadingAction(true));

    if (values?.password !== values?.cpassword) {
      setErrorMsg("Password and Confirm Password do not match!");
      return;
    } else {
      let userData = {
        name: values?.name,
        email: values?.email,
        dob: "01/01/1990",
        zipcode: values?.zipcode,
        country_code: countryCode,
        password: values?.password
      };

      if (!isLoggedIn || (isLoggedIn && signUpType !== SIGN_UP_TYPE.PHONE)) {
        userData = {
          ...userData,
          role_id: values.role_id,
          phone: phoneNumber,
          platform: "android",
          os_version: "v1.0",
          app_version: "v.4.5",
          user_timezone: "GMT+5:30",
        };
      }
      if (loginData?.registered_by_email === 1) {
        delete userData?.email;
      }
      let finalAPI = registerApi

      if (isLoggedIn) {
        switch (loginData.signup_type) {
          case SIGN_UP_TYPE.GOOGLE:
            finalAPI = googleRegisterUpdate
            break;
          case SIGN_UP_TYPE.FACEBOOK:
            finalAPI = facebookRegisterUpdate
            break;
          case SIGN_UP_TYPE.PHONE:
            finalAPI = phoneRegisterUpdate
            break;
          default:
            finalAPI = registerApi
            break;
        }
      }
      try {
        const response = await finalAPI(userData);
        const loginDataSuccess = response;

        if (loginDataSuccess) {
          dispatch(loadingAction(false));

          if (loginDataSuccess?.data?.result?.is_deleted == 0) {
            if (loginDataSuccess?.data?.result?.user_state == "0") {
              setItemLocalStorage(
                "accessToken",
                loginDataSuccess.data.access_token
              );
              setItemLocalStorage(
                "refreshToken",
                loginDataSuccess.data.refresh_token
              );
              setItemLocalStorage(
                "user",
                JSON.stringify(loginDataSuccess.data.result)
              );
            }
          }
          if (isLoggedIn) {
            swal({
              title: "Profile Updated successfull!",
              text: "",
              buttons: false,
              icon: "success",
              timer: 3000,
            }).then(() =>
              navigate("/")
            );
          } else {
            swal({
              title: "Registration successfull!",
              text: "Registration Successfull now verify OTP send to your account",
              buttons: false,
              icon: "success",
              timer: 3000,
            }).then(() =>
              navigate("/signinotp", { state: { forget_password: 0 } })
            );
          }
        } else {
          dispatch(loadingAction(false));

          swal({
            title: "Registration Failed",
            text: loginDataSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 3000,
          });
        }
      } catch (err) {
        dispatch(loadingAction(false));

        swal({
          title: "Registration Failed!",
          text: err?.response.data.message,
          buttons: false,
          icon: "error",
          timer: 3000,
        });
      }
    }
  };

  const togglePasswordVisibility = (val) => {
    if (val === "password") {
      setShowPassword(!showPassword);
    } else {
      setShowPassword2(!showPassword2);
    }
  };

  const checkHandler = (event) => {
    setCheckedVal(event.target.value);
  };

  return (
    <>
      <div className="container login2 ">
        <div className="row">
          <div className="col-md-6 trust_sec">
            <h1>
              Your Trusted
              <br />
              Local Connection
              <br />{" "}
              <label
                style={{
                  color: "#349c52",
                  position: "relative",
                }}
              >
                Starts Here
                <img
                  src="images/green-suf.png"
                  className="key_feature_designg2"
                  alt=""
                  style={{ left: "3px" }}
                />
              </label>
            </h1>
            <div id="download_app">
              <div className="container login_download_app mt-1">
                <h6>
                  <img
                    src="images/suffixes3.svg"
                    className="suffixes3"
                    alt=""
                  />
                  Please Download and Install Brigiz App
                  <br />
                  to Explore More features{" "}
                  <img
                    src="images/green-suf2.png"
                    className="green-suf2"
                    style={{
                      width: "141px",
                      height: "32px",
                      marginLeft: "-136px",
                    }}
                    alt=""
                  />
                </h6>
              </div>
              <div className=" exp-download-btns mt-4">
                <button
                  onClick={() => window.open(ANDROID_APP_LINK, "_")}
                  style={{ background: "#292828" }}
                >
                  <div className="g_pay_container ">
                    <div className=" btn-img1">
                      <img src="images/play-button.svg" alt="" />
                    </div>
                    <div className=" btn-txt1">
                      <span>GET IT ON</span>
                      <p className="text-white m-0">Google Play</p>
                    </div>
                  </div>
                </button>
                <button
                  onClick={() => window.open(IOS_APP_LINK, "_")}
                  style={{ width: "180px", background: "#292828" }}
                >
                  <div className="g_pay_container">
                    <div className=" btn-img1">
                      <img src="images/apple.svg" />
                    </div>
                    <div className="col-md-9 btn-txt1">
                      <span>Download on the</span>
                      <p className="text-white m-0">App Store</p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 form_screen" id="signup_screen_1">
            <div className="login_card bg-white p-4 mt-1 mb-5">
              <div className="login_back_btn">
                {" "}
                <h4 className="login_form_label_2">{isLoggedIn && loginData ? "Lets Get Started" : "Create an Account!"}</h4>
                <button
                  href="#"
                  style={{ verticalAlign: "text-bottom" }}
                  onClick={() => navigate(-1)}
                >
                  <img src="/images/back.png" alt="" /> Back
                </button>
              </div>
              <form onSubmit={handleSubmit} className="login_form2">
                {!loginData && <div className="d-flex mt-4 row">
                  <div className=" col-md-6">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="radio1"
                      name="role_id"
                      value="3"
                      onChange={(e) => {
                        handleInputChange(e);
                        checkHandler(e);
                      }}
                      checked={checkedVal === "3"}
                    />
                    <label className="form-check-label2" for="radio1">
                      For Customer
                    </label>
                  </div>
                  <div className="form-check col-md-6">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="radio2"
                      name="role_id"
                      value="2"
                      // onChange={checkHandler}
                      onChange={(e) => {
                        handleInputChange(e);
                        checkHandler(e);
                      }}
                      checked={checkedVal === "2"}
                    />

                    <label className="form-check-label2" for="radio2">
                      For Business
                    </label>
                  </div>
                </div>}

                <label className="login_form_email2 mt-4 mb-2">Full Name</label>
                <input
                  className="login_form_input2"
                  type="text"
                  name="name"
                  value={values.name}
                  disabled={isSocialLogin}
                  onChange={handleInputChange}
                />
                <label className="login_form_email2 my-2">Email Address</label>
                <input
                  className="login_form_input2"
                  type="text"
                  disabled={isSocialLogin}
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                />
                <div className="register-form-city-wrap">
                  <div style={{ width: "50%" }}>
                    <>
                      <label className="login_form_email2 my-2">Mobile Number</label>
                      <PhoneInput
                        defaultCountry="us"
                        value={phone}
                        onChange={(phone, { country, inputValue }) => {
                          handlePhone(phone, country, inputValue);
                        }}
                        name="phone"
                      />
                    </>
                  </div>
                  <div style={{ width: "50%" }}>
                    <label className="login_form_email2 my-2">Zip Code</label>
                    <input
                      className="login_form_input2"
                      type="text"
                      name="zipcode"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <label className="login_form_email2 my-2">Password</label>
                <div className="password-input-container">
                  <input
                    className="login_form_input2 mb-1"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    onChange={handleInputChange}
                  />
                  <button
                    type="button"
                    className="password-toggle-btn bg-transparent"
                    onClick={() => togglePasswordVisibility("password")}
                  >
                    {showPassword ? (
                      <img src={EyesOnIcon} className="eyes-icon" alt="" />
                    ) : (
                      <img src={EyesOffIcon} className="eyes-icon" alt="" />
                    )}
                  </button>
                </div>
                <span className="password_warning_text mt-1 mb-3">
                  Use 8 or more characters with a mix of letters, numbers &
                  symbols
                </span>
                <label className="login_form_email2 mt-3 mb-2">
                  Confirm Password
                </label>
                <div className="password-input-container">
                  <input
                    className="login_form_input2"
                    type={showPassword2 ? "text" : "password"}
                    name="cpassword"
                    onChange={handleInputChange}
                  />
                  <button
                    type="button"
                    className="password-toggle-btn bg-transparent"
                    onClick={() => togglePasswordVisibility("cpassword")}
                  >
                    {showPassword2 ? (
                      <img src={EyesOnIcon} className="eyes-icon" alt="" />
                    ) : (
                      <img src={EyesOffIcon} className="eyes-icon" alt="" />
                    )}
                  </button>
                </div>
                {errorMsg && (
                  <span className="password_warning_text mt-1 mb-3 text-danger">
                    {errorMsg}
                    <br />
                  </span>
                )}
                <button className="login_form_btn mt-4" disabled={!checkedVal}>
                  {isLoggedIn ? "Update" : "Sign Up"}
                </button>
                {!isLoggedIn && <div className="signup_text text-center mt-3">
                  Already have an account? <Link to="/login">Sign In</Link>
                </div>}
              </form>
            </div>
          </div>
        </div>
        <div id="mobile_view">
          <div className="container login_download_app mt-1">
            <h6>
              <img src="images/suffixes3.svg" className="suffixes3" alt="" />
              Please Download and Install Brigiz App
              <br />
              to Explore More features{" "}
              <img
                src="images/green-suf2.png"
                className="green-suf2"
                style={{
                  width: "168px",
                  height: "32px",
                  marginLeft: "-158px",
                }}
                alt=""
              />
            </h6>
          </div>
          <div className=" exp-download-btns mt-4">
            <button
              onClick={() => window.open(ANDROID_APP_LINK, "_")}
              style={{ background: "#292828" }}
            >
              <div className="g_pay_container ">
                <div className=" btn-img1">
                  <img src="images/play-button.svg" alt="" />
                </div>
                <div className=" btn-txt1">
                  <span>GET IT ON</span>
                  <p className="text-white m-0">Google Play</p>
                </div>
              </div>
            </button>
            <button
              onClick={() => window.open(IOS_APP_LINK, "_")}
              style={{ width: "180px", background: "#292828" }}
            >
              <div className="g_pay_container">
                <div className=" btn-img1">
                  <img src="images/apple.svg" />
                </div>
                <div className="col-md-9 btn-txt1">
                  <span>Download on the</span>
                  <p className="text-white m-0">App Store</p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
