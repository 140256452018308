import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../Contact/Contact.css";
import "./AdBusinessDetails.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { PhoneInput } from "react-international-phone";
import {
  addBusinessDetails,
  getBusinessCategories,
  getBusinessTypes,
} from "../../API/ApiService";
import { loadingAction } from "../../Redux/Action/loadingAction";
import DefaultImage from "../../assets/images/add-image.png";

import { addBusinessImage } from "../../API/ApiService";
import { usePlacesWidget } from "react-google-autocomplete";
import { GOOGLE_API_KEY } from "../../Utils/constants";

// import MobileLeft from "../../assets/images/mobile2.png";
// import MobileRight from "../../assets/images/mobile.png";

const AdBusinessDetails = () => {
  const [isFirst, setIsFirst] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [tags, setTags] = React.useState([]);
  const [categories, setCategories] = useState();
  const [businessTypes, setBusinessTypes] = useState();
  // const [country, setCountry] = useState(null);
  // const [currentState, setCurrentState] = useState(null);
  // const [currentCity, setCurrentCity] = useState(null);
  const [isAddressHide, setAddressHide] = useState(0);
  const [avatarURL, setAvatarURL] = useState(DefaultImage);
  const [uploadedFile, setUploadedFile] = useState();

  const fileUploadRef = useRef();

  const handleImageUpload = (event) => {
    event.preventDefault();
    fileUploadRef.current.click();
  };
  const uploadImageDisplay = async () => {
    try {
      setUploadedFile(fileUploadRef.current.files[0]);

      const cachedURL = URL.createObjectURL(fileUploadRef.current.files[0]);
      setAvatarURL(cachedURL);
      // const formData = new FormData();
      // formData.append("file", uploadedFile);

      // const body = {
      //   image: uploadedFile.name,
      //   format: uploadedFile.type,
      // };

      // const response = await addBusinessImage(body);
      // console.log(response);
      // if (response) {
      //   setAvatarURL(URL.createObjectURL(response?.data?.image_name));
      // }
    } catch (error) {
      console.error(error);
      // setAvatarURL(DefaultImage);
      toast.error(error?.response?.data?.message);
    }
  };

  //

  const formik = useFormik({
    initialValues: {
      business_name: "",
      email: "",
      tag_line: "",
      tag: [],
      message: "",
      description: "",
      phone: "",
      busi_cat_id: "",
      busi_type_id: "",
      website: "",
      address: "",

      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      lat: "",
      lng: "",
      status: 1,
      facebook: "",
      twitter: "",
      linkedIn: "",
      instagram: "",
      tiktok: "",
      youtube: "",
      is_address_hide: 0,
      is_claimed: 0,
      country_code: "",
    },
    validationSchema: Yup.object({
      business_name: Yup.string().trim().required("Name Required"),
      email: Yup.string()
        .trim()
        .email("Invalid email"),
      description: Yup.string().trim().required("Description Required"),
      phone: Yup.string().trim().required("Phone Required"),
      busi_cat_id: Yup.string().trim().required("Business Category Required"),
      busi_type_id: Yup.string().trim().required("Business Type Required"),
      // website: Yup.string().trim().required("Website Required"),
      address1: Yup.string().trim().required("Address Line 1 Required"),
      // address1: Yup.string().trim().required("Address Line 2 Required"),
      // country: Yup.string().trim().required("Country Required"),
      // state: Yup.string().trim().required("State Required"),
      // city: Yup.string().trim().required("City Required"),
      zipcode: Yup.string().trim().required("Zip code Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      let body = JSON.stringify({
        business_name: values.business_name,
        email: values.email,
        tag_line: values.tag_line,
        tag: tags,
        description: values?.description,
        phone: phone,
        busi_cat_id: values?.busi_cat_id,
        busi_type_id: values?.busi_type_id,
        website: values?.website,
        address1: values?.address1,
        address2: values?.address2,
        address: values?.address,

        city: values?.city,
        state: values?.state,
        country: values?.country,
        zipcode: values?.zipcode,
        lat: values?.lat,
        lng: values?.lng,
        status: 1,
        facebook: "",
        twitter: "",
        linkedIn: "",
        instagram: "",
        tiktok: "",
        youtube: "",
        is_address_hide: isAddressHide ? 1 : 0,
        is_claimed: 0,
        country_code: countryCode,
      });
      dispatch(loadingAction(true));

      try {
        let response = await addBusinessDetails(body);
        if (response) {
          dispatch(loadingAction(false));
          toast.success("Business Added Successfully");
          resetForm();
          const body = {
            image: uploadedFile.name,
            format: uploadedFile.type,
          };
          const response = await addBusinessImage(body);
          if (response) {
            navigate("/");
          }
        } else {
          dispatch(loadingAction(false));
          toast.error("Somethings went wrong!");
        }
      } catch (err) {
        dispatch(loadingAction(false));
        console.log(err);
        toast.error(err?.response.data.message);
      }
    },
  });

  const { ref, autocompleteRef } = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    onPlaceSelected: (details) => {
      console.log(details, "/////");
      const address_components =
        details?.address_components || [];
      const streetNumber = address_components.filter(
        (ele) => ele.types.indexOf("street_number") !== -1
      );
      const streetAddress = address_components.filter(
        (ele) => ele.types.indexOf("route") !== -1
      );
      const state = address_components.filter(
        (ele) =>
          ele.types.indexOf("administrative_area_level_1") !==
          -1
      );
      const city = address_components.filter(
        (ele) => ele.types.indexOf("locality") !== -1
      );
      const zipCode = address_components.filter(
        (ele) => ele.types.indexOf("postal_code") !== -1
      );
      const area = address_components.filter(
        (ele) => ele.types.indexOf("neighborhood") !== -1
      );
      const country = address_components.filter(
        (ele) => ele.types.indexOf("country") !== -1
      );
      const city1 = address_components.filter(
        (ele) => ele.types.indexOf("postal_town") !== -1
      );
      let address = {
        addressLine1: details?.formatted_address
          ?.split(",")
          .slice(0, -2)
          .join(","),
        city:
          city && city?.length
            ? city[0]?.long_name
            : city1 && city1?.length
              ? city1[0]?.long_name
              : "",
        state:
          state && state?.length ? state[0]?.long_name : "",
        area: area && area?.length ? area[0]?.long_name : "",
        postCode:
          zipCode && zipCode?.length
            ? zipCode[0]?.long_name
            : "",
        lat:
          details?.geometry?.location &&
            details?.geometry?.location?.lat
            ? details?.geometry?.location?.lat
            : null,
        lng:
          details?.geometry?.location &&
            details?.geometry.location?.lng
            ? details?.geometry?.location?.lng
            : null,
        country:
          country && country?.length
            ? country[0]?.long_name
            : "",
      };
      // formik.values.city = address?.city;
      formik.setFieldValue('address', address?.addressLine1)
      formik.setFieldValue('address1', address?.addressLine1)
      formik.setFieldValue('lat', address?.lat())
      formik.setFieldValue('lng', address?.lng())
      formik.setFieldValue('zipcode', address?.postCode)
      formik.setFieldValue('city', address?.city)
      formik.setFieldValue('state', address?.state)
      formik.setFieldValue('country', address?.country)
      // formik.values.lat = address?.lat;
      // formik.values.lng = address?.lng;
      // formik.values.zipcode = address?.postCode;
      console.log(address, "???????");

    }
  }, [formik]);
  console.log(formik);

  const handlePhone = (phone, country, inputvalue) => {
    setPhone(phone);
    setCountryCode("+" + country?.dialCode);
    let mystring = phone.replace("+", "");
    mystring = mystring.replace(country?.dialCode, "");
    setPhoneNumber(mystring);
  };

  const addTags = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      setTags([...tags, event.target.value]);
      event.target.value = "";
    }
  };

  const removeTags = (index) => {
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
  };

  useEffect(() => {
    formik.values.tag = tags;
  }, [tags]);

  useEffect(() => {
    formik.values.phone = phoneNumber;
  }, [phone]);

  // get categories

  const getCategories = async () => {
    const response = await getBusinessCategories();
    setCategories(response.data?.result);
  };

  //  get Business types
  const getTypes = async () => {
    const response = await getBusinessTypes();
    setBusinessTypes(response.data?.result);
  };

  useEffect(() => {
    if (!categories) {
      getCategories();
    }
    if (!businessTypes) {
      getTypes();
    }
  }, [categories, businessTypes]);

  return (
    <>
      <section className='heading'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h2>Add Business Details</h2>
            </div>
            <div className='relative h-96 w-96 m-8'>
              <form id='form' encType='multipart/form-data'>
                <button
                  type='submit'
                  onClick={handleImageUpload}
                  className='flex-center absolute bottom-12 right-14 h-9 w-9 rounded-full'
                  style={{
                    border: "1px dashed  #3CA059",
                    borderRadius: "50%",
                    padding: "38px",
                    overflowClipMargin: "padding-box",
                    margin: "30px 0",
                    background: "transparent",
                  }}
                >
                  <img
                    src={avatarURL}
                    // src={`https://bgz-business-dev.s3.us-east-2.amazonaws.com/d273b7eb-d82b-4c0b-a097-5d0101a033b4fbDA2A`}
                    alt='Avatar'
                    height={70}
                    width={70}
                    style={
                      {
                        // borderRadius: "50%",
                      }
                    }
                  />
                </button>
                <input
                  type='file'
                  id='file'
                  accept='image/*'
                  ref={fileUploadRef}
                  onChange={uploadImageDisplay}
                  hidden
                />
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className='contact-form pt-1'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <form onSubmit={formik.handleSubmit}>
                <div className='row mb-2'>
                  <div className='col-md-6'>
                    <label className='input_label'>Business Name*</label>
                    <input
                      type='text'
                      name='business_name'
                      placeholder='Enter Business Name'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.business_name}
                    />
                    {formik.touched.business_name &&
                      formik.errors.business_name ? (
                      <div
                        className='invalid-feedback'
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.business_name}
                      </div>
                    ) : null}
                  </div>
                  <div className='col-md-6'>
                    <label className='input_label'>Tagline</label>
                    <input
                      type='text'
                      name='tag_line'
                      placeholder='Enter Business Tagline'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.tag_line}
                    />
                    {formik.touched.tagline && formik.errors.tag_line ? (
                      <div
                        className='invalid-feedback'
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.tag_line}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='row my-2'>
                  <div className='col-md-12 my-2'>
                    <label className='input_label'>Business Description*</label>
                    <textarea
                      placeholder='Enter Business Description'
                      name='description'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <div
                        className='invalid-feedback'
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.description}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='row my-2'>
                  <div className='col-md-6'>
                    <label className='input_label'>
                      Business Contact Number*
                    </label>
                    <PhoneInput
                      defaultCountry='us'
                      value={phone}
                      onChange={(phone, { country, inputValue }) => {
                        handlePhone(phone, country, inputValue);
                      }}
                      name='phone'
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <div
                        className='invalid-feedback'
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.phone}
                      </div>
                    ) : null}
                  </div>
                  <div className='col-md-6'>
                    <label className='input_label'>Business Category*</label>
                    <select
                      className='form-select'
                      name='busi_cat_id'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.busi_cat_id}
                    >
                      <option>Select Business Category</option>
                      {categories &&
                        categories?.map((cat) => (
                          <option val={cat?.id}>{cat.category_name}</option>
                        ))}
                    </select>
                    {formik.touched.busi_cat_id && formik.errors.busi_cat_id ? (
                      <div
                        className='invalid-feedback'
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.busi_cat_id}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='row my-2'>
                  <div className='col-md-6'>
                    <label className='input_label'>Business Type*</label>
                    <select
                      className='form-select'
                      name='busi_type_id'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.busi_type_id}
                    >
                      <option>Select Business Type</option>
                      {businessTypes &&
                        businessTypes?.map((cat) => (
                          <option val={cat?.id}>{cat.business_type}</option>
                        ))}
                    </select>
                    {formik.touched.busi_type_id &&
                      formik.errors.busi_type_id ? (
                      <div
                        className='invalid-feedback'
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.busi_type_id}
                      </div>
                    ) : null}
                  </div>
                  <div className='col-md-6'>
                    <label className='input_label'>Business Email</label>
                    <input
                      type='email'
                      name='email'
                      placeholder='Enter Business Email'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div
                        className='invalid-feedback'
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col-md-6'>
                    <label className='input_label'>Business Website</label>
                    <input
                      type='text'
                      name='website'
                      placeholder='Enter Business Website'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.website}
                    />
                    {formik.touched.website && formik.errors.website ? (
                      <div
                        className='invalid-feedback'
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.website}
                      </div>
                    ) : null}
                  </div>
                  <div className='col-md-6'>
                    <label className='input_label'>Additional Website</label>
                    <input
                      type='text'
                      name='additionalWebsite'
                      placeholder='Enter Additional Website'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.additionalWebsite}
                    />
                    {formik.touched.tagline &&
                      formik.errors.additionalWebsite ? (
                      <div
                        className='invalid-feedback'
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.additionalWebsite}
                      </div>
                    ) : null}
                  </div>
                </div>
                <hr className='text-white my-5' />
                <div className='row mb-2'>
                  <div className='col-md-6'>
                    <label className='input_label'>
                      Business Address Line 1*
                    </label>
                    <input
                      type='text'
                      name='address1'
                      placeholder='Enter Business Address'
                      ref={ref}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address1}
                    />
                    {formik.touched.address1 && formik.errors.address1 ? (
                      <div
                        className='invalid-feedback'
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.address1}
                      </div>
                    ) : null}
                  </div>
                  <div className='col-md-6'>
                    <label className='input_label'>
                      Business Address Line 2
                    </label>
                    <input
                      type='text'
                      name='address2'
                      placeholder='Enter Business Address'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address2}
                    />
                    {formik.touched.address2 && formik.errors.address2 ? (
                      <div
                        className='invalid-feedback'
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.address2}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='row my-2'>
                  <div className='col-md-6'>
                    <label className='input_label'>Country</label>
                    <input
                      type='text'
                      name='country'
                      placeholder='Country'
                      disabled
                      value={formik.values.country}
                    />
                    {formik.touched.country && formik.errors.country ? (
                      <div
                        className='invalid-feedback'
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.country}
                      </div>
                    ) : null}
                  </div>

                  <div className='col-md-6'>
                    <label className='input_label'>State</label>
                    {/* <select
                    className='form-select'
                    name='state'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.state}
                  >
                    <option disabled defaultValue>
                      Select State
                    </option>
                  </select> */}
                    <input
                      type='text'
                      name='state'
                      placeholder='State'
                      disabled
                      value={formik.values.state}
                    />
                    {formik.touched.state && formik.errors.state ? (
                      <div
                        className='invalid-feedback'
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.state}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='row my-2'>
                  <div className='col-md-6'>
                    <label className='input_label'>City</label>
                    <input
                      type='text'
                      name='city'
                      placeholder='City'
                      disabled
                      value={formik.values.city}
                    />
                    {formik.touched.city && formik.errors.city ? (
                      <div
                        className='invalid-feedback'
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.city}
                      </div>
                    ) : null}
                  </div>
                  <div className='col-md-6'>
                    <label className='input_label'>Zip Code*</label>
                    <input
                      type='text'
                      name='zipcode'
                      placeholder='Zip Code'
                      disabled
                      value={formik.values.zipcode}
                    />
                    {formik.touched.zipcode && formik.errors.zipcode ? (
                      <div
                        className='invalid-feedback'
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.zipcode}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='row my-2'>
                  <div className='col-md-12 my-2'>
                    <label className='input_label'>Tags</label>
                    {/* <textarea
                      placeholder='Enter Your Business Tags'
                      name='tag'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.tag}
                    /> */}
                  </div>
                  <div className='tags-input'>
                    <ul>
                      {tags.map((tag, index) => (
                        <li key={index}>
                          <span>{tag}</span>
                          <i
                            className='material-icons'
                            onClick={() => removeTags(index)}
                          >
                            X
                          </i>
                        </li>
                      ))}
                    </ul>
                    <textarea
                      type='text'
                      placeholder='Press enter to add tags'
                      onKeyUp={(event) => addTags(event)}
                    />
                    {formik.touched.tags && formik.errors.tag ? (
                      <div
                        className='invalid-feedback'
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.tag}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='row my-5'>
                  <div className='col-12 hide_business_details d-flex px-0'>
                    <input
                      type='checkbox'
                      id='is_address_hide'
                      name='is_address_hide'
                      value='is_address_hide'
                      onClick={() => setAddressHide(!isAddressHide)}
                    />
                    <label for='hideBusinessDetails'>
                      {" "}
                      Hide Business Details
                    </label>
                    <br />
                  </div>
                </div>
                <button
                  type='submit'
                  className='submit_button btn btn-success w-100'
                >
                  {" "}
                  Next
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdBusinessDetails;
