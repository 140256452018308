import React from "react";
import Modal from "../../../../Component/modal/Modal";

const OfferPopup = ({ isOpenOfferModal, setIsOpenOfferModal }) => {
  return (
    <Modal
      isOpen={isOpenOfferModal.open}
      onClose={() => setIsOpenOfferModal({ data: {}, open: false })}
    >
      <div className="sale-popContent-container">
        <div className="modal-event-box-img">
          <img
            src={
              isOpenOfferModal.data?.offerImage &&
              isOpenOfferModal.data?.offerImage.length > 0
                ? isOpenOfferModal.data?.offerImage[0].image
                : "https://bgz-business.s3.us-east-2.amazonaws.com/FreeBusinessImage.jpg"
            }
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
              borderRadius: "15px",
            }}
          />
        </div>
        <h5 className="saleTitle">{isOpenOfferModal.data?.title}</h5>
        <p style={{ color: "#3CA059", fontSize: "18px", fontWeight: "400" }}>
          {isOpenOfferModal.data?.tag_line}
        </p>
        <span
          style={{
            color: "#FCFCFC",
            fontSize: "16px",
            fontWeight: "600",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            paddingBottom: "12px",
          }}
        >
          <img src="/images/calender.svg" width={16} />
          <span>{isOpenOfferModal.data?.start_date} </span> -{" "}
          <span>{isOpenOfferModal.data?.end_date} </span>
        </span>
        <h6
          style={{
            color: "#FCFCFC",
            fontSize: "16px",
            fontWeight: "600",
            paddingTop: "12px",
          }}
        >
          Offers
        </h6>
        <ol
          style={{
            listStyle: "outside",
            paddingLeft: "20px",
            paddingBottom: "12px",
          }}
        >
          <li
            style={{
              color: "#9f9f9f",
              fontWeight: "400",
              fontSize: "14px",
            }}
          >
            Get 20% off on every purchase.
          </li>
        </ol>
        <h3 style={{ fontSize: "18px", fontWeight: "600", color: "#fff" }}>
          How to use it
        </h3>
        {isOpenOfferModal.data?.description?.map((des, index) => {
          return (
            <ol
              key={index}
              style={{ listStyle: "outside", paddingBottom: "12px" }}
            >
              <li
                style={{ fontSize: "14px", fontWeight: "600", color: "#fff" }}
              >
                Step {index + 1} -{" "}
                <span style={{ fontWeight: "400", color: "#9f9f9f" }}>
                  {des.value}
                </span>
              </li>
            </ol>
          );
        })}

        <h6 style={{ color: "#FFFFFF", fontSize: "18px", fontWeight: "600" }}>
          Terms & Conditions{" "}
        </h6>
        {isOpenOfferModal.data?.term_conditions?.map((terms, id) => {
          return (
            <ol key={id} style={{ listStyle: "outside", paddingLeft: "20px" }}>
              <li
                style={{
                  color: "#9f9f9f",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {terms.value}
              </li>
            </ol>
          );
        })}

        {/* <button className="business-telBtn" style={{ marginTop: "50px" }}>
          Apply To My Offer
        </button> */}
      </div>
    </Modal>
  );
};

export default OfferPopup;
