import { GET_PROFILE_SUCCESS, GET_PROFILE_ERROR } from "../actionTypes";
import { getProfileApi } from "../../../API/ApiService";
import { loadingAction } from "../loadingAction";

export const getProfile = () => {
  return dispatch => {
    dispatch(loadingAction(true));
    return getProfileApi()
      .then(response => {
        if (response && response.status === 200) {
          dispatch(loadingAction(false));           
          return dispatch({ type: GET_PROFILE_SUCCESS, result: response });
        }
      })
      .catch(({ ...error }) => {
        dispatch(loadingAction(false));
        return dispatch({ type: GET_PROFILE_ERROR, result: error.response });
      });
  };
};