import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import debounce from "lodash/debounce";

import InfinitScroll from "react-infinite-scroll-component";
import GridCardWrapper from "../../Component/GridCardWrapper";

import {
  NEW_INTEREST,
} from "../../Utils/constants";
import EmptyMessage from "../../Component/EmptyMessage";
import { BUSINESS_DETAILS } from "../../Routes/RoutesConstant";
import "./MyInterest.css";
import { skeletonAction } from "../../Redux/Action/loadingAction";
import { getInterest } from "../../API/ApiService";
import CardInterest2 from "../../Component/CardInterest2";
import { getItemLocalStorage } from "../../Utils/localStroge";

const MyInterest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [interestList, setInterestList] = useState([]);

  // const businessData = useSelector(state => state.businessData);
  // const loadingRequest = useSelector(state => state.loadingReducer);
  const businessPage = (id, b_name) => {
    navigate(
      `/businessDetails/${id}?name=${b_name?.trim()?.replaceAll(" ", "_")}`,
      { state: { id } }
    );
    // navigate(`/businessDetails/${id}`);
  };
  const skeletonLoad = useSelector(
    (state) => state?.loadingReducers?.skeletonLoader
  );
  const {
    latLng: { lat, lng },
  } = useSelector((state) => state.locationReducer);
  const PageSize = 20;

  const { stateList, selectedState } = useSelector(
    (state) => state.locationReducer
  );
  const currentState = stateList.find((s) => s.state_code === selectedState);

  let [responseData, setResponseData] = useState([]);
  let [isNext, isNextFunc] = useState(false);
  let [pageCount, setCount] = useState(1);
  let [user, setUser] = useState(JSON.parse(getItemLocalStorage('user')))

  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchData();
  }, [pageCount]);

  const fetchData = async (page = pageCount) => {
    dispatch(skeletonAction(true, NEW_INTEREST));
    let response = await getInterest({
      type: "Custom",
      user_uuid: user.user_uuid,
      page: pageCount,
      limit: PageSize,
    })
      .then((res) => {
        // console.log(res, "res");
        setInterestList(res?.data?.result?.interestList);
        dispatch(skeletonAction(false, NEW_INTEREST));
      })
      .catch(() => {
        dispatch(skeletonAction(false, NEW_INTEREST));
      });
    const totalPageCount = Math.ceil(
      response?.payload?.TotalRecords / PageSize
    );
    isNextFunc(pageCount !== totalPageCount);
  };
  const fetchMoreData = () => {
    dispatch(skeletonAction(false, NEW_INTEREST));
    setCount(pageCount + 1);
  };

  const debouncVersion = useCallback(debounce(fetchData), [
    lat,
    lng,
    currentState,
  ]);
  return (
    <>
      <div>
        <section className='interestDiv'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <h2>
                  My <span style={{ color: "#3CA059" }}>Interest</span>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section className='nib-boxes'>
          <div className='container'>
            <div className='row'>
              {!skeletonLoad[NEW_INTEREST] && interestList?.length === 0 ? (
                <EmptyMessage message={`No Interests Found ${search}`} />
              ) : (
                <>
                  <InfinitScroll
                    dataLength={interestList?.length}
                    next={fetchMoreData}
                    //   hasMore={isNext}
                    style={{
                      overflow: "auto",
                      // height: "90vh"
                    }}
                  >
                    <GridCardWrapper
                      blockWidth={310}
                      blockHeight={270}
                      isLoading={skeletonLoad[NEW_INTEREST]}
                      data={interestList}
                    >
                      {(props) => (
                        <CardInterest2
                          {...props}
                          width={300}
                          marginBottom={0}
                          height={310}
                          handleViewDetail={businessPage}
                          showReview
                          showAddress
                          showPhone
                          borderRadius='20px'
                        />
                      )}
                    </GridCardWrapper>
                  </InfinitScroll>
                  {isNext && (
                    <div className='col-md-12 my-3 text-center'>
                      <button className='view_more_btn' onClick={fetchMoreData}>
                        View More
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className='for-border' />
        </section>
      </div>
    </>
  );
};

export default MyInterest;
