import React from "react";
import Linkify from "react-linkify";

const Announcements = ({ announcements }) => {
  function urlify(message) {
    if (!message) return;
    const newText = message
      .split("\n")
      .map((str, idx) => <h4 key={idx}>{str}</h4>);
    return newText;
  }
  return announcements?.length > 0 ? (
    announcements?.map((item, index) => {
      const { id, description, image } = item;
      return (
        <div className="announcements-card-wrap" key={id}>
          <img
            src={
              image ||
              "https://bgz-business.s3.us-east-2.amazonaws.com/FreeBusinessImage.jpg"
            }
            style={{
              objectFit: "cover",
              width: "100%",
              height: "178px",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          />
          <div
            className="announcements-card-description"
            style={{ borderRadius: !image && "12px" }}
          >
            <Linkify>{urlify(description)}</Linkify>

            {/* <div className="event-card-details-address">
                 <img src="/images/tim-date.svg" width={16} />
                 <span style={{ padding: "0px 8px " }}>{event_time}</span>
                 <span>{event_date}</span>
               </div> */}
          </div>
        </div>
        // <div className="col-md-6 text-center" key={id}>
        //   <div className="event-box" style={{ height: "100%" }}>
        //     <div className="event-box-img" style={{ height: "80%" }}>
        //       <img
        //         src={
        //           image ||
        //           "https://bgz-business.s3.us-east-2.amazonaws.com/FreeBusinessImage.jpg"
        //         }
        //         className="img-fluid"
        //         style={{
        //           // objectFit: "cover",
        //           height: "300px",
        //           width: "auto",
        //         }}
        //         alt=""
        //       />
        //     </div>
        //     <Linkify>{urlify(description)}</Linkify>
        //   </div>
        // </div>
      );
    })
  ) : (
    <div className="businessDetails-Noreview">
      <img src="/images/speaker-icon.svg" />
      <div className="businessDetails-Noreview-content">
        No announcements found. <br />
        stay tuned!
      </div>
    </div>
  );
};

export default Announcements;
