import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getBusinessDetail,
  getBusinessEvent,
} from "../../../Redux/Action/BusinessAction/businesAction";
import { Link, useNavigate } from "react-router-dom";
import { isArray, isEmpty } from "lodash";
import { formatPhone, setItemLocalStorage } from "../../../Utils/localStroge";
import { getAnnouncements, getOffer, getSales } from "../../../API/ApiService";

import Reviews from "./reviews/Reviews";
import Events from "./events/Events";
import EventsPopup from "./events/EventsPopup";
import Announcements from "./announcements/Announcements";
import Sales from "./sales/Sales";
import SalesPopup from "./sales/SalesPopup";
import Offers from "./offers/Offers";
import OfferPopup from "./offers/OfferPopup";
import LoginPopup from "./login-popup/LoginPopup";
import Slider from "./slider/Slider";
import BusinessOpenHours from "./business-open-hours/BusinessOpenHours";
const BusinessDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isbusiness, setBusiness] = useState({});
  const [event, setEvent] = useState({});
  const [announcements, setAnnouncements] = useState([]);
  const [sales, setSales] = useState([]);
  const [offers, setOffers] = useState([]);
  const [isBusinessHours, setBusinessHours] = useState({});
  const [visible, setVisible] = useState(4);
  const [isOpenSaleModal, setIsOpenSaleModal] = useState(false);
  const [selectedSale, setSelectedSale] = useState(null);
  const [isOpenOfferModal, setIsOpenOfferModal] = useState({
    data: {},
    open: false,
  });
  const [isOpenEventModal, setIsOpenEventModal] = useState({
    data: {},
    open: false,
  });
  const [isLoginModal, setIsLoginModal] = useState(false);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 4);
  };
  const { loginDataSuccess } = useSelector((state) => state.LoginData);
  const isLoggedIn = loginDataSuccess?.access_token;

  useEffect(async () => {
    let body;
    if (id) {
      body = { business_id: id };
    } else if (id) {
      body = { business_id: id };
    }
    const response = await dispatch(getBusinessDetail(body));

    if (response?.type === "GET_BUSINESS_DETAILS_SUCCESS") {
      setBusiness(response?.payload);
      setBusinessHours(response?.payload?.businessHours[0]);
    }

    const res = await dispatch(getBusinessEvent(id));

    if (res?.type === "GET_BUSINESS_EVENT_SUCCESS") {
      setEvent(res.payload);
    }

    getAnnouncements(id).then((res) => {
      // console.log(res);
      if (res.status === 200) {
        setAnnouncements(res.data?.announcements || []);
      }
    });
    getSales(id).then((res) => {
      // console.log(res);
      if (res.status === 200) {
        setSales(res.data?.sales || []);
      }
    });
    getOffer(id).then((res) => {
      if (res.status === 200) {
        setOffers(res?.data?.offers);
      }
    });
  }, [id]);

  const handleSelectEvent = (event) => {
    setIsOpenEventModal({ data: event, open: true });
  };

  const handleClick = (sale) => {
    if (isLoggedIn) {
      setSelectedSale(sale);
      setIsOpenSaleModal(true);
    } else {
      setIsLoginModal(true);
    }
  };

  const handleClose = () => {
    setIsOpenSaleModal(false);
  };

  const handleOffer = (offer) => {
    if (isLoggedIn) {
      setIsOpenOfferModal({ data: offer, open: true });
    } else {
      setIsLoginModal(true);
    }
  };
  return (
    <>
      <LoginPopup
        isLoginModal={isLoginModal}
        setIsLoginModal={setIsLoginModal}
      />
      <SalesPopup
        handleClose={handleClose}
        isOpenSaleModal={isOpenSaleModal}
        selectedSale={selectedSale}
      />
      <EventsPopup
        isOpenEventModal={isOpenEventModal}
        setIsOpenEventModal={setIsOpenEventModal}
      />
      <OfferPopup
        isOpenOfferModal={isOpenOfferModal}
        setIsOpenOfferModal={setIsOpenOfferModal}
      />
      <section className="vd-content pb-5">
        <div className="container">
          <div className="row businessDetails-alignItem">
            <div className="businessDetails-backBtn">
              <img src="/images/bi_arrow-right.svg" />
              <div onClick={() => navigate(-1)}>Back</div>
            </div>
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="col-md-7">
                <div className="vd-content-text">
                  <div className="businessDetails-heading-section">
                    <h3 className="businessDetails-title">
                      {isbusiness?.business_name}
                    </h3>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          color: "#fcfcfc",
                          fontSize: "14px",
                          fontWeight: 300,
                          textAlign: "initial",
                          marginBottom: "8px",
                        }}
                      >
                        {isbusiness?.tag_line}
                      </div>
                      {isbusiness?.reviewAverage ? (
                        <div className="businessDetails-reviewStar">
                          {isbusiness?.reviewAverage != undefined &&
                            Array.from(
                              Array(parseInt(isbusiness?.reviewAverage)),
                              (e, i) => {
                                return (
                                  <li key={i} className="reviewStar">
                                    <i
                                      className="fa-solid fa-star"
                                      style={{ fontSize: "15px" }}
                                    />
                                  </li>
                                );
                              }
                            )}
                          {Array.from(
                            {
                              length:
                                5 - parseInt(isbusiness?.reviewAverage || 0),
                            },
                            (e, j) => {
                              return (
                                <li key={j + 5} style={{ listStyle: "none" }}>
                                  <i
                                    style={{
                                      marginRight: "4px",
                                      fontSize: "15px",
                                      listStyle: "none",
                                    }}
                                    className="fa-regular fa-star"
                                  />
                                </li>
                              );
                            }
                          )}
                          {isbusiness?.reviewCount && (
                            <span
                              style={{
                                color: "#FFC320",
                                fontWeight: "400",
                                fontSize: "15px",
                              }}
                            >
                              ({isbusiness?.reviewCount} Reviews)
                            </span>
                          )}
                        </div>
                      ) : null}
                    </div>
                    <div style={{ display: "inline-block" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "16px",
                        }}
                      >
                        {/* <div className="businessDetails-isFavourite">
                          <img src="/images/bxs_share.svg" width={24} />
                        </div> */}
                        <div>
                          {isbusiness.isFavourite ? (
                            <div className="businessDetails-isFavourite">
                              <i
                                className="fas fa-heart"
                                style={{
                                  color: "#3CA059",
                                  fontSize: "24px",
                                }}
                              />
                            </div>
                          ) : (
                            <div className="businessDetails-isFavourite">
                              <i
                                className="far fa-heart"
                                style={{ color: "#3CA059", fontSize: "24px" }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Slider isbusiness={isbusiness} />
                  <p className="businessDetails-description">
                    {isbusiness?.description}
                  </p>
                  <div className="grab-container">
                    {/* <div className="grab-boxes">
                    <div className="row">
                      <div className="col-md-6 grab-box1">
                        <div className="grab-box-img">
                          <img
                            src="images/offercard34.png"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 grab-box1">
                        <div className="grab-box-img">
                          <img
                            src="images/offercard34.png"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="vd-content-details">
                  <div className="contact-info" style={{ padding: "12px" }}>
                    <h4 style={{ borderBottom: "1px solid #282828" }}>
                      Contact Information
                    </h4>
                    <ul className="address-info">
                      {isbusiness?.phone && (
                        <li>
                          <a
                            href={`tel:${isbusiness?.country_code}${isbusiness?.phone}`}
                          >
                            <img src="../images/call-calling.svg" />{" "}
                            {formatPhone(
                              `${isbusiness?.country_code}${isbusiness?.phone}`
                            )}
                          </a>
                        </li>
                      )}
                      {isbusiness?.users?.email_id && (
                        <div style={{ marginBottom: "12px" }}>
                          <i
                            className="fa-solid fa-envelope"
                            style={{
                              fontSize: "16px",
                              color: "#2d8646",
                            }}
                          />
                          <a
                            href={`mailto:${isbusiness?.users?.email_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: "none",
                              color: "#fff",
                              fontSize: "15px",
                              fontWeight: "200",
                              marginLeft: "14px",
                            }}
                          >
                            {isbusiness?.users?.email_id}
                          </a>
                        </div>
                      )}
                      {isbusiness?.website && (
                        <li>
                          <a
                            href={
                              isbusiness?.website?.includes("http")
                                ? isbusiness?.website
                                : `https://${isbusiness?.website}`
                            }
                            target="_blank"
                          >
                            <img src="../images/global.svg" />
                            {isbusiness?.website}
                          </a>
                        </li>
                      )}
                      {isbusiness?.is_address_hide === 1 && (
                        <>
                          <li className="add-green">
                            <a
                              href={`https://www.google.com/maps/search/?api=1&query=${isbusiness?.lat},${isbusiness?.lng}`}
                              target="_blank"
                            >
                              <img src="../images/map.svg" />{" "}
                              {isbusiness?.address}
                            </a>
                          </li>
                        </>
                      )}
                      {isbusiness?.business_email && (
                        <li>
                          <a style={{ color: "#fff" }}>
                            <img src="../images/sms.svg" />
                            {isbusiness?.business_email}
                          </a>
                        </li>
                      )}
                      {isbusiness?.alternative_website && (
                        <li>
                          <a style={{ color: "#fff" }}>
                            <img src="../images/global.svg" />
                            {isbusiness?.alternative_website}
                          </a>
                        </li>
                      )}
                    </ul>
                    <ul className="vd-socials">
                      {isbusiness?.facebook && (
                        <li>
                          <a href={`${isbusiness?.facebook}`} target="_blank">
                            <img src="../images/fb2.svg" />
                          </a>
                        </li>
                      )}
                      {isbusiness?.twitter && (
                        <li>
                          <a href={`${isbusiness?.twitter}`} target="_blank">
                            <img src="../images/twitter2.svg" />
                          </a>
                        </li>
                      )}
                      {isbusiness?.instagram && (
                        <li>
                          <a href={`${isbusiness?.instagram}`} target="_blank">
                            <img src="../images/instagram12.svg" />
                          </a>
                        </li>
                      )}

                      {isbusiness?.youtube && (
                        <li>
                          <a
                            href={`${isbusiness?.youtube}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src="../images/youtube_logo.png"
                              style={{
                                background: "#49ab59",
                                borderRadius: "50%",
                              }}
                            />
                          </a>
                        </li>
                      )}
                      {isbusiness?.linkedIn && (
                        <li>
                          <a href={`${isbusiness?.linkedIn}`} target="_blank">
                            <img
                              src="../images/linkedin2.svg"
                              style={{ width: "30px" }}
                            />
                          </a>
                        </li>
                      )}
                      {isbusiness?.ticktock && (
                        <li>
                          <a href={`${isbusiness?.ticktock}`} target="_blank">
                            <img src="../images/ticktock.svg" />
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      gap: "12px",
                    }}
                  >
                    <button
                      className="business-messageBtn"
                      style={{ position: "relative" }}
                      onClick={() => {
                        if (!isLoggedIn) {
                          setIsLoginModal(true);
                        }
                      }}
                    >
                      <i
                        className="fas fa-lock"
                        style={{
                          color: "#349b52",
                          fontSize: "15px",
                          position: "absolute",
                          right: 5,
                          top: 4,
                        }}
                      />
                      <img src="/images/msg.svg" width={20} />
                      Message
                    </button>
                    <button
                      className="business-telBtn"
                      onClick={() => {
                        window.open(
                          `tel:${isbusiness.country_code}${isbusiness.phone}`,
                          "_self"
                        );
                      }}
                    >
                      <img src="/images/phone-icon.svg" width={20} />
                      Call Now
                    </button>
                  </div>
                </div>
                {isBusinessHours?.day_hours && (
                  <div className="barber-accordion" style={{ width: "100%" }}>
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOpenHours"
                            aria-expanded="true"
                            aria-controls="collapseOpenHours"
                            style={{ fontSize: "18px", fontWeight: "600" }}
                          >
                            Opening Hours
                          </button>
                        </h2>

                        <div
                          id="collapseOpenHours"
                          className="accordion-collapse collapse "
                          aria-labelledby="headingOpenHours"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            {!isEmpty(isBusinessHours) &&
                              isArray(
                                JSON.parse(isBusinessHours?.day_hours)
                              ) && (
                                <div className="accordion-content-wrap">
                                  <BusinessOpenHours
                                    isBusinessHours={isBusinessHours}
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="barber-accordion">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Reviews
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div
                        className={
                          isbusiness?.userReview?.length > 0 &&
                          "businessDetails-review-section-wrap"
                        }
                      >
                        <Reviews isbusiness={isbusiness} visible={visible} />
                      </div>
                      {isbusiness?.userReview?.length >= visible && (
                        <button onClick={showMoreItems}>
                          <img src="../images/angle-down.png" /> See more
                          reviews
                        </button>
                      )}
                      {isLoggedIn && (
                        <div className="businessDetails-textarea-review-post-wrap">
                          <textarea
                            className="businessDetails-textarea-review-post"
                            placeholder="Add your review here..."
                          />
                          <div className="businessDetails-review-postBtn">
                            Post
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Events
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div
                        className={
                          !isEmpty(event.events)
                            ? "businessDetails-sales-card"
                            : ""
                        }
                      >
                        <Events
                          event={event}
                          handleSelectEvent={handleSelectEvent}
                          setIsLoginModal={setIsLoginModal}
                          isLoggedIn={isLoggedIn}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Announcements
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div
                        className={
                          announcements?.length > 0
                            ? "businessDetails-sales-card"
                            : ""
                        }
                      >
                        <Announcements announcements={announcements} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Sales
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div
                        className={
                          sales?.length > 0 ? "businessDetails-sales-card" : ""
                        }
                      >
                        <Sales sales={sales} handleClick={handleClick} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      Offers
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div
                        className={
                          offers?.length > 0 ? "businessDetails-sales-card" : ""
                        }
                      >
                        <Offers offers={offers} handleOffer={handleOffer} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BusinessDetails;
