import React from "react";
import { number, string } from "yup";
import { formatPhone } from "../Utils/localStroge";

const DailyDealsAds = ({
  width,
  height,
  marginLeft = 32,
  marginBottom = 20,
  data,
  handleViewDetail,
  showReview = false,
  showAddress = false,
  gridView = false,
  showPhone = false,
  borderRadius = 23.96,
  padding = 0,
}) => {
  const {
    address1,
    city,
    state,
    country,
    zipcode,
    is_address_hide,
    phone,
    country_code,
    lat,
    lng,
    busi_type_id,
  } = data;
  var text = address1;
  var count = 8;
  //   var result = text.slice(0, count) + (text.length > count ? "..." : "");
  const starCount = [1, 2, 3, 4, 5];
  return (
    <div
      className="nib-box ads-box "
      style={{
        width,
        height,
        marginLeft,
        marginBottom,
        borderRadius,
        padding,
      }}
    >
      <div style={{ position: "relative", width: `${width}` }}>
        <img
          src={
            data?.image
            // ||
            // "https://bgz-business.s3.us-east-2.amazonaws.com/FreeBusinessImage.jpg"
          }
          style={{
            width: 1280,
            objectFit: "fill",
            height: 350,
            padding: 13,
            borderRadius: 30,
            marginLeft: 7,
          }}
          alt=""
        />
      </div>
    </div>
  );
};

export default DailyDealsAds;
