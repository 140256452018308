import React from "react";
import { formatPhone } from "../Utils/localStroge";

const DailyDealsCard = ({
  width = 8000,
  height = 450,
  marginLeft = 0,
  marginBottom = 20,
  data,
  handleViewDetail,
  showReview = false,
  showAddress = false,
  gridView = false,
  showPhone = false,
}) => {
  const {
    address1,
    city,
    state,
    country,
    zipcode,
    is_address_hide,
    phone,
    country_code,
    lat,
    lng,
    busi_type_id,
  } = data;

  const result = address1?.slice(0, 8) + (address1?.length > 8 ? "..." : "");

  return (
    <div
      className="nib-box"
      style={{
        width: "630px",
        height,
        marginLeft,
        marginBottom,
        display: "flex",
        flexDirection: "column",

        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      <div style={{ height: height - 60, position: "relative" }}>
        <img
          src={
            data?.businessImage?.[0]?.image 
            ||
            "https://bgz-business.s3.us-east-2.amazonaws.com/FreeBusinessImage.jpg"
          }
          style={{
            width: "100%",
            objectFit: "cover",
            height: "155px",
          }}
          alt=""
        />
        {busi_type_id === 6 && (
          <label
            style={{
              position: "absolute",
              transform: "translate(-50%, -50%)",
              top: "44.3%",
              left: "50%",
              backgroundColor: "#00000094",
              color: "white",
              width: "100%",
              textAlign: "center",
              fontSize: "1.1rem",
            }}
          >
            Gig worker
          </label>
        )}
        {data?.userDetail?.subscription_id === 1 && (
          <div
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              width: "0",
              height: "0",
              borderTop: "70px solid #000000ab",
              borderLeft: "70px solid transparent",
            }}
          >
            <img
              src="../images/greenlock.png"
              style={{ position: "absolute", top: "-65px", right: "10px" }}
              width={26}
              alt=""
            />
          </div>
        )}

        <h4 style={{ textAlign: "left" }}>{data?.business_name}</h4>
        {showReview && (
          <ul>
            {Array.from(
              { length: parseInt(data?.reviewAverage || 0) },
              (e, i) => (
                <li key={i}>
                  <i className={`fa-solid fa-star`} />
                </li>
              )
            )}
          </ul>
        )}
        {showAddress && is_address_hide === 1 && (
          <div className="d-flex align-items-baseline">
            <i
              className="fa-solid fa-location-dot"
              style={{ color: "#349c52", marginRight: "5px", fontSize: "15px" }}
            />
            <p
              style={{
                color: "#ffffffc9",
                fontSize: "14px",
                fontWeight: 500,
                textAlign: "initial",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "95%",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
                  "_"
                );
              }}
            >
              {result}, {city}, {state}, {country}, {zipcode}
            </p>
          </div>
        )}

        {showPhone && phone && (
          <div className="d-flex align-items-baseline">
            <a
              href={`tel:${country_code}${phone}`}
              style={{ textDecoration: "none", color: "#dadada" }}
            >
              <img
                src="../images/call-calling.svg"
                width="18px"
                height="18px"
                alt=""
              />{" "}
              {formatPhone(`${country_code}${phone}`)}
            </a>
          </div>
        )}
      </div>
      {data?.userDetail?.subscription_id === 1 ? (
        <button
          className="view-detail-btn"
          onClick={() => {
            window.open(`tel:${country_code}${phone}`, "_self");
          }}
        >
          Call Now
        </button>
      ) : (
        <button
          className="view-detail-btn"
          onClick={() => {
            handleViewDetail && handleViewDetail(data?.id, data.business_name);
          }}
        >
          View Details
        </button>
      )}
    </div>
  );
};

export default DailyDealsCard;
